import { gql } from '@apollo/client';

export const DRAFT_SUBSCRIPTION = gql`
  subscription draftPick($draftId: ID!) {
    draftPick(draftId: $draftId) {
      draftId
      pick {
        player {
          playerId
          fullName
        }
        franchise {
          franchiseName
        }
      }
    }
  }
`;

// TODO: test for efficiency vs dedicated drafted players query
export const GET_DRAFT = gql`
  query getDraftById($draftId: ID!) {
    getDraftById(draftId: $draftId) {
      draftId
      leagueId
      status
      schedule {
        start
        readyUsers
      }
      contest {
        contestId
        name
        rules {
          ...on NflRule{
            league{
              type
              style
            }
          }
          sport
          scoring {
            rule
            points
          }
          roster {
            size
            starters
            positions {
              positionId
              position
              starters
              flex
            }
          }
        }
        entry {
          fee
          count
          max
        }
        isFeatured
        prize {
          pool
          first
          second
          third
          weekly
        }
        week {
          start
          end
        }
        users
      }
      pick {
        round
        number
        duration
        auto
        overall
        expiration
        franchise {
          user {
            userId
            profile {
              displayName
              firstName
              lastName
              avatar
            }
          }
        }
      }
      order {
        userId
        franchiseName
        user {
          profile {
            firstName
            lastName
            displayName
            avatar
          }
        }
      }
      summary {
        overall
        player {
          playerId
          fullName
          team
          fantasyPosition
          ... on NflPlayer {
            photoUrl
            byeWeek
          }
        }
        franchise {
          userId
        }
      }
      userSettings {
        autoDraftEnabled
      }
    }
  }
`;

export const UPDATE_DRAFT_USER_SETTINGS = gql`
  mutation updateDraftUserSettings($draftId: ID!, $settings: UpdateDraftUserSettingsInput) {
    userSettings: updateDraftUserSettings(draftId: $draftId, settings: $settings) {
      autoDraftEnabled
    }
  }
`;
