import { useLazyQuery, useQuery, useSubscription } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { GET_CONTESTS } from 'api/contest';
import logo from 'assets/logos/text_logo.png';
import Text from 'components/Common/Text';
import { NavbarAction, NavbarBottom, NavbarTop } from 'components/Navigation/index';
import { useAppContext } from 'context/AppProvider';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { useTheme, withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { AccountCircle, ArrowBack } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { GET_NOTIFICATIONS, NOTIFICATION_CREATED } from 'components/Common/NotificationPopper/queries';
import { useSnackbar } from 'components/Common/Snackbar/useSnackbar';
import AccountBalance from 'components/Navigation/NavMenu/components/AccountBalance';
import NotificationPopper from 'components/Navigation/NavMenu/components/NotificationPopper';
import TransactionHistoryDialog from 'components/Navigation/NavMenu/components/TransactionHistoryDialog';
import { Checkout_USD, Payout_USD } from 'components/Navigation/NavMenu/utils';
import cfg from 'root/config';
import { formatCurrency } from 'utils/strings';
import s from './NavMenu.module.scss';
import { GET_ACCOUNT_BALANCE, GET_PAYSAFE_TOKEN } from './queries';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    width: '250px',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

export const NavMenu = ({ children, showNavItems = true, customerProfile }) => {
  const [{ user, token }, dispatch] = useAppContext();
  const { openSnackbar, snackbarProps, open: snackbarOpen } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const [navDrawerOpen, setNavDrawerOpen] = useState(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [unreadCount, setUnreadCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [notificationsAnchor, setNotificationsAnchor] = useState(null);

  const [refreshContests] = useLazyQuery(GET_CONTESTS, { fetchPolicy: 'network-only' });

  const {
    data: accountBalanceData,
    refetch: refetchAccountBalance,
    loading: accountBalanceLoading,
  } = useQuery(GET_ACCOUNT_BALANCE, {
    fetchPolicy: 'network-only',
    skip: !user,
  });

  const [withdrawFunds] = useLazyQuery(GET_PAYSAFE_TOKEN, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onCompleted: (tokenData) => {
      const { singleUseToken } = tokenData;
      const setupConfig = Payout_USD({
        isDeposit: false,
        singleUseToken,
        user: { ...user, ...customerProfile },
        accountBalance: accountBalanceData?.account?.balance,
      });
  
      // Ensure to use the correct method for payouts
      window.paysafe.checkout.setup(cfg.paysafeKey, setupConfig, async function (instance, error, result) {
        if (error) {
          console.error('Payout Error:', error); // Logs the detailed error to help diagnose the issue
          instance.showFailureScreen('Payout failed. Please try again.');
          return;
        }
  
        // Check if the amount is below the minimum required for payout
        if (result.amount < 1000) {
          instance.showFailureScreen('Must withdraw at least $10.');
          return;
        }
  
        // Proceed with server request to handle the payout
        const response = await fetch(cfg.ftApiUri, {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
          redirect: 'follow',
          referrerPolicy: 'no-referrer',
          body: JSON.stringify({
            operationName: 'withdrawWithPaymentProvider',
            variables: {
              input: {
                amount: Math.round(result.amount || 1000) / 100,
                merchantRefNum: singleUseToken?.merchantRefNum,
                paymentHandleToken: result.paymentHandleToken,
              },
            },
            query:
              'mutation withdrawWithPaymentProvider($input: WithdrawalInput!) {\n  withdrawWithPaymentProvider(input: $input) {\n    balance\n  }\n}\n',
          }),
        });
  
        // Process the response from the server
        const data = await response.json();
        if (data.errors && data.errors.length > 0) {
          console.error(data.errors[0].extensions?.exception?.userMessage || data.errors[0].message);
          instance.showFailureScreen('Error during payout. Please close this window and try again.');
        } else {
          refetchAccountBalance();
          instance.showSuccessScreen(`New account balance: ${formatCurrency(data.data.withdrawWithPaymentProvider.balance)}`);
        }
      });
    },
  });  
  

  const [depositFunds] = useLazyQuery(GET_PAYSAFE_TOKEN, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onCompleted: (tokenData) => {
      const { singleUseToken } = tokenData;
      const setupConfig = Checkout_USD({
        isDeposit: true,
        singleUseToken,
        user: { ...user, ...customerProfile },
        accountBalance: accountBalanceData?.account?.balance,
      });

      window.paysafe.checkout.setup(cfg.paysafeKey, setupConfig, async function (instance, error, result) {
        if (error) {
          openSnackbar({ message: `${error.displayMessage} ${error.detailedMessage}`, variant: 'error' });
          instance?.showFailureScreen();
          return;
        }

        if (result.amount < 1000) {
          instance?.showFailureScreen('Must deposit at least $10.');
          return;
        }

        if (result.amount > 1000000) {
          instance?.showFailureScreen('Cannot deposit more than $10,000.');
          return;
        }

        const response = await fetch(cfg.ftApiUri, {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
          redirect: 'follow',
          referrerPolicy: 'no-referrer',
          body: JSON.stringify({
            operationName: 'depositWithPaymentProvider',
            variables: {
              input: {
                amount: Math.round(result.amount || 1000) / 100,
                saveHandle: result?.customerOperation === 'ADD',
                merchantRefNum: singleUseToken?.merchantRefNum,
                paymentHandleToken: result.paymentHandleToken,
              },
            },
            query:
              'mutation depositWithPaymentProvider($input: DepositInput!) {\n  depositWithPaymentProvider(input: $input) {\n    balance\n  }\n}\n',
          }),
        });

        const data = await response.json();

        if (data.errors && data.errors.length > 0) {
          console.log({ data });
          instance.showFailureScreen(
            `${data.errors[0].extensions?.exception?.userMessage || data.errors[0].message} Close this window and try again.`
          );
        } else {
          refetchAccountBalance();
          instance.showSuccessScreen(`New account balance: ${formatCurrency(data.data.depositWithPaymentProvider.balance)}`);
        }
      });
    },
  });

  const [transactionHistoryOpen, setTransactionHistoryOpen] = useState(false);

  const { data: notificationData, refetch: refetchNotifications } = useQuery(GET_NOTIFICATIONS, {
    fetchPolicy: 'network-only',
  });

  const { data: notificationCountData } = useSubscription(NOTIFICATION_CREATED);

  const handleOpenNotifications = (e) => setNotificationsAnchor(e.currentTarget);
  const handleCloseNotifications = () => setNotificationsAnchor(null);

  const handleUpdateNotificationsState = (notificationId) => {
    const notificationsCopy = notifications.slice();
    const updatedNotificationIdx = notificationsCopy.findIndex((not) => not.notificationId === notificationId);
    notificationsCopy[updatedNotificationIdx] = { ...notificationsCopy[updatedNotificationIdx], isRead: true };
    setNotifications(notificationsCopy);

    setUnreadCount((prev) => {
      return prev > 0 ? prev - 1 : 0;
    });
  };

  useEffect(() => {
    const allNotifications = notificationData?.getNotifications || [];
    if (notificationCountData) {
      setUnreadCount(notificationCountData?.notificationCreated?.unreadCount || 0);
      refetchNotifications();
    } else {
      const unreadNotificationsCount = allNotifications.filter((notification) => !notification.isRead).length;
      setUnreadCount(unreadNotificationsCount);
    }

    setNotifications(allNotifications);
  }, [notificationData, notificationCountData]);

  const menuLinks = [
    {
      title: 'Deposit',
      to: '',
      onClick: () => depositFunds(),
    },
    {
      title: 'Withdrawal',
      to: '',
      onClick: () =>
        accountBalanceData?.account?.balance > 0
          ? withdrawFunds()
          : openSnackbar({ message: "You don't have any funds available for withdrawal. Your account balance is $0.00.", variant: 'error' }),
    },
    {
      title: 'Completed Leagues',
      to: '/leagues?completed=true',
      onClick: null,
    },
    {
      title: 'Contact & Support',
      to: '',
      onClick: null,
    },
    {
      title: 'How to Play',
      to: '',
      onClick: null,
    },
    {
      title: 'Settings',
      to: '',
      onClick: null,
    },
    {
      title: 'Transaction History',
      to: '',
      onClick: () => setTransactionHistoryOpen(true),
    },
    {
      title: 'Try for Free',
      to: '',
      onClick: null,
    },
    {
      title: 'FAQ',
      to: '',
      onClick: null,
    },
    {
      title: 'Send Feedback',
      to: '',
      onClick: null,
    },
    {
      title: 'Write a Review',
      to: '',
      onClick: null,
    },
    ...(cfg.env !== 'production'
      ? [
          {
            title: 'Add draft',
            to: '',
            onClick: () => window.open(`${cfg.ftMockApiUri}/mock/generate`),
          },
          {
            title: 'Reset data',
            to: '',
            onClick: () => window.open(`${cfg.ftMockApiUri}/mock/reset`),
          },
          {
            title: 'Regenerate data',
            to: '',
            onClick: () => window.open(`${cfg.ftMockApiUri}/mock/reset?regenerate=true`),
          },
        ]
      : []),
    {
      title: 'Logout',
      to: '',
      onClick: () =>
        dispatch({
          type: 'LOGOUT',
        }),
    },
  ];

  return (
    <>
      <Hidden smDown>
        <NavbarTop>
          <Grid container direction={'row'} alignItems={'center'} className={s.grid}>
            <Grid container item xs={2} md={2} lg={2}>
              <Link to='/'>
                <img className={s.logo} src={logo} alt='logo' />
              </Link>
            </Grid>
            <Hidden mdDown>
              <Grid container item xs={3} md={3} lg={2}>
                <Text variant='body2' color='primary' bold>
                  6 WEEK FANTASY SPORTS LEAGUES
                </Text>
              </Grid>
            </Hidden>
            {showNavItems && (
              <>
                <Grid container justifyContent={'center'} item xs={7} md={6} lg={4}>
                  <NavbarAction showLabel={true} to='/lobby' label='Lobby' icon='house' onClick={refreshContests} />
                  <NavbarAction showLabel={true} to={'/leagues'} label='My Leagues' icon='playlist_add_check' />
                  <NavbarAction showLabel={true} to={'/drafts'} label='My Drafts' icon='group' />
                  <NavbarAction
                    onClick={handleOpenNotifications}
                    onClose={handleCloseNotifications}
                    open={Boolean(notificationsAnchor)}
                    showLabel={true}
                    label='Notifications'
                    icon='chat'
                    count={unreadCount}
                    anchorEl={notificationsAnchor}
                    popoverContent={
                      <NotificationPopper notifications={notifications} handleUpdateNotificationsState={handleUpdateNotificationsState} />
                    }
                    isDesktop={isDesktop}
                  />
                </Grid>
              </>
            )}
            {showNavItems && (
              <Grid container justifyContent={'flex-end'} item xs={2} md={4}>
                <Grid item alignContent='center'>
                  <AccountBalance accountBalance={accountBalanceData?.account?.balance} loading={accountBalanceLoading} />
                  <Text display='block' align='center' variant={'caption'} color='textPrimary'>
                    Balance
                  </Text>
                </Grid>
                <Button aria-controls='user-menu' aria-haspopup='true' onClick={(e) => setAnchorEl(e.currentTarget)}>
                <AccountCircle fontSize={'large'} />
                <ExpandMoreIcon />
                </Button>
                <StyledMenu
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  id={'league-menu'}
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}>
                  {menuLinks.map((link) => (
                    <MenuItem
                      key={link.title}
                      onClick={() => {
                        setAnchorEl(null);
                        if (link.onClick) {
                          link.onClick();
                        }
                      }}
                      component={link.to ? Link : 'div'}
                      to={link.to}>
                      {link.title}
                    </MenuItem>
                  ))}
                </StyledMenu>
              </Grid>
            )}
          </Grid>
        </NavbarTop>
      </Hidden>

      {children}

      {showNavItems && (
        <Hidden mdUp>
          <NavbarBottom>
            <NavbarAction onClick={refreshContests} showLabel={true} to='/lobby' icon='house' label={<Text variant='caption'>LOBBY</Text>} />
            <NavbarAction showLabel={true} to='/leagues' icon='playlist_add_check' label={<Text variant='caption'>LEAGUES</Text>} />
            <NavbarAction showLabel={true} to='/drafts' icon='group' label={<Text variant='caption'>DRAFTS</Text>} />
            <NavbarAction
              onClick={handleOpenNotifications}
              onClose={handleCloseNotifications}
              open={Boolean(notificationsAnchor)}
              showLabel={true}
              icon='chat'
              count={unreadCount}
              anchorEl={notificationsAnchor}
              popoverContent={<NotificationPopper notifications={notifications} handleUpdateNotificationsState={handleUpdateNotificationsState} />}
              isDesktop={isDesktop}
              label={<Text variant='caption'>ALERTS</Text>}
            />
            <NavbarAction
              onClick={() => setNavDrawerOpen(true)}
              showLabel={true}
              icon='account_circle'
              label={<Text variant='caption'>ACCOUNT</Text>}
            />
            <SwipeableDrawer onOpen={() => setNavDrawerOpen(true)} open={navDrawerOpen} onClose={() => setNavDrawerOpen(false)}>
              <IconButton className={s.backButton} onClick={() => setNavDrawerOpen(false)}>
                <ArrowBack />
              </IconButton>
              <Grid alignItems={'center'} container direction={'column'} className={s.balance}>
                <AccountCircle fontSize={'large'} />
                <Text paragraph>
                  {customerProfile?.firstName} {customerProfile?.lastName}
                </Text>
                <AccountBalance accountBalance={accountBalanceData?.account?.balance} loading={accountBalanceLoading} />
                <Text variant={'caption'}>Balance</Text>
              </Grid>
              <List className={s.list}>
                {menuLinks.map((link) => (
                  <ListItem divider component={link.to ? Link : 'div'} to={link.to} key={link.title}>
                    <ListItemText
                      onClick={() => {
                        if (link.onClick) {
                          link.onClick();
                        }
                      }}>
                      {link.title}
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </SwipeableDrawer>
          </NavbarBottom>
        </Hidden>
      )}
      {transactionHistoryOpen && <TransactionHistoryDialog open onClose={() => setTransactionHistoryOpen(false)} />}
    </>
  );
};

export default NavMenu;
