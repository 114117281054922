import { func, string } from 'prop-types';
import React, { useState } from 'react';

import { Redirect } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import { HandleError } from 'components/Common/Error';

// Material-UI Components
import Button from '@material-ui/core/Button';
import { GET_CONTESTS, LEAVE_CONTEST_GQL } from 'api/contest';
import { GET_ACCOUNT_BALANCE } from 'components/Navigation/NavMenu/queries';
import { useSnackbarContext } from 'context/SnackbarProvider';

export const LeaveContestAction = ({ contestName, id, handleClose }) => {
  const [_, dispatch] = useSnackbarContext();
  const [{ isJoined }] = useState({
    url: '',
    isJoined: true,
  });

  const [leaveContest, { error, loading }] = useMutation(LEAVE_CONTEST_GQL);

  const handleLeaveContest = async () => {
    await leaveContest({ variables: { contestId: id }, refetchQueries: [{ query: GET_CONTESTS }, { query: GET_ACCOUNT_BALANCE }] });
    if (!error && !loading) {
      dispatch({
        type: 'OPEN',
        snackbarProps: {
          message: `Successfully left ${contestName}`,
        },
      });
      handleClose();
    }
  };

  return (
    <>
      {error && <HandleError error={error.graphQLErrors[0]} />}
      {isJoined ? (
        <Button variant='contained' color='secondary' onClick={handleLeaveContest}>
          Leave League!
          {/* Leave {id} */}
        </Button>
      ) : (
        <Redirect to='/lobby' />
      )}
    </>
  );
};

LeaveContestAction.propTypes = {
  contestName: string,
  id: string,
  handleClose: func,
  setIsSnackbarOpen: func,
  setSnackbarMessage: func,
};

export default LeaveContestAction;
