const LOG_LEVEL = process?.env?.VITE_ENV === 'production' ? 'error' : 'log';
const NO_OP = (message, ...optionalParams) => {};

const COLORS = {
  error: '\x1B[91;1m[ERROR]', // Red
  warn: '\x1b[33;1m[WARN]', // Yellow
  info: '\x1b[36;1m[INFO]', // Cyan
  debug: '\x1b[94;1m[DEBUG]', // Blue
  log: '\x1b[37;1m[LOG]' // White
};

/** Logger which outputs to the browser console */
export class ConsoleLogger {
  constructor(options) {
    const level = options && options.level;

    this.error = console.error.bind(console, COLORS.error);
    this.warn = console.warn.bind(console, COLORS.warn);
    this.info = console.info.bind(console, COLORS.info);
    this.debug = console.debug.bind(console, COLORS.debug);
    this.log = console.log.bind(console, COLORS.log);

    if (level === 'error' || !level) {
      this.warn = NO_OP;
      this.info = NO_OP;
      this.debug = NO_OP;
      this.log = NO_OP;
      return;
    }
    
    if (level === 'warn') {
      this.info = NO_OP;
      this.debug = NO_OP;
      this.log = NO_OP;
      return;
    }
    
    if (level === 'info') {
      this.debug = NO_OP;
      this.log = NO_OP;
      return;
    }
    
    if (level === 'debug') {
      this.log = NO_OP;
      return;
    }
  }
}

export const logger = new ConsoleLogger({ level: LOG_LEVEL });

export default logger;
