export const STATUS = {
  UNVERIFIED: 'UNVERIFIED',
  VERIFIED: 'VERIFIED',
  FAILED: 'VERIFICATION_FAILED',
};

export const LOCATION_CHECK_DISABLED = 'DISABLE_VERIFICATION';

export const STATE_OPTIONS = [
  {
    label: 'Alabama',
    value: 'AL',
  },
  {
    label: 'California',
    value: 'CA',
  },
  {
    label: 'Florida',
    value: 'FL',
  },
  {
    label: 'Georgia',
    value: 'GA',
  },
  {
    label: 'Illinois',
    value: 'IL',
  },
  {
    label: 'Kentucky',
    value: 'KY',
  },
  {
    label: 'Minnesota',
    value: 'MN',
  },
  {
    label: 'Nebraska',
    value: 'NE',
  },
  {
    label: 'New Mexico',
    value: 'NM',
  },
  {
    label: 'North Carolina',
    value: 'NC',
  },
  {
    label: 'North Dakota',
    value: 'ND',
  },
  {
    label: 'Oklahoma',
    value: 'OK',
  },
  {
    label: 'Oregon',
    value: 'OR',
  },
  {
    label: 'Rhode Island',
    value: 'RI',
  },
  {
    label: 'South Carolina',
    value: 'SC',
  },
  {
    label: 'South Dakota',
    value: 'SD',
  },
  {
    label: 'Texas',
    value: 'TX',
  },
  {
    label: 'Utah',
    value: 'UT',
  },
  {
    label: 'Wisconsin',
    value: 'WI',
  },
];
