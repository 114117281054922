import { Skeleton } from '@material-ui/lab';
import Text from 'components/Common/Text';
import { bool, number } from 'prop-types';
import React from 'react';
import { formatCurrency } from 'utils/strings';

const AccountBalance = ({ accountBalance, loading }) => {
  if (loading) {
    return <Skeleton height={24} width={60} />;
  }

  return (
    <Text align='center' bold color='textPrimary'>
      {accountBalance ? formatCurrency(accountBalance) : '$0.00'}
    </Text>
  );
};

AccountBalance.propTypes = {
  accountBalance: number,
  loading: bool,
};

export default AccountBalance;
