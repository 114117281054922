import React from 'react';
import { node } from 'prop-types';

// Material-UI Components
import AppBar from '@material-ui/core/AppBar';

import s from './NavbarTop.module.scss';

export const NavbarTop = ({ children }) => (
  <AppBar className={s.root} elevation={0}>
    {children}
  </AppBar>
);

NavbarTop.propTypes = {
  children: node.isRequired,
};

export default NavbarTop;
