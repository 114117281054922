import { gql } from '@apollo/client';

export const GET_USER_CONTESTS = gql`
  query getContestsOfUser {
    nfl: getContestsOfUser(filter: { sport: { eq: "NFL" }, isActive: { eq: true }, isComplete: { eq: false } }) {
      values {
        contestId
        draftId
        leagueId
        isActive
        isComplete
        isJoinable
        isFeatured
        name
        draft {
          status
        }
        rules {
          sport
          scoring {
            rule
            points
          }
        }
        entry {
          fee
          count
          max
        }
        prize {
          pool
          first
          second
          third
          weekly
        }
        week {
          start
          end
        }
        users
      }
    }
  }
`;