export const AppReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        user: action.state.user,
        token: action.state.token,
        isAuthenticated: action.state.isAuthenticated,
      };
    case 'LOGOUT':
      window.localStorage.clear();
      window.sessionStorage.clear();
      window.location.reload(true);
      return {
        ...state,
        user: null,
        token: null,
        isLoading: true,
        isAuthenticated: false,
      };
    case 'JOIN_CONTEST':
      return {
        ...state,
        user: action.state.user,
      };
    default:
      return state;
  }
};
