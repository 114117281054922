import React from 'react';

import { RuleItem } from '../../components/Rule';
import { Grid } from '@material-ui/core';

export const ScoringListContainer = ({ rules }) => {
  return rules.map(( rule, index ) => (
    <Grid container key={ index } spacing={2}>
      <RuleItem label={ rule.rule } value={ rule.points} />
    </Grid>
  ));
}

export default ScoringListContainer;
