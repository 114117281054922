// THIS MIGHT NOT BE USED ANYMORE!

import { func, string } from 'prop-types';
import React, { useState } from 'react';

import { Redirect } from 'react-router-dom';

// App State/Context
import { useMutation } from '@apollo/client';

// Material-UI Components
import Button from '@material-ui/core/Button';
import { GET_CONTESTS, JOIN_CONTEST_GQL } from 'api/contest';
import { HandleError } from 'components/Common/Error';
import { GET_ACCOUNT_BALANCE } from 'components/Navigation/NavMenu/queries';
import { useSnackbarContext } from 'context/SnackbarProvider';

export const JoinContestAction = ({ contestName, id, handleClose }) => {
  const [_, dispatch] = useSnackbarContext();
  const [{ url, isJoined }, setContestState] = useState({
    url: '',
    isJoined: false,
  });

  const [joinContest, { error, loading }] = useMutation(JOIN_CONTEST_GQL);

  const handleJoinContest = async () => {
    await joinContest({ variables: { contestId: id }, refetchQueries: [{ query: GET_CONTESTS }, { query: GET_ACCOUNT_BALANCE }] });
    if (!error && !loading) {
      setContestState({
        url: '',
        isJoined: isJoined,
      });
      dispatch({
        type: 'OPEN',
        snackbarProps: {
          message: `Successfully joined ${contestName}`,
        },
      });
      handleClose();
    }
  };

  return (
    <>
      {error && <HandleError error={error.graphQLErrors[0]} />}
      {isJoined ? (
        <Redirect to={url} />
      ) : (
        <Button variant='contained' color='primary' onClick={handleJoinContest}>
          Join League!
          {/* Join {id} */}
        </Button>
      )}
    </>
  );
};

JoinContestAction.propTypes = {
  contestName: string,
  id: string,
  handleClose: func,
  setIsSnackbarOpen: func,
  setSnackbarMessage: func,
};

export default JoinContestAction;
