export { dayjs } from './dayjs';
export { formatCurrency } from './strings';
export { fillRosterSlots } from './players';
export { useCurrentWeek } from './useCurrentWeek';
export { useQueryParams } from './useQueryParams';

export const convertToBoolean = (v) => {
  if (!v) return false;
  switch (v.toString().toLowerCase()) {
    case 'no':
    case 'n':
    case '0':
    case 'false':
    case undefined:
    case 'undefined':
    case 'null':
    case null:
    case false:
      return false;
    case 'yes':
    case 'y':
    case '1':
    case 'true':
    case true:
      return true;
    default:
      if (typeof v === 'string') return v.trim();
      return v;
  }
};

