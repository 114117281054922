import { gql } from '@apollo/client';

export const GET_CURRENT_WEEK = gql`
  query getCurrentWeek {
    currentWeek: getCurrentWeek(sport: NFL) {
      season
      week
      sport
    }
  }
`;

export const GET_SIMULATION_STATUS = gql`
  query getSimulationStatus {
    getSimulationStatus(sport: NFL)
  }
`;