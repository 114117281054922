import { gql } from "@apollo/client";

export const GET_CONTEST = gql`
  query getContestById($id: ID!) {
    contest: getContestById(contestId: $id) {
      week {
        start
        end
      }
    }
  }
`;