import React from 'react';
import { oneOfType, number, string } from 'prop-types';

import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';

import Text from '../../../../components/Common/Text';

import s from './DraftPicksPlayerListItem.module.scss';

export const DraftPicksPlayerListItem = (props) => {
  const { fullname, photourl, rosterslot, position, team, byeweek, ...rest } = props;

  return (
    <ListItem {...rest} disableGutters={true} divider={true}>
      <Grid container className={s.grid} alignItems="center">
        <Grid item xs={1}>
          {' '}
          <Text variant="caption" display="block">
            {rosterslot}
          </Text>
        </Grid>
        <Grid item xs={3}>
          {photourl && <Avatar className={s.avatar} alt={fullname} src={photourl} />}
        </Grid>
        <Grid container item xs={6}>
          <Grid item>
            <Text bold variant="caption" display="block">
              {fullname}
            </Text>
            {position && (
              <Text variant="caption" display="block">
                {position} / {team}
              </Text>
            )}
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Text variant="caption" display="block">
            {byeweek && `Bye: ${byeweek}`}
          </Text>
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default DraftPicksPlayerListItem;

DraftPicksPlayerListItem.propTypes = {
  byeweek: oneOfType([number, string]),
  fullname: string,
  photourl: string,
  position: string,
  rosterslot: string,
  team: string,
};
