import { Dialog, DialogContent, Grid, IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import Text from 'components/Common/Text';
import { bool, func } from 'prop-types';
import React from 'react';

import { useQuery } from '@apollo/client';
import CloseIcon from '@material-ui/icons/Close';
import { GET_TRANSACTION_HISTORY } from 'components/Navigation/NavMenu/queries';
import s from './TransactionHistoryDialog.module.scss';

const TransactionHistoryDialog = ({ open, onClose }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { data: transactionsData } = useQuery(GET_TRANSACTION_HISTORY);

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={onClose} classes={{ paper: s.root }}>
      <Grid container alignItems='center'>
        <Grid item xs={2} />
        <Grid item xs={8}>
          <Text variant='h6' component='h2' align='center'>
            Transaction History
          </Text>
        </Grid>
        {fullScreen && (
          <Grid container item xs={2} justifyContent='flex-end'>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <DialogContent className={s.content}>
        {transactionsData?.account?.transactions?.values.length > 0 ? (
          transactionsData.account.transactions.values.map((transaction) => (
            <div key={transaction.timestamp} className={s.transactionItem}>
              <Grid container className={s.itemTitle}>
                <Grid item xs={6}>
                  <Text variant='subtitle2' className={s.date}>
                    {new Date(Date.parse(transaction.timestamp)).toLocaleDateString('en-US', {
                      day: '2-digit',
                      year: 'numeric',
                      month: 'short',
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </Text>
                </Grid>
                <Grid item xs={6}>
                  <Text variant='subtitle2'>{transaction.memo}</Text>
                </Grid>
              </Grid>
              <Text variant='body2' bold>
                Amount:&nbsp;
                <span className={transaction.operation === 'credit' ? s.credit : s.debit}>
                  {transaction.operation === 'credit' ? '+' : '-'}${transaction.amount}
                </span>
              </Text>
              <Text variant='body2' className={s.capitalize}>
                Status: {transaction.status?.toLowerCase()}
              </Text>
            </div>
          ))
        ) : (
          <Text align='center'>You have no transactions</Text>
        )}
      </DialogContent>
    </Dialog>
  );
};

TransactionHistoryDialog.propTypes = {
  open: bool,
  onClose: func,
};

export default TransactionHistoryDialog;
