import { func, number, shape } from 'prop-types';
import React from 'react';

import { Grid } from '@material-ui/core';

import { ContestListItem } from '../../../../components/Contest/ContestList/Item/ContestListItem';

export const ContestList = ({ item, setItem, handleClickOpen }) => {
  const handleOnClick = () => {
    setItem(item);
    handleClickOpen();
  };

  return (
    <Grid container xs={12} variant='outlined'>
        <ContestListItem item={item} setItem={setItem} handleClickOpen={handleClickOpen}/>
    </Grid>
  );
};

export default ContestList;

ContestList.propTypes = {
  item: shape({
    entry: shape({
      count: number,
      max: number,
    }),
    prize: shape({
      pool: number,
      first: number,
      second: number,
      third: number,
      weekly: number,
    }),
  }),
  setItem: func,
  handleClickOpen: func,
};
