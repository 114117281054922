import { arrayOf, func, shape } from 'prop-types';
import React, { useEffect, useRef } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';

import { useAppContext } from 'context/AppProvider';

import Text from 'components/Common/Text';

import s from './DraftOrderStatus.module.scss';

export const DraftOrderStatus = ({ franchises, pick, draftedPlayers, setIsDrafting, draft, setUserId }) => {
  const [{ user }] = useAppContext();
  const userId = user.attributes.sub;
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
    }
    if (draft?.pick?.franchise?.user?.userId === userId) {
      setIsDrafting(true);
    } else {
      setIsDrafting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pick.overall, draftedPlayers, draft.pick.franchise?.user?.userId, setIsDrafting, userId]);

  const avatars = franchises?.map((franchise, i) => (
    <Grid
      container
      direction='column'
      alignItems='center'
      justifyContent='center'
      item
      className={i === pick?.overall - 1 ? `${s.drafting} ${s.avatarBox}` : s.avatarBox}
      ref={i === pick.overall - 1 ? ref : null}
      key={i}
      onClick={() => setUserId(franchise.userId)}>
      <Text align='center' display='block' variant='caption' uppercase>
        {i === pick?.overall - 1 && userId === franchise.userId ? 'your pick' : `pick #${i + 1}`}
      </Text>
      <Avatar className={s.avatarPhoto} src={franchise.user.profile.avatar} alt={franchise.user.profile.displayName} />
      <Text align='center' display='block' variant='caption'>
        {franchise.user.profile.firstName[0]}.{franchise.user.profile.lastName}
      </Text>
    </Grid>
  ));

  return (
    <Grid item xs={7} md={10} className={s.root}>
      <Grid container direction={'row'} alignItems={'center'} wrap={'nowrap'} className={s.scrollGrid}>
        {draft.pick ? (
          avatars
        ) : (
          <Text align='center' variant='h3'>
            Draft Complete
          </Text>
        )}
      </Grid>
    </Grid>
  );
};

export default DraftOrderStatus;

DraftOrderStatus.propTypes = {
  franchises: arrayOf(shape({})),
  pick: shape({}),
  draftedPlayers: arrayOf(shape({})),
  setIsDrafting: func,
  draft: shape({}),
  setUserId: func,
};
