import { gql } from '@apollo/client';

export const GET_PLAYERS = gql`
  query getFantasyPlayers($filter: FilterFantasyPlayerInput) {
    players: getFantasyPlayers(filter: $filter, sort: [{ asc: "fullName" }]) {
      count
      values {
        playerId
        fullName
      }
    }
  }
`;
