import React, { useEffect, useState } from 'react';

// Material-UI Components
import { Grid, Hidden } from '@material-ui/core';

// Components
import DevInfoBlockControls from './DevInfoBlockControls';

import { useCurrentWeek } from '../../../utils/useCurrentWeek';
import { useAppContext } from 'context/AppProvider';

const DevInfoBlock = () => {
  const { currentWeek, simStatus } = useCurrentWeek();
  const [{ user }] = useAppContext();

  return (
    <Grid container>
      <Hidden smDown>
        <DevInfoBlockControls 
          metadata={{ user, currentWeek, simStatus }}
          bottomPosition={0}
        />
      </Hidden>
      <Hidden mdUp> 
        <DevInfoBlockControls
          metadata={{ user, currentWeek, simStatus }}
          bottomPosition={56}
        />
      </Hidden>
    </Grid>
  );
};

export default DevInfoBlock;
