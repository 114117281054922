import PropTypes from 'prop-types';
import React from 'react';

// Components & CSS
import { DraftPicksPlayerListItem, DraftPlayersAvailableListItem, DraftSummaryPlayerListItem } from '../../../components/Draft';

import { makeStyles } from '@material-ui/core/styles';
import { Draggable } from 'react-beautiful-dnd';
import { LeagueRosterPlayerListItem } from '../../../components/League';

export const PlayerListItem = ({ tag, index, isDraggable, item, handleOpenPlayerDialog, ...props }) => {
  const COMPONENT = {
    DraftRoom: DraftPlayersAvailableListItem,
    DraftPicks: DraftPicksPlayerListItem,
    DraftSummary: DraftSummaryPlayerListItem,
    LeagueRoster: LeagueRosterPlayerListItem,
    // LeagueMatchup: LeagueMatchupPlayerListItem,
    // LeagueWaiverWire: LeagueWaiverWirePlayerListItem
  };
  const cn = PlayerListItemCss();

  // Create dynamic component from tag prop
  const PlayerListItem = COMPONENT[tag];

  return isDraggable ? (
    <Draggable draggableId={item.playerId} index={index}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <PlayerListItem
            key={item.playerId}
            player={item}
            handleOpenDialog={() => handleOpenPlayerDialog(item)}
            // handleAddToDraftList={() => handleAddToDraftList(playerId)}
            className={[cn.root, cn.gutters].join(' ')}
            isDraggable={isDraggable}
            {...props}
          />
        </div>
      )}
    </Draggable>
  ) : (
    <PlayerListItem
      key={item.playerId}
      player={item}
      handleOpenDialog={() => handleOpenPlayerDialog(item)}
      // handleAddToDraftList={() => handleAddToDraftList(playerId)}
      className={[cn.root, cn.gutters].join(' ')}
      {...props}
    />
  );
};

PlayerListItem.propTypes = {
  tag: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  handleOpenPlayerDialog: PropTypes.func.isRequired,
};

export default PlayerListItem;

const PlayerListItemCss = makeStyles((theme) => ({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
    width: '100%',
    cursor: 'pointer',
  },
  gutters: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));
