export const fillRosterSlots = (rosterSlots, players) =>
  rosterSlots.reduce((prev, curr) => {
    const playerCopy = players.slice();
    let matchingPlayer;
    let matchIndex;
    if (curr.rosterSlot === 'FLEX') {
      matchIndex = playerCopy.findIndex((player) => player.canFlex);
    } else if (curr.rosterSlot !== 'BN') {
      matchIndex = playerCopy.findIndex((player) => player.fantasyPosition === curr.rosterSlot);
    } else {
      matchIndex = 0;
    }

    if (matchIndex > -1) {
      matchingPlayer = playerCopy[matchIndex];
      players.splice(matchIndex, 1);
    }
    return [...prev, matchingPlayer || curr];
  }, []);
