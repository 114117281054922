import React from 'react';
import { Box, Typography } from '@material-ui/core';

export const ContestComingSoon = ({ sport }) => {

  return (
    <Box alignItems="center">
      <Typography align="center">
        Contests for { sport.toUpperCase() } coming soon!
      </Typography>
    </Box>
  );
};

export default ContestComingSoon;