import React from 'react';

import Container from '@material-ui/core/Container';

import Text from '../../components/Common/Text';

import s from './Account.module.scss';

export const Account = () => {
  return (
    <>
      <Container className={s.root}>
        <Text variant={'h5'}>My Account</Text>
      </Container>
    </>
  );
};

export default Account;
