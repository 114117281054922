import { arrayOf, func, number, shape, string } from 'prop-types';
import React, { useState } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import CompareIcon from '@material-ui/icons/CompareArrows';
import WarningIcon from '@material-ui/icons/Warning';

import Text from 'components/Common/Text';

import { slotIsAvailable } from 'components/League/LeagueRoster/utils';
import { PlayerDialog } from 'components/Player';
import { isNil } from 'lodash';
import { useCurrentWeek } from 'utils/useCurrentWeek';
import s from './LeagueRosterPlayerTable.module.scss';

export const LeagueRosterPlayerTable = ({ draftId, handleMovePlayer, lineup, positions, playersToMove, numStarters, flexSlots, loadingLineup }) => {
  const { currentWeek } = useCurrentWeek();
  const initialPlayerToMove = playersToMove?.[0];
  const [dialogOpen, setDialogOpen] = useState(null);

  return (
    <Grid className={s.root} item container xs={12}>
      <PlayerDialog draftId={draftId} fullScreen player={dialogOpen} handleClosePlayerDialog={() => setDialogOpen(null)} open={Boolean(dialogOpen)} />
      <Grid item container xs={6}>
        {lineup.map((player, idx) => {
          const rosterSlot = positions[idx];

          const getPlayerName = () => {
            if (!player?.firstName) {
              return player?.lastName ?? '-';
            }

            return `${player.firstName[0]}. ${player.lastName}`;
          };

          const hasPoints = !isNil(player.gameStats?.fantasyPointsPpr);

          const moveDisabled =
            loadingLineup ||
            hasPoints ||
            (playersToMove.length === 1 && !slotIsAvailable({ initialPlayerToMove, playersToMove, numStarters, flexSlots, rosterSlot, player }));

          return (
            <Grid key={`${player.playerId} - ${idx}`} container item xs={12} alignItems='center' onClick={() => setDialogOpen(player)}>
              <Grid container item direction='column' alignItems='center' xs={2}>
                <Text display='inline' variant='caption'>
                  {positions[idx]}
                </Text>
                {player.currentInjuryStatus !== null && (
                  <Tooltip title={`Injury status: ${player.currentInjuryStatus}`} placement='top'>
                    <WarningIcon fontSize='inherit' color='error' />
                  </Tooltip>
                )}
              </Grid>
              <Grid item xs={3}>
                <Avatar
                  className={s.avatar}
                  alt={player.fullName}
                  src={player.photoUrl || 'https://cdn.pixabay.com/photo/2014/04/03/10/32/businessman-310819_960_720.png'}
                />
              </Grid>
              <Grid item xs={5}>
                <Text variant='body2' bold>
                  {getPlayerName()}
                </Text>
                <Text variant='caption' display='block'>
                  {player.team} {player.upcomingGameOpponent ? `vs. ${player.upcomingGameOpponent}` : '-'}  Bye: {player.byeWeek}
                </Text>               
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  disabled={moveDisabled}
                  onClick={(e) => handleMovePlayer(e, player, idx)}
                  color={slotIsAvailable({ initialPlayerToMove, playersToMove, numStarters, flexSlots, rosterSlot, player }) ? 'primary' : 'default'}>
                  <CompareIcon
                    className={
                      slotIsAvailable({ initialPlayerToMove, playersToMove, numStarters, flexSlots, rosterSlot, player }) ? s.iconAvailable : s.icon
                    }
                    fontSize='small'
                  />
                </IconButton>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <Grid item container xs={6}>
        <TableContainer>
          <Table>
            <TableBody>
              {lineup.map((player, i) => (
                <TableRow key={`${player.playerId} - ${i}`} className={s.tableRow}>
                  <TableCell align={'center'} className={s.cell}>
                    <Text display={'block'} variant={'caption'}>
                      OPRK
                    </Text>
                    {player.upcomingOpponentRank ? player.upcomingOpponentRank : '-'}
                  </TableCell>
                  <TableCell align={'center'} className={s.cell}>
                    <Text display={'block'} variant={'caption'}>
                      PROJ
                    </Text>
                    {player.gameProjections?.fantasyPointsPpr ? player.gameProjections.fantasyPointsPpr : '-'}
                  </TableCell>
                  <TableCell align={'center'} className={s.cell}>
                    <Text display={'block'} variant={'caption'}>
                      SCORE
                    </Text>
                    {player.gameStats?.fantasyPointsPpr ? player.gameStats.fantasyPointsPpr : '-'}
                  </TableCell>
                  <TableCell align={'center'} className={s.cell}>
                    <Text display={'block'} variant={'caption'}>
                      PRK
                    </Text>
                    {player.seasonStats?.positionRank ? player.seasonStats.positionRank : '-'}
                  </TableCell>
                  <TableCell align={'center'} className={s.cell}>
                    <Text display={'block'} variant={'caption'}>
                      FPTS
                    </Text>
                    {player.seasonStats?.fantasyPointsPpr ? player.seasonStats.fantasyPointsPpr.toFixed(2) : '-'}
                  </TableCell>
                  <TableCell align={'center'} className={s.cell}>
                    <Text display={'block'} variant={'caption'}>
                      AVG
                    </Text>
                    {player.seasonStats ? (player.seasonStats.fantasyPointsPpr / currentWeek).toFixed(1) : '-'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default LeagueRosterPlayerTable;

LeagueRosterPlayerTable.propTypes = {
  handleMovePlayer: func,
  lineup: arrayOf(shape({})),
  positions: arrayOf(string),
  playersToMove: arrayOf(shape({})),
  numStarters: number,
};
