export const slotIsAvailable = ({ playersToMove, initialPlayerToMove, numStarters, flexSlots, rosterSlot, player }) => {
  if (playersToMove.length === 0) {
    return false;
  }

  return (
    initialPlayerToMove.player.fantasyPosition === rosterSlot ||
    initialPlayerToMove.player.fantasyPosition === player.fantasyPosition ||
    (initialPlayerToMove.idx >= numStarters && rosterSlot === 'FLEX' && initialPlayerToMove.player.canFlex === 1) ||
    (initialPlayerToMove.idx >= numStarters && rosterSlot === 'BN') ||
    (flexSlots.includes(initialPlayerToMove.idx) && rosterSlot === 'BN' && player.canFlex === 1)
  );
};
