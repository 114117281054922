import { gql } from '@apollo/client';

export const GET_CURRENT_CUSTOMER = gql`
  query getCurrentCustomer {
    currentCustomer: getCurrentCustomer {
      info {
        firstName
        lastName
        city
        state
        zip
        dobMonth
        dobDay
        dobYear
        country
      }
      status
      geolocationLicense {
        license
        expires
      }
    }
  }
`;

export const GET_CITIES = gql`
  query getCities($input: GetCitiesInput!) {
    cities: getCities(input: $input)
  }
`;

export const VERIFY_GEOLOCATION = gql`
  mutation verifyGeolocation($input: VerifyGeolocationInput!) {
    verifyGeolocation(input: $input) {
      success
      troubleshootingMessages
    }
  }
`;

export const UPSERT_CUSTOMER_INFO = gql`
  mutation upsertCustomerInfo($input: CustomerInfoInput!) {
    upsertCustomerInfo(input: $input) {
      info {
        firstName
        lastName
        city
        state
        zip
        dobMonth
        dobDay
        dobYear
        country
      }
      status
    }
  }
`;

export const VERIFY_CUSTOMER_INFO = gql`
  mutation verifyCustomerInfo {
    verifyCustomerInfo {
      info {
        firstName
        lastName
        city
        state
        zip
        dobMonth
        dobDay
        dobYear
        country
      }
      status
    }
  }
`;
