import { bool, func, node, string } from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import Badge from '@material-ui/core/Badge';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Icon from '@material-ui/core/Icon';
import Popover from '@material-ui/core/Popover';

import s from './NavbarAction.module.scss';

export const NavbarAction = ({ to, label, value, showLabel, icon, color, onClick, count, onClose, open, anchorEl, popoverContent, isDesktop }) => {
  const Wrapper = to ? Link : 'div';

  const linkProps = to ? { to } : {};
  return (
    <Wrapper {...linkProps} className={s.link}>
      <BottomNavigationAction
        onClick={onClick}
        showLabel={showLabel || false}
        className={s.root}
        label={label}
        value={value}
        icon={
          <Badge color="primary" badgeContent={count}>
            <Icon>{icon}</Icon>
          </Badge>
        }
        style={{ color: color }}
      />
      {popoverContent && (
        <Popover
          anchorOrigin={{
            vertical: isDesktop ? 'bottom' : 'top',
            horizontal: 'left',
          }}
          anchorEl={anchorEl}
          onClose={onClose}
          open={open}>
          {popoverContent}
        </Popover>
      )}
    </Wrapper>
  );
};

export default NavbarAction;

NavbarAction.propTypes = {
  to: string,
  label: node,
  value: string,
  showLabel: bool,
  icon: string,
  color: string,
  onClick: func,
  onClose: func,
  anchorEl: node,
  open: bool,
  popoverContent: node,
};
