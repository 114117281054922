import { gql } from '@apollo/client';

export const GET_PLAYERS = gql`
  query getFantasyPlayers($limit: Int!, $skip: Int, $filter: FilterFantasyPlayerInput) {
    players: getFantasyPlayers(filter: $filter, sort: [{ asc: "averageDraftPositionPpr" }], limit: $limit, skip: $skip) {
      count
      values {
        playerId
        fullName
        ... on NflPlayer {
          photoUrl
          byeWeek
          currentInjuryStatus
          seasonStats {
            fantasyPoints
            fantasyPointsPpr
            passingYards
            receivingYards
            rushingYards
            passingTouchdowns
            rushingTouchdowns
            receivingTouchdowns
          }
          gameStats {
            fantasyPoints
            fantasyPointsPpr
            passingYards
            receivingYards
            rushingYards
            passingTouchdowns
            rushingTouchdowns
            receivingTouchdowns
          }
          gameProjections {
            fantasyPoints
            fantasyPointsPpr
            passingYards
            receivingYards
            rushingYards
            passingTouchdowns
            rushingTouchdowns
            receivingTouchdowns
          }
          seasonProjections {
            fantasyPoints
            fantasyPointsPpr
            passingYards
            receivingYards
            rushingYards
            passingTouchdowns
            rushingTouchdowns
            receivingTouchdowns
          }
          fantasyPosition
          team
          averageDraftPositionPpr
        }
      }
    }
  }
`;
