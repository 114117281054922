import React from "react";
import PropTypes from 'prop-types';
import css from "../../../../routes/League/LeagueScores/LeagueScores.module.scss";
import { Avatar } from '@material-ui/core';

export const Matchup = ({ franchiseName, franchiseLogo, wins, losses, fantasyPoints }) => {
  return (
    <div className={css.team}>
      <div className={css.teamData}>
        <Avatar src={franchiseLogo} alt={`${franchiseName} Logo`} />
        {franchiseName}
        <span>
          ({wins}-{losses?.toLocaleString()})
        </span>
      </div>
      <div className={css.fantasyPoints}>
        {fantasyPoints?.toLocaleString()}
      </div>
    </div>
  );
}

Matchup.propTypes = {
  franchiseName: PropTypes.string.isRequired,
  franchiseLogo: PropTypes.string,
  wins: PropTypes.number.isRequired,
  losses: PropTypes.number,
  fantasyPoints: PropTypes.number.isRequired,
};

Matchup.defaultProps = {
  franchiseLogo: '',
  losses: 0,
};

export default Matchup;
