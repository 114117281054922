import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const PlayerDialogStatBox = (props) => {
  const { title, stats } = props;

  const classes = useStyles();

  return (
    <>
      <Box p={1} className={classes.root}>
        <Box className={classes.outer}>
          <Box className={classes.inner}>
            <Typography variant="body2" className={classes.header}>
              { title }
            </Typography>
          </Box>
        </Box>

        <Box className={classes.outer}>
          {(
            Object.keys(stats).map(k => {
              return (
                <Box className={classes.inner} key={ k }>
                  <Typography variant="body2" className={classes.header}>
                    { k }
                  </Typography>
                  <Typography variant="body2">
                    { stats[k] }
                  </Typography>
                </Box>
              )
            })
          )}
        </Box>
      </Box>
    </>
  );
};

PlayerDialogStatBox.propTypes = {
  title: PropTypes.string,
  stats: PropTypes.object.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    display: "block",
    width: "100%"
  },
  outer: {
    display: "flex",
    width: "100%",
    marginBottom: theme.spacing(1)
  },
  inner: {
    flexGrow: 1,
    textAlign: "center"
  },
  header: {
    fontWeight: "bold"
  }
}));

export default PlayerDialogStatBox;