import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import React, { useState } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import WarningIcon from '@material-ui/icons/Warning';

import Text from 'components/Common/Text';
import { PlayerDialog } from 'components/Player';

import { Tooltip, useMediaQuery } from '@material-ui/core';
import { slotIsAvailable } from 'components/League/LeagueRoster/utils';
import { isNil } from 'lodash';
import { useCurrentWeek } from 'utils/useCurrentWeek';
import s from './LeagueRosterPlayerListItem.module.scss';

export const LeagueRosterPlayerListItem = ({
  desktop,
  handleMovePlayer,
  player,
  rosterSlot,
  isRosterTab,
  draftId,
  idx,
  playersToMove,
  numStarters,
  flexSlots,
  loadingLineup
}) => {
  const { currentWeek } = useCurrentWeek();
  const [dialogOpen, setDialogOpen] = useState(false);
  const initialPlayerToMove = playersToMove?.[0];
  const showPosition = isRosterTab || (!isRosterTab && desktop);
  
  const isMobile = useMediaQuery('(max-width:600px)');

  const hasPoints = !isNil(player.gameStats?.fantasyPointsPpr);

  const moveDisabled =
    isRosterTab &&
    (loadingLineup ||
      hasPoints ||
      (playersToMove.length === 1 && !slotIsAvailable({ playersToMove, initialPlayerToMove, numStarters, flexSlots, rosterSlot, player })));

  let shortName;
  if (player?.firstName && player?.lastName) {
    shortName = `${player.firstName[0]}. ${player.lastName}`;
  } else {
    shortName = player.fullName;
  }
  const mobileLayout = (
    <Grid container item xs={12}>
      <Grid container item xs={2} alignContent='center' justifyContent='center'>
                <Text display='inline' variant='body2'>
                  {rosterSlot}
                </Text>
            </Grid>
      <Grid container item xs={3} justifyContent='center'>        
        <Avatar
          style={{ height: 32, width: 32 }}
          alt={player.fullName}
          src={player.photoUrl || 'https://cdn.pixabay.com/photo/2014/04/03/10/32/businessman-310819_960_720.png'}
        />
      </Grid>
      <Grid container item xs={4}>
        <Grid container item xs={12}>
          <Text variant='body2'>
            {shortName}
          </Text>
        </Grid>
        <Grid container item xs={12}>
          <Text variant='caption'>
            {player.team} {player.upcomingGameOpponent && `vs. ${player.upcomingGameOpponent}`} - Bye:{player.byeWeek}
          </Text>
        </Grid>
      </Grid>
      <Grid container item xs={3} justifyContent='center' alignContent='center'>
        <Text variant='body2'>
          {player.gameStats?.fantasyPointsPpr ? player.gameStats.fantasyPointsPpr : '--'}
        </Text>
      </Grid>
    </Grid>
  );

  const desktopLayout = (
    <>
      <ListItem onClick={() => setDialogOpen(true)} className={isRosterTab ? s.rosterRoot : s.lineupRoot} px={-1}>
        <Grid container alignItems='center'>
          <Grid
            container
            item
            xs={isRosterTab ? 6 : 4}
            md={isRosterTab ? 2 : 6}
            alignItems='center'
            justifyContent={desktop ? 'space-between' : 'flex-start'}
            >
            <Grid container item xs={2} justifyContent='center'>
                <Text display='inline' variant='body2'>
                  {rosterSlot}
                </Text>
            </Grid>
            <Grid container item xs={10}>
              <Grid container item justifyContent='center' xs={4}>
                <Avatar
                  style={{ height: 32, width: 32 }}
                  alt={player.fullName}
                  src={player.photoUrl || 'https://cdn.pixabay.com/photo/2014/04/03/10/32/businessman-310819_960_720.png'}
                />
              </Grid>
              <Grid container item xs={7}>
                <Grid item container xs={12}>
                    <Text display='block' variant={desktop ? 'body2' : 'caption'} bold>
                        {shortName}
                    </Text>
                  </Grid>
                  <Grid item container xs={12}>
                    <Text variant='caption' display='block'>
                      {player.team} {player.upcomingGameOpponent && `vs. ${player.upcomingGameOpponent}`}   Bye: {player.byeWeek}
                    </Text>
                </Grid>
              </Grid>
            {isRosterTab && (
              <Grid item xs={1}>
                <Button
                  disabled={moveDisabled}
                  onClick={(e) => handleMovePlayer(e, player, idx)}
                  variant={initialPlayerToMove && initialPlayerToMove.player.playerId === player.playerId ? 'outlined' : 'contained'}
                  color={slotIsAvailable({ playersToMove, initialPlayerToMove, numStarters, flexSlots, rosterSlot, player }) ? 'primary' : 'default'}
                  size='small'>
                  MOVE
                </Button>
              </Grid>
            )}
            </Grid>
          </Grid>
          <Grid container item xs={isRosterTab ? 6 : 8} md={isRosterTab ? 5 : 6} justifyContent='space-between'>
            {/* <Grid item xs={4}>
              <Text variant='body1' align='center'>
              {player.seasonStats?.positionRank ? player.seasonStats.positionRank : '--'}
              </Text>
            </Grid> */}
            <Grid item xs={2} />
            <Grid item xs={5}>
              <Text variant='body1' align='center'>
                {player.gameProjections?.fantasyPointsPpr ? player.gameProjections.fantasyPointsPpr : '--'}
              </Text>
            </Grid>
            <Grid item xs={5}>
              <Text variant='body1' align='center'>
                {player.gameStats?.fantasyPointsPpr ? player.gameStats.fantasyPointsPpr : '--'}
              </Text>
            </Grid>
          </Grid>
          {isRosterTab && (
            <Grid container item md={5} justifyContent='space-between' className={s.borderLeft}>
              {/* <Grid item md={4}>
                <Text variant='body1' align='center'>
                  {player.seasonStats?.positionRank ? player.seasonStats.positionRank : '--'}
                </Text>
              </Grid> */}
              <Grid item xs={2} />
              <Grid item md={5}>
                <Text variant='body1' align='center'>
                  {player.seasonStats?.fantasyPointsPpr ? player.seasonStats.fantasyPointsPpr.toFixed(2) : '--'}
                </Text>
              </Grid>
              <Grid item md={5}>
                <Text variant='body1' align='center'>
                  {player.seasonStats?.fantasyPointsPpr ? (player.seasonStats.fantasyPointsPpr / currentWeek).toFixed(1) : '--'}
                </Text>
              </Grid>
            </Grid>
          )}
        </Grid>
      </ListItem>
      <PlayerDialog draftId={draftId} fullScreen player={player} handleClosePlayerDialog={() => setDialogOpen(false)} open={dialogOpen} />
    </>
  );

  return isMobile ? mobileLayout : desktopLayout;
};

export default LeagueRosterPlayerListItem;

LeagueRosterPlayerListItem.propTypes = {
  desktop: bool,
  handleMovePlayer: func,
  player: shape({}),
  rosterSlot: string,
  isRosterTab: bool,
  draftId: string,
  idx: number,
  playersToMove: arrayOf(shape({})),
  numStarters: number,
  flexSlots: arrayOf(number),
};
