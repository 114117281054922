import React from 'react';
import { number } from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import Text from '../../../../components/Common/Text';

import s from './DraftPlayersAvailableListLabels.module.scss';

export const DraftPlayersAvailableListLabels = ({ currentWeek }) => (
  <Hidden smDown>
    <Grid container spacing={2}>
      <Grid item xs={1} />
      <Grid item xs={3} />
      <Grid item xs={2} />
      <Grid container item justifyContent="center" alignItems="center" xs={3}>
        <Grid item>
          <Text bold variant="body2">
            {new Date().getFullYear()} Stats
          </Text>
        </Grid>
      </Grid>
      <Grid container item justifyContent="center" alignItems="center" xs={3}>
        <Grid item>
          <Text bold variant="body2">
            Week {currentWeek} Projections
          </Text>
        </Grid>
      </Grid>
    </Grid>
    <Grid container spacing={2}>
      <Grid item xs={1} />
      <Grid item xs={3} />
      <Grid item xs={2} />
      <Grid container item alignItems="center" xs={3} className={s.statLabelsOne}>
        <Grid item xs={4}>
          <Text display="block" align="center" bold variant="caption">
            PRK
          </Text>
        </Grid>
        <Grid item xs={4}>
          <Text display="block" align="center" bold variant="caption">
            PTS
          </Text>
        </Grid>
        <Grid item xs={4}>
          <Text display="block" align="center" bold variant="caption">
            AVG
          </Text>
        </Grid>
      </Grid>
      <Grid container item alignItems="center" xs={3} className={s.statLabelsTwo}>
        <Grid item xs={4}>
          <Text display="block" align="center" bold variant="caption">
            YDS
          </Text>
        </Grid>
        <Grid item xs={4}>
          <Text display="block" align="center" bold variant="caption">
            TD
          </Text>
        </Grid>
        <Grid item xs={4}>
          <Text display="block" align="center" bold variant="caption">
            PTS
          </Text>
        </Grid>
      </Grid>
    </Grid>
  </Hidden>
);

DraftPlayersAvailableListLabels.propTypes = {
  currentWeek: number,
};
