import { gql } from '@apollo/client';

export const GET_DRAFT = gql`
  query getDraftById($draftId: ID!) {
    draft: getDraftById(draftId: $draftId) {
      pick {
        franchise {
          userId
        }
      }
    }
  }
`;

export const DRAFT_PLAYER = gql`
  mutation draftPlayer($draftId: ID!, $playerId: Int, $contestId: ID!) {
    draftPlayer(draftId: $draftId, playerId: $playerId, contestId: $contestId) {
      pick {
        number
      }
    }
  }
`;