import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_CONTESTS } from 'api/contest';
import { GET_ACCOUNT_BALANCE } from '../../../components/Navigation/NavMenu/queries';
import { useSnackbarContext } from 'context/SnackbarProvider';

// Material-UI Components
import { Grid, Tooltip } from '@material-ui/core';
import { cfg, logger } from '@/system';

const DevInfoBlockControls = ({ metadata, bottomPosition }) => {
  const { user, currentWeek, simStatus } = metadata;
  const [isEnabled, setIsEnabled] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const [, dispatch] = useSnackbarContext();
  const { refetch: refreshContestsQuery } = useQuery(GET_CONTESTS, { fetchPolicy: 'network-only' });

  // Monitor the simulation status (simStatus.enabled) and update the UI
  useEffect(() => {
    setIsEnabled(simStatus?.enabled ?? false);
  }, [simStatus?.enabled]);

  logger.info('DevInfoBlock', { ...metadata, isEnabled, isResetting });
  
  const handleStartSim = async () => {
    const enableValue = isEnabled ? 'disable' : 'enable';
    await fetch(`${cfg.ftMockApiUri}/simulation/nfl/${enableValue}`);
    setIsEnabled(!isEnabled);
  };

  const handleResetSim = async () => {
    try {
      setIsResetting(true);
      await fetch(`${cfg.ftMockApiUri}/mock/reset`);
      await fetch(`${cfg.ftMockApiUri}/simulation/nfl/reset`);
      await fetch(`${cfg.ftMockApiUri}/simulation/nfl/enable`);
      await fetch(`${cfg.ftMockApiUri}/simulation/nfl/disable`);
      dispatch({
        type: 'OPEN',
        snackbarProps: {
          message: 'Reset simulation!',
        },
      });
    } catch (e) {
      logger.error(e);
    }
    setIsResetting(false);
  };
  const handleSeedSim = async (regenerate = false) => {
    try {
      setIsResetting(true);
      await fetch(`${cfg.ftMockApiUri}/mock/reset?regenerate=true`);
      await refreshContestsQuery({
        refetchQueries: [{ query: GET_CONTESTS }, { query: GET_ACCOUNT_BALANCE }],
      });
      dispatch({
        type: 'OPEN',
        snackbarProps: {
          message: 'Regenerated contest data!',
        },
      });
    } catch (e) {
      logger.error(e);
    }
    setIsResetting(false);
  };

  const getUserToken = () => {
    const token = user.signInUserSession.idToken.jwtToken;
    if (navigator.clipboard) {
      // Get the JWT token from the user's session
      navigator.clipboard.writeText(token)
      .catch(err => logger.error('Could not copy token: ', err));
    }
    logger.debug('JWT Token:', token);
  };
  const getUserId = () => {
    const userId = user.signInUserSession.accessToken.payload.username;
    if (navigator.clipboard) {
      // Get the userId from the user's session
      navigator.clipboard.writeText(userId)
      .catch(err => logger.error('Could not copy user ID: ', err));
    }
    logger.debug('User ID:', userId);
  };

  const playIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M8 5.14v14l11-7z"></path></svg>`;
  const pauseIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M14 19h4V5h-4M6 19h4V5H6z"></path></svg>`;
  const resetIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20"><g fill="currentColor"><path d="M13.937 4.211a1 1 0 0 1-1.126 1.653A5 5 0 1 0 15 10a1 1 0 1 1 2 0a7 7 0 1 1-3.063-5.789"></path><path d="M13.539 12.506a1 1 0 1 1-1.078-1.685l3.482-2.227a1 1 0 0 1 1.077 1.685z"></path><path d="M18.903 12.41a1 1 0 0 1-1.826.815l-1.508-3.38a1 1 0 1 1 1.826-.815z"></path></g></svg>`;
  const dbIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M12 16c.41 0 .81-.03 1.21-.06c.19-.76.51-1.48.95-2.11c-.69.11-1.42.17-2.16.17c-2.42 0-4.7-.6-6-1.55V9.64c1.47.83 3.61 1.36 6 1.36s4.53-.53 6-1.36v1.55c.5-.12 1-.19 1.55-.19c.15 0 .3 0 .45.03V7c0-2.21-3.58-4-8-4S4 4.79 4 7v10c0 2.21 3.59 4 8 4c.66 0 1.31-.04 1.92-.12c-.35-.59-.61-1.24-.76-1.94c-.37.06-.75.06-1.16.06c-3.87 0-6-1.5-6-2v-2.23c1.61.78 3.72 1.23 6 1.23m0-11c3.87 0 6 1.5 6 2s-2.13 2-6 2s-6-1.5-6-2s2.13-2 6-2m11 12.5c0 .82-.25 1.58-.67 2.21l-1.09-1.09c.17-.34.26-.72.26-1.12A2.5 2.5 0 0 0 19 15v1.5l-2.25-2.25L19 12v1.5c2.21 0 4 1.79 4 4m-4 1l2.25 2.25L19 23v-1.5c-2.21 0-4-1.79-4-4c0-.82.25-1.58.67-2.21l1.09 1.09c-.17.34-.26.72-.26 1.12A2.5 2.5 0 0 0 19 20z"></path></svg>`;
  const jwtIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16"><path fill="currentColor" fillRule="evenodd" d="M12.667 6.542A3.208 3.208 0 0 1 8.86 9.694l-.438.492a.437.437 0 0 1-.327.147h-.678v.73a.437.437 0 0 1-.438.437H6.25v.73a.437.437 0 0 1-.437.437H3.77a.437.437 0 0 1-.438-.438v-1.423a.44.44 0 0 1 .128-.31l2.95-2.949a3.208 3.208 0 0 1 3.047-4.214a3.202 3.202 0 0 1 3.209 3.209m-3.209-.875a.875.875 0 1 0 1.75 0a.875.875 0 0 0-1.75 0"></path></svg>`;
  const userIdIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 14 14"><path fill-rule="evenodd" d="M0 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1zm6 2.5a1.5 1.5 0 1 1-3 0a1.5 1.5 0 0 1 3 0M1.547 9.302A3.99 3.99 0 0 1 4.5 8a3.99 3.99 0 0 1 2.953 1.302c.26.284.03.698-.355.698H1.902c-.385 0-.615-.414-.355-.698M8.875 5.5c0-.345.28-.625.625-.625h2a.625.625 0 0 1 0 1.25h-2a.625.625 0 0 1-.625-.625M9.5 7.875a.625.625 0 1 0 0 1.25h2a.625.625 0 0 0 0-1.25z" clip-rule="evenodd" /></svg>`;

  const buttonStyle = { fontSize: '20px', lineHeight: '14px', padding: '0 2px', margin: 0 };

  return (
    <div>
      <div
        style={{
          fontFamily: 'Arial',
          fontSize: '14px',
          position: 'fixed',
          zIndex: 1000,
          bottom: bottomPosition,
          backgroundColor: 'black',
          color: 'white',
          height: '56px',
          width: '100%',
          lineHeight: '56px',
          gap: '20px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <div style={{ 
            gap: '10px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 'bold',
            textTransform: 'uppercase',
          }}>
            <div style={{ color: isEnabled ? '#00FF00' : '#FF0000' }}>{isEnabled ? 'Running' : 'Paused'}</div>
            <Grid container>
              <Tooltip title="Start/Pause simulation">
                <button style={buttonStyle} onClick={handleStartSim} dangerouslySetInnerHTML={{ __html: isEnabled ? pauseIcon : playIcon }}></button>
              </Tooltip>
              <Tooltip title="Reset simulation">
                <button style={buttonStyle} onClick={handleResetSim} dangerouslySetInnerHTML={{ __html: resetIcon }}></button>
              </Tooltip>
              <Tooltip title="Regenerate contest data">
                <button style={buttonStyle} onClick={handleSeedSim} dangerouslySetInnerHTML={{ __html: dbIcon }}></button>
              </Tooltip>
              <Tooltip title="Copy JWT token to clipboard/console">
                <button style={buttonStyle} onClick={getUserToken} dangerouslySetInnerHTML={{ __html: jwtIcon }}></button>
              </Tooltip>
              <Tooltip title="Copy User ID to clipboard/console">
                <button style={buttonStyle} onClick={getUserId} dangerouslySetInnerHTML={{ __html: userIdIcon }}></button>
              </Tooltip>
            </Grid>
          </div>
          <Tooltip title={`Interval: ${simStatus?.interval ?? 'week'} Step: ${simStatus?.intervalStep ?? '1'}`}>
            <div style={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              lineHeight: '14px',
            }}>
              <div style={{ 
                gap: '10px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 'bold',
                textTransform: 'uppercase',
              }}>
                <div style={{ fontWeight: 'bold' }}>Week {currentWeek > 0 ? currentWeek : '--'}</div>
              </div>
              <div>
                {new Date(simStatus?.currentDate).toLocaleString('en-US', { timeZone: 'America/New_York' }).replace(', ', ' ')}
              </div>
            </div>
          </Tooltip>
        </div>
    </div>
  );
};

export default DevInfoBlockControls;
