import { gql } from '@apollo/client';

// Get all leagues for authenticated user
export const GET_LEAGUES = gql`
  query getLeagues {
    leagues: getLeagues {
      count
      values {
        id: leagueId
        name
        contestId
        contest {
          results {
            overall {
              place
              franchise {
                userId
              }
            }
          }
          contestId
          name
          rules {
            sport
            scoring {
              rule
              points
            }
          }
          entry {
            fee
            count
            max
          }
          isFeatured
          prize {
            pool
            first
            second
            third
            weekly
          }
          week {
            start
            end
          }
          users
        }
        week {
          start
          end
        }
        franchises {
          userId
        }
      }
    }
  }
`;
