import React from 'react';
import { bool, string } from 'prop-types';

import Grid from '@material-ui/core/Grid';

import Text from '../../../../components/Common/Text';

import s from './LeagueStandingsHeader.module.scss';

export const LeagueStandingsHeader = ({ divisionName, showStatHeaders }) => {
  return (
    <Grid container alignItems="center" className={s.root}>
      <Grid item xs={8}>
        <Text bold>{divisionName}</Text>
      </Grid>
      {showStatHeaders && (
        <>
          <Grid item xs={2} className={s.center}>
            <Text bold>PF</Text>{' '}
          </Grid>
          <Grid item xs={2} className={s.center}>
            <Text bold>PA</Text>{' '}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default LeagueStandingsHeader;

LeagueStandingsHeader.propTypes = {
  divisionName: string,
  showStatHeaders: bool,
};
