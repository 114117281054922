import React from 'react';
import { func, shape, string } from 'prop-types';

import Card from '@material-ui/core/Card';

import { CurrencyFormat } from '../../../../components/Common/CurrencyFormat';
import Text from '../../../../components/Common/Text';

import s from './DraftListItem.module.scss';

export const DraftListItem = ({ draft, setItem, status }) => {
  const handleOnClick = () => {
    setItem(draft);
  };

  return (
    <Card key={draft.draftId} className={s.card} onClick={handleOnClick}>
      <Text align={'center'} variant={'body2'} bold>
        {draft.name}
      </Text>
      <Text align={'center'} variant={'body2'}>
        {status}
      </Text>
      <Text align={'center'} variant={'body2'}>
        <CurrencyFormat value={draft.entry.fee} includeCents={false} /> entry - <CurrencyFormat value={draft.prize.pool} includeCents={false} /> prize
        pool
      </Text>
    </Card>
  );
};

export default DraftListItem;

DraftListItem.propTypes = {
  draft: shape({}),
  setItem: func,
  status: string,
};
