import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { Button, Grid, TextField, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { GET_PLAYERS } from './queries';

export const PlayerPositionFilter = (props) => {
  const { positions, setFilteredPositions, filteredPositions, setFilteredPlayerId, handleListView, showQueue, className } = props;
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [searchValue, setSearchValue] = useState('');
  const classes = useStyles();

  const { data, loading, error } = useQuery(GET_PLAYERS, {
    variables: {
      filter: { sport: { eq: 'nfl' }, averageDraftPositionPpr: { gt: 0 }, fantasyPosition: { in: filteredPositions } },
    },
  });

  const handleClickAll = () => {
    setFilteredPositions(['QB', 'RB', 'WR', 'TE']);
  };

  const handlePositionToggle = (position) => {
    setFilteredPositions(position === 'FLEX' ? ['RB', 'WR', 'TE'] : [position]);
  };

  const handlePlayerFilter = (playerValue) => {
    setFilteredPlayerId(playerValue ? playerValue.id : null);
  };

  // Filter players by searchValue and filteredPositions
  const options = data?.players?.values?.filter((player) =>
    player.fullName.toLowerCase().includes(searchValue.toLowerCase())
  ) || [];

  const buttons = positions.map((p) => (
    <Button
      key={p}
      aria-label={`${p} button`}
      variant="contained"
      className={classes.button}
      color={filteredPositions.includes(p) && filteredPositions.length < 4 ? 'primary' : 'secondary'}
      onClick={() => handlePositionToggle(p)}
    >
      {p}
    </Button>
  ));

  return (
    <Grid container spacing={2} className={`${classes.root} ${className}`}>
      <Grid item xs={12} lg={6} className={classes.one}>
        <Autocomplete
          autoComplete
          options={options}
          getOptionLabel={(option) => option.fullName}
          onChange={(e, newValue) => handlePlayerFilter(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              placeholder="Search Players"
              className={classes.input}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          )}
        />
        {handleListView && (
          <Grid item xs={12}>
            <Button
              className={classes.queueButton}
              variant={showQueue ? 'outlined' : 'contained'}
              color={!showQueue ? 'primary' : 'default'}
              onClick={() => handleListView(false)}
              title="Draft Room"
            >
              Draft Room
            </Button>
            <Button
              className={classes.queueButton}
              variant={showQueue ? 'contained' : 'outlined'}
              color={showQueue ? 'primary' : 'default'}
              onClick={() => handleListView(true)}
              title="Queue"
            >
              Queue
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} lg={6} className={classes.two}>
        <Button
          aria-label="select all button"
          fullWidth={isMobile}
          color={filteredPositions.length === 4 ? 'primary' : 'secondary'}
          variant="contained"
          onClick={handleClickAll}
        >
          All
        </Button>
        {buttons}
      </Grid>
    </Grid>
  );
};

export default PlayerPositionFilter;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 1rem',
  },
  input: {
    width: '100%',
  },
  one: {
    order: 2,
    [theme.breakpoints.up('md')]: {
      order: 1,
    },
  },
  two: {
    order: 1,
    display: 'flex',
    justifyContent: 'space-around',
    [theme.breakpoints.up('md')]: {
      order: 2,
    },
  },
  queueButton: {
    margin: '4px 8px 0 0',
  },
}));
