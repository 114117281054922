import React from 'react';
import { node, string } from 'prop-types';

import BottomNavigation from '@material-ui/core/BottomNavigation';

import s from './NavbarBottom.module.scss';

export const NavbarBottom = ({ value, children }) => (
  <BottomNavigation value={value} className={s.root}>
    {children}
  </BottomNavigation>
);

NavbarBottom.propTypes = {
  children: node.isRequired,
  value: string,
};

export default NavbarBottom;
