import { useLazyQuery, useQuery, useSubscription } from '@apollo/client';
import { shape } from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Material-UI Components
import { Button, Container, Dialog, DialogActions, DialogTitle, Grid, Hidden, DialogContent} from '@material-ui/core';
import Skeleton, { SkeletonVariant } from 'components/Common/Skeleton';

import { GET_LEAGUES } from 'api/league';
import { DraftPicksPlayerListContainer, DraftSummaryPlayerListContainer } from 'components/Draft';
import { DraftPlayersAvailableListContainer } from 'components/Draft/DraftPlayersAvailable/Container/DraftPlayersAvailableListContainer';
import DraftStatus from 'components/Draft/DraftStatus/DraftStatus';

import { GET_PLAYERS } from 'api/player';
import Layout from 'components/Common/Layout';
import { useAppContext } from 'context/AppProvider';
import s from './DraftDetails.module.scss';
import { DRAFT_SUBSCRIPTION, GET_DRAFT } from './queries';

export const DraftDetails = ({ match }) => {
  const [{ user }] = useAppContext();
  const draftId = match.params.id;
  const LIMIT = 50;
  const [draftedPlayers, setDraftedPlayers] = useState([]);
  const [filteredPositions, setFilteredPositions] = useState(['QB', 'RB', 'WR', 'TE']);
  const [filteredPlayerId, setFilteredPlayerId] = useState(null);
  const [isDrafting, setIsDrafting] = useState(false);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [userId, setUserId] = useState(user.attributes.sub);
  const history = useHistory();
  const [getNewLeagues] = useLazyQuery(GET_LEAGUES, { skip: !user });
  const [pollInterval, setPollInterval] = useState(3000);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [draftedPlayerName, setDraftedPlayerName] = useState('');
  const dialogCloseTimer = useRef(null);

  const [playerId, setPlayerId] = useState(null);

  const [isActiveView, setIsActiveView] = useState({
    draftRoom: true,
    myPicks: false,
    summary: false,
  });

  const {
    data,
    loading: playersLoading,
    error: playersError,
    fetchMore,
  } = useQuery(GET_PLAYERS, {
    variables: {
      limit: LIMIT,
      skip: 0,
      filter: filteredPlayerId
        ? { sport: { eq: 'nfl' }, averageDraftPositionPpr: { gt: 0 }, fantasyPosition: { in: filteredPositions }, playerId: { eq: filteredPlayerId } }
        : { sport: { eq: 'nfl' }, averageDraftPositionPpr: { gt: 0 }, fantasyPosition: { in: filteredPositions } },
    },
  });

  const { data: summary, refetch: refetchDraft } = useQuery(GET_DRAFT, {
    variables: { draftId: draftId },
    pollInterval,
    fetchPolicy: 'network-only',
    onCompleted: ({ getDraftById }) => {
      if (getDraftById?.status === 'active') {
        setPollInterval(0);
      }
      const draftedQuery = summary.getDraftById.summary.map((player) => player.player.playerId);
      setDraftedPlayers(
        (draftedPlayers) => draftedPlayers.concat(draftedQuery) // set here for when a player initially enters a room
      );
    },
  });

  const { error } = useSubscription(DRAFT_SUBSCRIPTION, {
    variables: { draftId: draftId },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      const newPlayer = data.draftPick.pick.player;
      setDraftedPlayers((draftedPlayers) => draftedPlayers.concat({
          playerId: newPlayer.playerId,
          fullName: newPlayer.fullName,
          // Include any other relevant properties of the player object here
      }));
      setDraftedPlayerName(newPlayer.fullName);
      setIsDialogOpen(true);
      
      if (dialogCloseTimer.current) {
        clearTimeout(dialogCloseTimer.current);
      }
      
      dialogCloseTimer.current = setTimeout(() => {
        setIsDialogOpen(false);
      }, 10000);
  
      refetchDraft();
    },
  });
  
  useEffect(() => {
    return () => {
      if (dialogCloseTimer.current) {
        clearTimeout(dialogCloseTimer.current);
      }
    };
  }, []);

  const loadMorePlayers = () => {
    fetchMore({
      variables: {
        skip: data.players.values.length,
        limit: LIMIT,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const fetchMoreCopy = fetchMoreResult;
        if (fetchMoreCopy?.players?.count === 0) {
          setShowLoadMore(false);
        }
        const prevValues = prev?.players?.values || [];
        const newValues = fetchMoreCopy.players.values;
        fetchMoreCopy.players.values = [...prevValues, ...newValues];
        return { ...fetchMoreCopy };
      },
    });
  };

  if (error || playersError) {
    // Handle error (you may want to add a UI for errors)
  }

  const draft = summary?.getDraftById;

  if (!draft) {
    return (
      <Layout>
        <Container>
          <Skeleton height={90} variant={SkeletonVariant.RECT} />
          <Grid container spacing={1}>
            <Grid item lg={2}>
              <Skeleton height={800} variant={SkeletonVariant.RECT} />
            </Grid>
            <Grid item lg={8}>
              <Skeleton height={800} variant={SkeletonVariant.RECT} />
            </Grid>
            <Grid item lg={2}>
              <Skeleton height={800} variant={SkeletonVariant.RECT} />
            </Grid>
          </Grid>
        </Container>
      </Layout>
    );
  }

  const handleRedirectToLeague = () => {
    getNewLeagues();
    let path = `/leagues/${draft.leagueId}`;
    history.push(path);
  };

  const handleRedirectToLobby = () => {
    let path = `/`;
    history.push(path);
  };

  return (
    <>
    <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
      <DialogTitle>Player Drafted</DialogTitle>
      <DialogContent>
        <Text>{draftedPlayerName} was successfully drafted!</Text>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsDialogOpen(false)} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
    <Layout>
      <div>
        <Grid container>
          <Dialog open={draft.status === 'complete'} onClose={handleRedirectToLeague} aria-labelledby='responsive-dialog-title'>
            <DialogTitle className={s.dialog}>Draft is complete</DialogTitle>
            <DialogActions className={s.dialogActions}>
              <Button onClick={handleRedirectToLeague} color='primary'>
                My Roster
              </Button>
              <Button onClick={handleRedirectToLobby} color='primary'>
                Lobby
              </Button>
            </DialogActions>
          </Dialog>
          <DraftStatus draft={draft} draftedPlayers={draftedPlayers} isDrafting={isDrafting} setIsDrafting={setIsDrafting} setUserId={setUserId} />
          <Hidden lgUp>
            <Grid container fullWidth className={s.viewToggle}>
              <Grid
                item
                xs={4}
                style={{ textAlign: 'center' }}
                className={isActiveView.draftRoom ? s.active : s.inactive}
                onClick={() =>
                  setIsActiveView({
                    draftRoom: true,
                    myPicks: false,
                    summary: false,
                  })
                }>
                Draft Room
              </Grid>

              <Grid
                item
                xs={4}
                style={{ textAlign: 'center' }}
                className={isActiveView.myPicks ? s.active : s.inactive}
                onClick={() =>
                  setIsActiveView({
                    draftRoom: false,
                    myPicks: true,
                    summary: false,
                  })
                }>
                My Roster
              </Grid>

              <Grid
                item
                xs={4}
                style={{ textAlign: 'center' }}
                className={isActiveView.summary ? s.active : s.inactive}
                onClick={() =>
                  setIsActiveView({
                    draftRoom: false,
                    myPicks: false,
                    summary: true,
                  })
                }>
                Summary
              </Grid>
            </Grid>
          </Hidden>
          <Hidden lgUp>
            {isActiveView.myPicks && (
              <Grid className={s.one} item xs={12}>
                <DraftPicksPlayerListContainer draft={draft} userId={userId} />
              </Grid>
            )}
            {isActiveView.draftRoom && (
              <Grid className={s.two} item xs={12}>
                <DraftPlayersAvailableListContainer
                  draft={draft}
                  draftedPlayers={draftedPlayers}
                  draftId={draftId}
                  filteredPositions={filteredPositions}
                  isDrafting={isDrafting}
                  isLoading={playersLoading}
                  leagueId={draft?.leagueId}
                  loadMore={loadMorePlayers}
                  players={data ?? {}}
                  playerId={playerId}
                  setFilteredPlayerId={setFilteredPlayerId}
                  setFilteredPositions={setFilteredPositions}
                  setIsDrafting={setIsDrafting}
                  setPlayerId={setPlayerId}
                  showLoadMore={showLoadMore}
                  setShowLoadMore={setShowLoadMore}
                />
              </Grid>
            )}
            {isActiveView.summary && (
              <Grid className={s.three} item xs={12}>
                <DraftSummaryPlayerListContainer draftedPlayers={draftedPlayers} draft={draft} playerId={playerId} setPlayerId={setPlayerId} />
              </Grid>
            )}
          </Hidden>
          <Hidden mdDown>
            <Grid className={s.one} item lg={2}>
              <DraftPicksPlayerListContainer draft={draft} userId={userId} />
            </Grid>
            <Grid className={s.two} item lg={8}>
              <DraftPlayersAvailableListContainer
                leagueId={draft?.leagueId}
                draftId={draftId}
                draft={draft}
                playerId={playerId}
                setPlayerId={setPlayerId}
                draftedPlayers={draftedPlayers}
                isDrafting={isDrafting}
                setIsDrafting={setIsDrafting}
                showLoadMore={showLoadMore}
                players={data ?? []}
                loadMore={loadMorePlayers}
                setFilteredPositions={setFilteredPositions}
                filteredPositions={filteredPositions}
                setFilteredPlayerId={setFilteredPlayerId}
                setShowLoadMore={setShowLoadMore}
              />
            </Grid>
            <Grid className={s.three} item lg={2}>
              <DraftSummaryPlayerListContainer draftedPlayers={draftedPlayers} draft={draft} playerId={playerId} setPlayerId={setPlayerId} />
            </Grid>
          </Hidden>
        </Grid>
      </div>
    </Layout>
    </>
  );
};

export default DraftDetails;

DraftDetails.propTypes = {
  match: shape({}),
};
