import { oneOf } from 'prop-types';
import React from 'react';

import { Snackbar as MuiSnackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import Text from 'components/Common/Text';

import { useSnackbarContext } from 'context/SnackbarProvider';
import s from './Snackbar.modules.scss';

export const Snackbar = () => {
  const [{ snackbarProps, open }, dispatch] = useSnackbarContext();

  return (
    <MuiSnackbar
      classes={{ root: s.root }}
      open={open}
      onClose={() => dispatch({ type: 'CLOSE' })}
      autoHideDuration={5000}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
      {snackbarProps?.message && (
        <Alert onClose={() => dispatch({ type: 'CLOSE' })} severity={snackbarProps?.variant} sx={{ width: '500px' }}>
          <Text>{snackbarProps.message}</Text>
        </Alert>
      )}
    </MuiSnackbar>
  );
};

Snackbar.propTypes = {
  variant: oneOf(['info', 'error', 'success', 'warning']),
};

export default Snackbar;
