import { gql } from '@apollo/client';

export const READY_TO_DRAFT = gql`
  mutation readyToDraft($draftId: ID!) {
    readyToDraft(draftId: $draftId) {
      contest {
        users
      }
      schedule {
        readyUsers
      }
    }
  }
`;
