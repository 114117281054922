import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

// Route components
import { Account, AccountLogin, AccountRegister, AccountReset } from 'routes/Account';
import { DraftDetails, Drafts } from 'routes/Draft';
import { LoggedIn, LoggedOut } from 'routes/Home';
import { LeagueDetails, Leagues } from 'routes/League';
import PrivateRoute from './PrivateRoute/PrivateRoute';

export const Error404 = () => {
  return <div>404 Not Found</div>;
};

/**
 * App navigation including both public and private routes.
 * Use PrivateRoute to require authentication to access.
 *
 * @param {object} props
 */
export const AppRouter = () => {
  return (
    <Router>
      <Switch>
        <Route exact path='/' component={LoggedOut} />
        <PrivateRoute path='/lobby' component={LoggedIn} />

        <PrivateRoute exact path='/leagues' component={Leagues} />
        <PrivateRoute exact path='/leagues/:id' component={LeagueDetails} />

        <PrivateRoute exact path='/drafts' component={Drafts} />
        <PrivateRoute exact path='/drafts/:id' component={DraftDetails} />

        <Route exact path='/login' component={AccountLogin} />
        <Route exact path='/register' component={AccountRegister} />
        <Route exact path='/reset-password' component={AccountReset} />
        <PrivateRoute exact path='/account' component={Account} />
        {/* when none of the above match, render Error404 */}
        <Route component={Error404} />
      </Switch>
    </Router>
  );
};

export default AppRouter;
