import React from 'react';
import { shape } from 'prop-types';

import Box from '@material-ui/core/Box';

import Text from '../../../../components/Common/Text';

import { DraftSummaryPlayerListItem } from '../../../../components/Draft/DraftSummary/Item/DraftSummaryPlayerListItem';

import s from './DraftSummaryPlayerListContainer.module.scss';

export const DraftSummaryPlayerListContainer = ({ draft }) => {
  const summary = Array.from(draft.summary).reverse();

  return (
    <Box className={s.root}>
      <Text bold variant="body1">
        Draft Summary
      </Text>
      {summary.map((pick) => (
        <DraftSummaryPlayerListItem key={pick.player.playerId.toString()} player={pick.player} pick={pick.overall} />
      ))}
    </Box>
  );
};

DraftSummaryPlayerListContainer.propTypes = {
  draft: shape({}),
};
