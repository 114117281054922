import Auth from '@aws-amplify/auth';
import { shape } from 'prop-types';
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import { useAppContext } from 'context/AppProvider';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import Snackbar from 'components/Common/Snackbar';
import Text from 'components/Common/Text';
import { useSnackbarContext } from 'context/SnackbarProvider';
import s from '../Account.module.scss';

export const AccountLogin = ({ location }) => {
  const [{ isAuthenticated }, dispatch] = useAppContext();
  const [{ snackbarProps }, dispatchSnackbar] = useSnackbarContext();

  const [showPassword, setShowPassword] = useState(false);
  const [values, setValues] = useState({
    username: '',
    password: '',
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getAccount = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const token = user.signInUserSession.idToken.jwtToken;

      // Check for a user account and if email/phone has been verified
      if (user.attributes.email_verified || user.attributes.phone_number_verified) {
        //const u = await getUser(client,user.attributes.sub);

        dispatch({
          type: 'LOGIN',
          state: {
            user: user,
            token: token,
            isAuthenticated: true,
          },
        });
      }
    } catch (err) {
      dispatchSnackbar({
        type: 'OPEN',
        snackbarProps: { message: err.message, variant: 'error' },
      });
      setErrors({
        ...errors,
        requirements: true,
      });
    }
  };

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmitAccountInfo = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await Auth.signIn(values.email, values.password)
      .then(() => {
        setIsLoading(false);
        setErrors({});
        getAccount();
      })
      .catch((err) => {
        console.log({ err });
        setIsLoading(false);
        dispatchSnackbar({
          type: 'OPEN',
          snackbarProps: { message: err.message, variant: 'error' },
        });
        setErrors({
          ...errors,
          requirements: true,
        });
      });
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // If we are authenticated redirect to
  // the requested destination or default to the lobby
  const { from } = location.state || { from: { pathname: '/lobby' } };
  if (isAuthenticated === true) {
    return <Redirect to={from} />;
  }

  return (
    <>
      <Snackbar message={snackbarProps.message} variant={snackbarProps.variant} />
      <Container className={s.container}>
        <form className={s.form} onSubmit={handleSubmitAccountInfo}>
          <Text className={s.loginText}>Login to Your Account</Text>
          {errors.message && (
            <Text variant='caption' color='error' paragraph>
              {errors.message}
            </Text>
          )}

          <TextField
            fullWidth
            id='email'
            key='email'
            error={errors.username === 'User with this email already exists'}
            helperText={errors.username && 'User with this email already exists'}
            variant='outlined'
            type='email'
            label='Email'
            name='email'
            onChange={handleChange}
          />
          <TextField
            fullWidth
            id='password'
            key='password'
            name='password'
            variant='outlined'
            type={showPassword ? 'text' : 'password'}
            label='Password'
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'>
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button type='submit' variant='contained' color='primary' onClick={handleSubmitAccountInfo} disabled={isLoading} style={{ width: '100%' }}>
            Next
          </Button>
          <Box className={s.formOptions}>
            <Text color='primary' variant='body2' to='/register'>
              Create Account
            </Text>
            <Text color='primary' variant='body2' to='/reset-password'>
              Forgot Password?
            </Text>
          </Box>
          <Text variant='caption' className={s.tosText}>
            By continuing, you agree to our terms of service.
          </Text>
        </form>
      </Container>
    </>
  );
};

export default AccountLogin;

AccountLogin.propTypes = {
  location: shape({}),
};
