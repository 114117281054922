import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Text from 'components/Common/Text';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import s from './FTError.module.scss';

export const FTErrorMessage = (error) => {
  if (error.message) {
    return error.message;
  }
  return 'UNKNOWN ERROR';
};

export const FTErrorCode = (error) => {
  if (error.graphQLErrors && error.graphQLErrors.size > 0 && error.graphQLErrors[0].code) {
    return error.graphQLErrors[0].code;
  }
  return 'INTERNAL_SERVER_ERROR';
};

export const FTErrorLevel = (error) => {
  if (error.graphQLErrors && error.graphQLErrors.size > 0 && error.graphQLErrors[0].level) {
    return error.graphQLErrors[0].level;
  }
  return 'INTERNALERROR';
};

export const FTErrorExtraInfo = (error) => {
  if (error.graphQLErrors && error.graphQLErrors.size > 0 && error.graphQLErrors[0].extraInfo) {
    return error.graphQLErrors[0].extraInfo;
  }
  return 'NONE';
};

export const FTStack = (error) => {
  if (error.graphQLErrors && error.graphQLErrors.size > 0 && error.graphQLErrors[0].stack) {
    return error.graphQLErrors[0].stack;
  }
  return 'NONE';
};

export const DecodeFTError = (error) => {
  const message = error.message ? error.message : 'UNKNOWN ERROR';
  const infoAvailable = error.graphQLErrors && error.graphQLErrors.length > 0;
  const code = infoAvailable && error.graphQLErrors[0].code ? error.graphQLErrors[0].code : 'INTERNAL_SERVER_ERROR';
  const level = infoAvailable && error.graphQLErrors[0].level ? error.graphQLErrors[0].level : 'INTERNALERROR';
  const extraInfo = infoAvailable && error.graphQLErrors[0].extraInfo ? error.graphQLErrors[0].extraInfo : 'NONE';
  const stack = infoAvailable && error.graphQLErrors[0].stack ? error.graphQLErrors[0].stack : 'hidden';
  return { message, code, level, extraInfo, stack };
};

export const HandleError = ({ error = {}, errorMessage, fallback = 'Error processing request. Please try again or contact support.' }) => {
  const { extensions } = error;
  const message = errorMessage || extensions?.exception?.userMessage || fallback;
  const [open, setOpen] = useState(Boolean(message));
  return (
    <Snackbar
      classes={{ root: s.root }}
      open={open}
      onClose={() => setOpen(false)}
      autoHideDuration={50000}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
      <Alert severity='error' sx={{ width: '500px' }}>
        <Text>{message || fallback}</Text>
      </Alert>
    </Snackbar>
  );
};

FTErrorMessage.propTypes = {
  error: PropTypes.object,
};

FTErrorCode.propTypes = {
  error: PropTypes.object,
};

FTErrorLevel.propTypes = {
  error: PropTypes.object,
};

FTErrorExtraInfo.propTypes = {
  error: PropTypes.object,
};

FTStack.propTypes = {
  error: PropTypes.object,
};

DecodeFTError.propTypes = {
  error: PropTypes.object,
};
