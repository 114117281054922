import { gql } from '@apollo/client';

export const GET_LEAGUES = gql`
  query getLeagues {
    leagues: getLeagues {
      count
      values {
        id: leagueId
        name
        contestId
        isComplete
        contest {
          draft {
            status
          }
          results {
            overall {
              place
              franchise {
                franchiseId
                franchiseLogo
                franchiseName
                record {
                  wins
                  losses
                  ties
                }
                userId
              }
            }
          }
          contestId
          name
          entry {
            fee
            count
            max
          }
          week {
            start
            end
          }
          users
        }
        week {
          start
          end
        }
        franchises {
          userId
          franchiseId
          franchiseName
          franchiseLogo
          record {
            wins
            losses
            ties
          }
        }
        schedule {
          matchup {
            userId
            week
            season
            fantasyPoints
            franchiseId
            opponentId
            sharedMatchupId
            isComplete
            isChampionship
          }
          matchups {
            week
            season
            matchup {
              userId
              week
              season
              fantasyPoints
              franchiseId
              opponentId
              sharedMatchupId
              isComplete
              isChampionship
            }
          }
        }
      }
    }
  }
`;