export const SnackbarReducer = (state, action) => {
  switch (action.type) {
    case 'OPEN':
      return {
        open: true,
        snackbarProps: action.snackbarProps,
      };
    case 'CLOSE':
      return {
        open: false,
        snackbarProps: {},
      };
    default:
      return state;
  }
};
