import { gql } from '@apollo/client';

export const GET_CONTESTS = gql`
  query getContests {
    nfl: getContests(filter: { sport: { eq: "NFL" }, isActive: { eq: true }, isComplete: { eq: false }, isJoinable: { eq: true } }) {
      values {
        contestId
        draftId
        leagueId
        isActive
        isComplete
        isJoinable
        isFeatured
        name
        season
        draft {
          status
        }
        rules {
          sport
          ... on NflRule {
            league {
              type
              style
            }
          }
          scoring {
            rule
            points
          }
        }
        entry {
          fee
          count
          max
        }
        prize {
          pool
          first
          second
          third
          weekly
        }
        week {
          start
          end
        }
        users
      }
    }
  }
`;

export const JOIN_CONTEST_GQL = gql`
  mutation joinContestById($contestId: ID!) {
    joinContestById(contestId: $contestId) {
      isJoined
    }
  }
`;

export const LEAVE_CONTEST_GQL = gql`
  mutation leaveContestById($contestId: ID!) {
    leaveContestById(contestId: $contestId) {
      isJoined
    }
  }
`;
