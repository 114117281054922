import { useMutation, useQuery } from '@apollo/client';
import { useAppContext } from 'context/AppProvider';
import { bool, number, shape, string, arrayOf, func } from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Grid, Card, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Text from 'components/Common/Text';
import { GET_CONTESTS, JOIN_CONTEST_GQL, LEAVE_CONTEST_GQL } from 'api/contest';
import { HandleError } from 'components/Common/Error';
import { GET_ACCOUNT_BALANCE } from 'components/Navigation/NavMenu/queries';
import { useSnackbarContext } from 'context/SnackbarProvider';
import cfg from 'config';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    boxShadow: theme.shadows[3],
    width: '100%',
  },
  title: {
    fontSize: '1rem',
    fontWeight: 'bold',
    paddingBottom: '10px',
    color: theme.palette.primary.main,
  },
  subtitle: {
    fontSize: '0.8rem',
    textTransform: 'uppercase',
    color: theme.palette.text.secondary,
  },
  button: {
    width: '100%',
    padding: theme.spacing(1, 0),
    boxShadow: theme.shadows[3],
  },
  greyButton: {
    backgroundColor: theme.palette.grey[500],
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.grey[700],
    },
  },
}));

export const ContestListItem = ({ item, setItem, handleClickOpen }) => {
  const classes = useStyles();
  const [joinContestMutation, { loading: joinLoading, error: joinError }] = useMutation(JOIN_CONTEST_GQL);
  const [leaveContestMutation, { loading: leaveLoading, error: leaveError }] = useMutation(LEAVE_CONTEST_GQL);
  const { refetch: refreshContestsQuery } = useQuery(GET_CONTESTS, { fetchPolicy: 'network-only' });
  const [, dispatch] = useSnackbarContext();
  const [{ user }] = useAppContext();
  const userId = user.attributes.sub;
  const [isJoined, setIsJoined] = useState(item.isJoined);

  const handleOnClick = () => {
    setItem(item);
    handleClickOpen();
  };

  useEffect(() => {
    setIsJoined(item.users.includes(userId));
  }, [item.users, userId]);

  const joinContest = async (e) => {
    e.stopPropagation();
    try {
      await joinContestMutation({
        variables: { contestId: item.contestId },
        refetchQueries: [{ query: GET_CONTESTS }, { query: GET_ACCOUNT_BALANCE }],
      });
      setIsJoined(true);
      dispatch({
        type: 'OPEN',
        snackbarProps: {
          message: 'Successfully joined league!',
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  const leaveContest = async (e) => {
    e.stopPropagation();
    try {
      await leaveContestMutation({
        variables: { contestId: item.contestId },
        refetchQueries: [{ query: GET_CONTESTS }, { query: GET_ACCOUNT_BALANCE }],
      });
      setIsJoined(false);
      dispatch({
        type: 'OPEN',
        snackbarProps: {
          message: 'Successfully left league',
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  const autofillContest = async (e) => {
    e.stopPropagation();
    try {
      await fetch(`${cfg.ftMockApiUri}/mock/autofill/${item.contestId}`);
      await refreshContestsQuery({
        refetchQueries: [{ query: GET_CONTESTS }, { query: GET_ACCOUNT_BALANCE }],
      });
      dispatch({
        type: 'OPEN',
        snackbarProps: {
          message: 'Autofilled contest',
        },
      });
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <Card className={classes.card} id="contest-list-item" data-testid="contest-list-item">
      {(joinError || leaveError) && (
        <HandleError
          error={joinError?.graphQLErrors[0] || leaveError?.graphQLErrors[0]}
          fallback="Error joining or leaving contest"
        />
      )}
      <Grid container justifyContent="center">
        <Text className={classes.title}>
          {item.rules.sport} {item.name.slice(3, 7)} | {item.entry.max} Team {item.rules.league.type} | WK {item.week.start} - {item.week.end}
        </Text>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4} align="center">
          <Text className={classes.subtitle}>Entries:</Text>
          <Text>{item.entry.count}/{item.entry.max}</Text>
        </Grid>
        <Grid item xs={4} align="center">
          <Text className={classes.subtitle}>Entry Fee:</Text>
          <Text>${item.entry.fee}</Text>
        </Grid>
        <Grid item xs={4} align="center">
          <Text className={classes.subtitle}>Prize Pool:</Text>
          <Text>${item.prize.pool % 1 === 0 ? item.prize.pool : item.prize.pool.toFixed(2)}</Text>
        </Grid>
      </Grid>
      <Grid container spacing={2} justifyContent="space-evenly">
        <Grid item xs={6}>
          <Button
            className={`${classes.button} ${classes.greyButton}`}
            variant="contained"
            onClick={handleOnClick}
          >
            League Info
          </Button>
        </Grid>
        <Grid item xs={6}>
          {item.isJoinable && !isJoined ? (
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={joinContest}
              disabled={joinLoading}
              startIcon={joinLoading && <CircularProgress size={20} />}
            >
              Join
            </Button>
          ) : (
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              onClick={leaveContest}
              disabled={leaveLoading}
              startIcon={leaveLoading && <CircularProgress size={20} />}
            >
              Leave
            </Button>
          )}
        </Grid>
        {cfg.env !== "production" && (
          <Grid container justifyContent="center">
            <Button
              variant="text"
              onClick={autofillContest}
            >
              Autofill
            </Button>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

ContestListItem.propTypes = {
  item: shape({
    contestId: string.isRequired,
    entry: shape({
      count: number.isRequired,
      max: number.isRequired,
      fee: number.isRequired,
    }).isRequired,
    isJoinable: bool.isRequired,
    name: string.isRequired,
    prize: shape({
      pool: number.isRequired,
      first: number,
      second: number,
      third: number,
      weekly: number,
    }).isRequired,
    rules: shape({
      sport: string.isRequired,
      league: shape({
        type: string.isRequired,
      }).isRequired,
    }).isRequired,
    week: shape({
      start: number.isRequired,
      end: number.isRequired,
    }).isRequired,
    users: arrayOf(string).isRequired,
  }).isRequired,
  handleClickOpen: func.isRequired,
};

export default ContestListItem;
