import React, { createContext, useContext, useReducer } from 'react';

// Draft room context
export const DraftContext = createContext();

export const DraftProvider = ({ state, reducer, children }) => (
  <DraftContext.Provider value={useReducer(reducer, state)}>
    {children}
  </DraftContext.Provider>
);

export const useDraftContext = () => useContext(DraftContext);

export default DraftProvider;
