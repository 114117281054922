import React from 'react';
import { Link } from 'react-router-dom';
import { arrayOf, func, shape } from 'prop-types';

import Button from '@material-ui/core/Button';

import Text from '../../../../components/Common/Text';

import { DraftListItem } from '../../../../components/Draft/DraftList/index';

import s from './DraftList.module.scss';

export const DraftList = ({ contests, setItem }) => {
  const active = contests.filter((contest) => !contest.isJoinable);
  const activeDrafts = active.map((contest) => {
    return <DraftListItem status="Active" key={contest.draftId} draft={contest} setItem={setItem} />;
  });

  const pending = contests.filter((contest) => contest.isJoinable);
  const pendingDrafts = pending.map((contest) => {
    return (
      <DraftListItem
        status={`Waiting for ${contest.entry?.max - contest.users?.length} more ${(contest.entry?.max - contest.users?.length) === 1 ? 'player' : 'players'
          }`}
        key={contest.contestId}
        draft={contest}
        setItem={() => null}
      />
    );
  });

  return (
    <>
      <Button fullWidth variant="contained" color="secondary" className={s.button} component={Link} to={'/lobby'}>
        + Join New League
      </Button>
      {activeDrafts.length > 0 ? (
        <Text variant="h5" component="h2" bold className={s.draftStateHeader}>
          Live
        </Text>
      ) : null}
      {activeDrafts}
      {pendingDrafts.length > 0 ? (
        <Text variant="h5" component="h2" bold className={s.draftStateHeader}>
          Waiting on
        </Text>
      ) : null}
      {pendingDrafts}
    </>
  );
};

export default DraftList;

DraftList.propTypes = {
  contests: arrayOf(shape({})),
  setItem: func,
};
