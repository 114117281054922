import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { HandleError } from '../../components/Common/Error';
import Skeleton, { SkeletonVariant } from '../../components/Common/Skeleton';
import { LeagueListItem } from '../../components/League/LeagueList';

import useQueryParams from '../../utils/useQueryParams';

import Layout from 'components/Common/Layout';
import { useAppContext } from 'context/AppProvider';
import s from './Leagues.module.scss';
import { GET_LEAGUES } from './queries';

export const Leagues = () => {
  const [{ isAuthenticated }] = useAppContext();
  const queryParams = useQueryParams();
  const [showCompletedLeagues, setShowCompletedLeagues] = useState(queryParams.get('completed'));

  const { loading, error, data } = useQuery(GET_LEAGUES, { fetchPolicy: 'network-only', skip: !isAuthenticated });
  if (loading) {
    return (
      <Layout>
        <Container className={s.root}>
          <Button fullWidth variant='contained' color='secondary' className={s.button} component={Link} to={'/lobby'}>
            + Join New League
          </Button>
          <Grid container spacing={3}>
            {Array.from(new Array(6)).map((item, idx) => (
              <Grid key={idx} item xs={12} md={6}>
                <Skeleton variant={SkeletonVariant.RECT} height={175} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Layout>
    );
  }
  if (error) {
    return <HandleError error={error.graphQLErrors[0]} fallback='Error getting league details' />;
  }

  const leagues = data?.leagues?.values?.filter((league) => league.contest?.draft?.status === 'complete') ?? [];
  const activeLeagues = leagues?.filter((league) => !league.isComplete);
  const completedLeagues = leagues?.filter((league) => league.isComplete);

  const renderLeagueList = (leagueList) =>
    leagueList.map((league) => {
      return <LeagueListItem key={league.id} league={league} />;
    });

  return (
    <Layout>
      <Container>
        <Button fullWidth variant='contained' color='secondary' className={s.button} component={Link} to={'/lobby'}>
          + Join New League
        </Button>
        <Grid container spacing={3}>
          {renderLeagueList(activeLeagues)}
        </Grid>
        {completedLeagues.length > 0 && (
          <>
            <Grid item>
              <Button fullWidth className={s.completedLeaguesToggle} onClick={() => setShowCompletedLeagues(!showCompletedLeagues)}>
                Completed Leagues {showCompletedLeagues ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </Button>
            </Grid>
            <Collapse in={showCompletedLeagues}>
              <Grid container spacing={3}>
                {renderLeagueList(completedLeagues)}
              </Grid>
            </Collapse>
          </>
        )}
      </Container>
    </Layout>
  );
};

export default Leagues;
