import { gql } from '@apollo/client';

export const GET_LEAGUE_DETAILS = gql`
  query getLeagues($leagueId: ID!) {
  league: getLeagueById(leagueId: $leagueId) {
    id: leagueId
    name
    contestId
    draftId
    contest {
      sport
      results {
        overall {
          place
          franchise {
            franchiseId
            franchiseName
          }
        }
      }
      contestId
      draftId
      name
      rules {
        sport
        ... on NflRule {
          league {
            type
            style
          }
        }
        scoring {
          rule
          points
        }
        roster {
          size
          starters
          positions {
            positionId
            position
            starters
            flex
          }
        }
      }
      entry {
        fee
        count
        max
      }
      isFeatured
      prize {
        pool
        first
        second
        third
        weekly
      }
      week {
        start
        end
      }
      users
    }
    week {
      start
      end
    }
    isComplete
    isRegularSeasonComplete
    schedule {
      matchup {
        matchupId
        sharedMatchupId
        userId
        week
          season
          fantasyPoints
          franchiseId
          opponentId
          opponent {
            fantasyPoints
          }
        franchise {
          user{
            userId
            email
            profile{
              firstName
              lastName
              displayName
              avatar
            }
          }
          franchiseId
          franchiseName
          franchiseLogo
          userId
          division
          record {
            wins
            losses
            ties
            pointsFor
            benchPointsFor
            pointsAgainst
            benchPointsAgainst
          }
        }
        week
        season
        fantasyPoints
        franchiseId
        opponentId
        opponent {
          fantasyPoints
        }
        isComplete
        isChampionship
        roster {
          players {
            fullName
            firstName
            lastName
            playerId
            team
            fantasyPosition
            ... on NflPlayer {
              photoUrl
              currentInjuryStatus
              seasonStats {
                season
                fantasyPointsPpr
                passingYards
                receivingYards
                rushingYards
                passingTouchdowns
                rushingTouchdowns
                receivingTouchdowns
              }
              gameStats {
                season
                seasonType
                week
                fantasyPointsPpr
                passingYards
                receivingYards
                rushingYards
                passingTouchdowns
                rushingTouchdowns
                receivingTouchdowns
              }
              gameProjections {
                season
                seasonType
                week
                fantasyPointsPpr
                passingYards
                receivingYards
                rushingYards
                passingTouchdowns
                rushingTouchdowns
                receivingTouchdowns
              }
              seasonProjections {
                season
                fantasyPointsPpr
                passingYards
                receivingYards
                rushingYards
                passingTouchdowns
                rushingTouchdowns
                receivingTouchdowns
              }
              byeWeek
            }
          }
        }
      }
      matchups {
        season
        week        
        matchup {
          matchupId
          sharedMatchupId
          userId
          week
          division
          season
          fantasyPoints
          franchiseId
          opponentId
          franchise{
            franchiseName
            franchiseLogo
            record{
              wins
              losses
              ties
            }
          division
          }
          roster{
            players{
              playerId
              shortName
              fantasyPosition
              team
              ... on NflPlayer {
                teamId
                playerId
                photoUrl
                currentInjury
                currentInjuryStatus
                byeWeek
                gameProjections{
                  fantasyPoints
                }
                gameStats{
                    fantasyPoints
                    fantasyPointsPpr
                    shortName
                    name
                    team
                    passingYards
                    passingTouchdowns
                    passingInterceptions
                    rushingYards
                    rushingTouchdowns
                    receptions
                    receivingYards
                    receivingTouchdowns
                    fumbles
                    fumblesLost
                    sacks
                    puntReturnTouchdowns
                    kickReturnTouchdowns
                  }
              }
          }
        }
          opponent {
            franchise{
              franchiseName
              franchiseLogo
              division
              record{
                wins
                losses
                ties
              }
            }
            fantasyPoints
          }
          isComplete
          isChampionship
        }
      }
    }
    franchises {
      franchiseName
      franchiseLogo
      roster {
        lineup {
          starters {
            playerId
            firstName
            lastName
            fullName
            shortName
            fantasyPosition
            team
            teamId
          }
        }
      }
      userId
      division
      record {
        wins
        losses
        ties
        pointsFor
        pointsAgainst
      }
      lineupLocked
      rosterLocked
    }
  }
}
`;