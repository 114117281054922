import Auth from '@aws-amplify/auth';
import { shape } from 'prop-types';
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';

import Snackbar from 'components/Common/Snackbar';
import Text from 'components/Common/Text';
import { useAppContext } from 'context/AppProvider';

import { useSnackbarContext } from 'context/SnackbarProvider';
import s from '../Account.module.scss';

export const AccountReset = ({ location }) => {
  const [{ isAuthenticated }, dispatch] = useAppContext();
  const [{ snackbarProps }, dispatchSnackbar] = useSnackbarContext();
  const [isLoading, setIsLoading] = useState(false);
  const [hasSubmittedReset, setHasSubmittedReset] = useState(false);
  const [formFields, setFormFields] = useState({
    username: '',
    code: '',
    password: '',
  });

  const getAccount = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const token = user.signInUserSession.idToken.jwtToken;

      // Check for a user account and if email/phone has been verified
      if (user.attributes.email_verified || user.attributes.phone_number_verified) {
        //const u = await getUser(client,user.attributes.sub);

        dispatch({
          type: 'LOGIN',
          state: {
            user: user,
            token: token,
            isAuthenticated: true,
          },
        });
      }
    } catch (err) {
      dispatchSnackbar({
        type: 'OPEN',
        snackbarProps: { message: err.message, variant: 'error' },
      });
    }
  };

  const handleResetPassword = async (e) => {
    const { username, code, password } = formFields;
    e.preventDefault();
    setIsLoading(true);
    if (!hasSubmittedReset) {
      Auth.forgotPassword(username)
        .then(() => setHasSubmittedReset(true))
        .catch((err) =>
          dispatchSnackbar({
            type: 'OPEN',
            snackbarProps: { message: err.message, variant: 'error' },
          })
        );
    } else {
      Auth.forgotPasswordSubmit(username, code, password)
        .then(() => {
          Auth.signIn(username, password).then((success) => {
            console.log('successful sign in');
            getAccount();
          });
        })
        .catch((err) =>
          dispatchSnackbar({
            type: 'OPEN',
            snackbarProps: { message: err.message, variant: 'error' },
          })
        );
    }
    setIsLoading(false);
  };

  // If we are authenticated redirect to
  // the requested destination or default to the lobby
  const { from } = location.state || { from: { pathname: '/lobby' } };
  if (isAuthenticated === true) {
    return <Redirect to={from} />;
  }

  return (
    <>
      <Snackbar message={snackbarProps.message} variant={snackbarProps.variant} />
      <Container className={s.container}>
        <form className={s.form} onSubmit={handleResetPassword}>
          <Text paragraph>{hasSubmittedReset ? 'Check your email for a confirmation code' : 'Reset your password'}</Text>
          <TextField
            id='email'
            key='email'
            fullWidth
            variant='outlined'
            type='email'
            label='Email'
            name='email'
            onChange={(e) => setFormFields({ ...formFields, username: e.target.value })}
            value={formFields.username}
          />
          {hasSubmittedReset ? (
            <>
              <TextField
                id='code'
                key='code'
                fullWidth
                variant='outlined'
                type='code'
                label='Confirmation Code'
                name='code'
                onChange={(e) => setFormFields({ ...formFields, code: e.target.value })}
                value={formFields.code}
              />
              <TextField
                id='password'
                key='password'
                fullWidth
                variant='outlined'
                type='password'
                label='New Password'
                name='password'
                onChange={(e) => setFormFields({ ...formFields, password: e.target.value })}
                value={formFields.password}
              />
            </>
          ) : null}
          <Button variant='contained' color='primary' onClick={handleResetPassword} disabled={isLoading} fullWidth>
            Reset Password
          </Button>
          <Text color='primary' variant='body2' to='/login'>
            Back to Login
          </Text>
        </form>
      </Container>
    </>
  );
};

export default AccountReset;

AccountReset.propTypes = {
  location: shape({}),
};
