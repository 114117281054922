import React from 'react';
import { number, shape } from 'prop-types';

import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';

import Text from '../../../../components/Common/Text';

import s from './DraftSummaryPlayerListItem.module.scss';

export const DraftSummaryPlayerListItem = ({ pick, player }, props) => {
  return (
    <>
      <ListItem {...props} divider={true} disableGutters>
        <Grid container className={s.grid} alignItems="center">
          <Grid item xs={1}>
            {' '}
            <Text variant="caption">{pick}</Text>
          </Grid>
          <Grid item xs={3}>
            <Avatar className={s.avatar} alt={player.fullName} src={player.photoUrl} />
          </Grid>
          <Grid container item xs={6}>
            <Grid item>
              <Text bold variant="caption" display="block">
                {player.fullName}
              </Text>
              <Text variant="caption" display="block">
                {player.fantasyPosition}/{player.team}
              </Text>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Text variant="caption" display="block">
              Bye:
            </Text>
            <Text variant="caption" display="block">
              {!player.byeWeek ? `-` : player.byeWeek}
            </Text>
          </Grid>
        </Grid>
      </ListItem>
    </>
  );
};

export default DraftSummaryPlayerListItem;

DraftSummaryPlayerListItem.propTypes = {
  pick: number,
  player: shape({}),
};
