import { gql } from '@apollo/client';

export const GET_ACCOUNT_BALANCE = gql`
  query getAccountOfUser {
    account: getAccountOfUser {
      accountId
      balance
    }
  }
`;

export const DEPOSIT_FUNDS = gql`
  mutation deposit($amount: Float!) {
    deposit(amount: $amount) {
      balance
    }
  }
`;

export const WITHDRAW_FUNDS = gql`
  mutation withdraw($amount: Float!) {
    withdraw(amount: $amount) {
      balance
    }
  }
`;

export const GET_TRANSACTION_HISTORY = gql`
  query getAccountOfUser {
    account: getAccountOfUser {
      transactions {
        count
        total
        values {
          transactionId
          debitAccountId
          creditAccountId
          amount
          memo
          status
          operation
          timestamp
          type
        }
      }
    }
  }
`;

export const GET_PAYSAFE_TOKEN = gql`
  query getSingleUseToken {
    singleUseToken: getSingleUseToken {
      singleUseToken
      merchantRefNum
    }
  }
`;
