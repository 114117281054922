import { gql } from '@apollo/client';

export const GET_QUEUE_PLAYERS = gql`
  query getDraftQueue($draftId: ID!) {
    queue: getDraftQueue(draftId: $draftId) {
      players {
        playerId
        fullName
        fantasyPosition
        team
        ... on NflPlayer {
          photoUrl
          byeWeek
          seasonStats {
            fantasyPoints
            fantasyPointsPpr
            passingYards
            receivingYards
            rushingYards
            passingTouchdowns
            rushingTouchdowns
            receivingTouchdowns
          }
          gameStats {
            fantasyPoints
            fantasyPointsPpr
            passingYards
            receivingYards
            rushingYards
            passingTouchdowns
            rushingTouchdowns
            receivingTouchdowns
          }
          gameProjections {
            fantasyPoints
            fantasyPointsPpr
            passingYards
            receivingYards
            rushingYards
            passingTouchdowns
            rushingTouchdowns
            receivingTouchdowns
          }
          seasonProjections {
            fantasyPoints
            fantasyPointsPpr
            passingYards
            receivingYards
            rushingYards
            passingTouchdowns
            rushingTouchdowns
            receivingTouchdowns
          }
          fantasyPosition
          team
          averageDraftPositionPpr
        }
      }
    }
  }
`;

export const ADD_PLAYER_TO_QUEUE = gql`
  mutation addToDraftQueue($draftId: ID!, $playerId: Int) {
    addToDraftQueue(draftId: $draftId, playerId: $playerId) {
      players {
        playerId
      }
    }
  }
`;

export const DROP_PLAYER_FROM_QUEUE = gql`
  mutation dropFromDraftQueue($draftId: ID!, $playerId: Int) {
    dropFromDraftQueue(draftId: $draftId, playerId: $playerId) {
      players {
        playerId
      }
    }
  }
`;

export const REORDER_QUEUE = gql`
  mutation setDraftQueue($draftId: ID!, $players: [Int]) {
    setDraftQueue(draftId: $draftId, players: $players) {
      players {
        playerId
        fullName
        fantasyPosition
        team
        ... on NflPlayer {
          photoUrl
          byeWeek
          seasonStats {
            fantasyPoints
            fantasyPointsPpr
            passingYards
            receivingYards
            rushingYards
            passingTouchdowns
            rushingTouchdowns
            receivingTouchdowns
          }
          gameStats {
            fantasyPoints
            fantasyPointsPpr
            passingYards
            receivingYards
            rushingYards
            passingTouchdowns
            rushingTouchdowns
            receivingTouchdowns
          }
          gameProjections {
            fantasyPoints
            fantasyPointsPpr
            passingYards
            receivingYards
            rushingYards
            passingTouchdowns
            rushingTouchdowns
            receivingTouchdowns
          }
          seasonProjections {
            fantasyPoints
            fantasyPointsPpr
            passingYards
            receivingYards
            rushingYards
            passingTouchdowns
            rushingTouchdowns
            receivingTouchdowns
          }
          fantasyPosition
          team
          averageDraftPositionPpr
        }
      }
    }
  }
`;