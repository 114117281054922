import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

export const CurrencyFormat = props => {
    const { value, includeCents } = props;

    return (
        <NumberFormat value={value} decimalScale={includeCents ? 2: 0} fixedDecimalScale={true} prefix={'$'} displayType={'text'} />
    );
};

CurrencyFormat.propTypes = {
    value: PropTypes.number.isRequired
};

export default CurrencyFormat;