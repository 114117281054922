import React, { createContext, useContext, useReducer } from 'react';

// Global context
export const AppContext = createContext();

export const AppProvider = ({ state, reducer, children }) => (
  <AppContext.Provider value={ useReducer(reducer, state) }>
    { children }
  </AppContext.Provider>
);

export const useAppContext = () => useContext(AppContext);

export default AppProvider;


