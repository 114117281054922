import React, { useEffect } from 'react';

// Apollo client (GraphQL)
import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache, split } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';

import { Auth, Hub } from 'aws-amplify'; // AWS Authentication

// App configuration settings and logger
import { cfg, logger } from '@/system';

// App State/Context
import { useAppContext } from '../../context/AppProvider';

export const AppAuthentication = ({ children }) => {
  const [state, dispatch] = useAppContext();

  /**
   * Signs out of the app and revokes
   * the users tokens
   */
  const handleSignOut = async () => {
    try {
      await Auth.signOut();
    } catch (err) {
      logger.error('Error signing out: ', err);
    }
  };

  const getAccount = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const token = user.signInUserSession.idToken.jwtToken;

      // Check for a user account and if email/phone has been verified
      if (user.attributes.email_verified || user.attributes.phone_number_verified) {
        //const u = await getUser(client,user.attributes.sub);

        dispatch({
          type: 'LOGIN',
          state: {
            user: user,
            token: token,
            isAuthenticated: true,
          },
        });
      }
    } catch (err) {
      logger.error('Error signing in: ', err);
    }
  };

  Hub.listen('auth', (data) => {
    switch (data.payload.event) {
      case 'signIn':
        // User has signed in
        getAccount();
        break;
      case 'signIn_failure':
        break;
      case 'signOut':
        handleSignOut();
        break;
      case 'verify':
        logger.debug('verify');
        break;
      case 'forgotPassword':
        logger.debug('forgot password');
        break;
      default:
        logger.debug(data.payload.event);
        return;
    }
  });

  useEffect(() => {
    getAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const httpLink = new HttpLink({
    uri: cfg.ftApiUri,
  });

  const wsLink = new WebSocketLink({
    uri: cfg.ftWsUri,
    options: {
      connectionParams: () => {
        return {
          authorization: state.token,
        };
      },
      reconnect: true,
    },
  });

  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink
  );

  const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers if there is a token
    if (state.token) {
      operation.setContext({
        headers: {
          authorization: state.token,
        },
      });
    }

    return forward(operation);
  });

  const client = new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          logger.log('----- GraphQL Errors -----');
          logger.log(graphQLErrors);
        }
        if (networkError) {
          logger.log('----- Network Errors -----');
          logger.log(networkError);
        }
      }),
      authMiddleware,
      link,
    ]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            feed: {
              keyArgs: false,
              merge(existing = [], incoming) {
                return [...existing, ...incoming];
              },
            },
          },
        },
      },
    }),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
