import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { HandleError } from 'components/Common/Error';
import { LeagueListItemMatchup } from 'components/League/LeagueMatchup';

// material
import { Button, Card, Grid } from '@material-ui/core';
import { useLeagueContext } from 'context/LeagueProvider';
import Skeleton from '../../Common/Skeleton';
import Text from 'components/Common/Text';

import s from './LeagueListItem.module.scss';

export const LeagueListItem = ({ league }) => {
  const { id, name, contest, week } = league;
  console.log('LEAGUE', league);

  const leagueTypeMatch = name.match((/\(([^)]+)\)/));
  const leagueType = leagueTypeMatch ? leagueTypeMatch[1] : ""; 
  const entryCount = league.contest.entry.count;
  const contestNumber = league.contest.name.slice(3 ,7)

  const { loading, error } = useLeagueContext();

  if (loading) {
    return (
      <Grid item xs={12} md={6}>
        <Card className={s.card} variant='outlined'>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6} style={{ paddingBottom: 0 }}>
                <div className={s.title}>
                  <Skeleton width={200} />
                </div>
              </Grid>
              <Grid item xs={6} style={{ paddingBottom: 0, textAlign: 'right' }}>
                <div className={s.title}>
                  <Skeleton width={100} />
                </div>
              </Grid>
            </Grid>
            <LeagueListItemMatchup leagueId={id} />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div className={[s.title, s.center].join(' ')}>
                  <Skeleton width={150} />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    );
  }
  if (error) {
    return <HandleError error={error.graphQLErrors[0]} fallback='Error getting league info. Please refresh the page.' />;
  }

  return (
    <Grid container item xs={12} md={6}>
      <Card className={s.card} variant='outlined'>
        <Grid container item xs={12}>
          <Grid container item xs={12}>
            <Grid container item xs={9} style={{ paddingBottom: 0 }}>
              <Text color='primary' bold className={s.title}>
                NFL  {contestNumber} | {entryCount} Team {leagueType} | WK {week.start} - {week.end}
              </Text>
            </Grid>
            <Grid container item xs={2} justifyContent='center' style={{ paddingBottom: 0, textAlign: 'right' }}>
              <Text color='primary' bold className={s.title}>Entry: {contest.entry ? `$${contest.entry.fee}` : '$100'}</Text>
            </Grid>
          </Grid>
          <LeagueListItemMatchup league={league} />
          <Grid container spacing={3}>
            <Grid container item xs={12}>
              <Grid container item xs={6} justifyContent='center' className={[s.title, s.center].join(' ')}>
                <Button className={s.button} component={Link} variant="contained" to={`/leagues/${id}`}>
                  My Roster
                </Button>
              </Grid>
              <Grid container item xs={6} justifyContent='center' className={[s.title, s.center].join(' ')}>
                <Button className={s.button} component={Link} variant="contained" color="primary" to={`/leagues/${id}?tab=matchup`}>
                  Matchup
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

LeagueListItem.propTypes = {
  league: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    contest: PropTypes.object,
    week: PropTypes.object,
  }),
};

export default LeagueListItem;
