import Auth from '@aws-amplify/auth';
import clsx from 'clsx';
import { useAppContext } from 'context/AppProvider';
import { shape } from 'prop-types';
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import Snackbar from 'components/Common/Snackbar';
import Text from 'components/Common/Text';
import { useSnackbarContext } from 'context/SnackbarProvider';
import s from '../Account.module.scss';

export const AccountRegister = ({ location }) => {
  const [{ isAuthenticated }, dispatch] = useAppContext();
  const [{ snackbarProps }, dispatchSnackbar] = useSnackbarContext();

  const [showPassword, setShowPassword] = useState(false);
  const [values, setValues] = useState({
    password: '',
    passwordConfirm: '',
    email: '',
  });
  const [errors, setErrors] = useState({});
  const [verificationCode, setVerificationCode] = useState('');
  const [verify, setVerify] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getAccount = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const token = user.signInUserSession.idToken.jwtToken;

      // Check for a user account and if email/phone has been verified
      if (user.attributes.email_verified || user.attributes.phone_number_verified) {
        //const u = await getUser(client,user.attributes.sub);

        dispatch({
          type: 'LOGIN',
          state: {
            user: user,
            token: token,
            isAuthenticated: true,
          },
        });
      }
    } catch (err) {
      dispatchSnackbar({
        type: 'OPEN',
        snackbarProps: { message: err.message, variant: 'error' },
      });
    }
  };

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleVerify = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await Auth.confirmSignUp(values.email, verificationCode);
      await Auth.signIn(values.email, values.password);
      await getAccount();
    } catch (err) {
      dispatchSnackbar({
        type: 'OPEN',
        snackbarProps: { message: err.message, variant: 'error' },
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendVerificationCode = async () => {
    try {
      await Auth.resendSignUp(values.email);
    } catch (err) {
      dispatchSnackbar({
        type: 'OPEN',
        snackbarProps: { message: err.message, variant: 'error' },
      });
    }
  };

  const handleSubmitAccountInfo = async (e) => {
    e.preventDefault();

    if (!values.email || !values.password || !values.passwordConfirm) {
      setErrors({
        email: values.email ? '' : 'Required',
        password: values.password ? '' : 'Required',
        passwordConfirm: values.passwordConfirm ? '' : 'Required',
      });
      return;
    }

    if (values.password !== values.passwordConfirm) {
      setErrors({
        ...errors,
        passwordConfirm: 'Password must match',
      });
      return;
    }
    setIsLoading(true);

    try {
      await Auth.signUp({
        username: values.email,
        password: values.password,
        attributes: {
          email: values.email,
        },
      });
      setVerify(true);
      setErrors({});
    } catch (err) {
      if (err.message === 'User already exists') {
        setErrors({ ...errors, email: 'User with this email already exists' });
      } else {
        dispatchSnackbar({
          type: 'OPEN',
          snackbarProps: { message: err.message, variant: 'error' },
        });
        setErrors({
          ...errors,
          password: 'Password must include at least one lowercase, one uppercase, one number and one special character',
          passwordConfirm: true,
        });
      }
    }
    setIsLoading(false);
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // If we are authenticated redirect to
  // the requested destination or default to the lobby
  const { from } = location.state || { from: { pathname: '/lobby' } };
  if (isAuthenticated === true) {
    return <Redirect to={from} />;
  }

  return (
    <>
      <Snackbar message={snackbarProps.message} variant={snackbarProps.variant} />
      <Container className={s.container}>
        {verify && (
          <form className={s.form} onSubmit={handleVerify}>
            <Text paragraph>{errors.message ? errors.message : 'Check Your Email For A Verification Code'}</Text>
            <TextField
              fullWidth
              id='verificationCode'
              key='verificationCode'
              variant='outlined'
              type='text'
              label='Verification Code'
              name='verificationCode'
              onChange={(e) => setVerificationCode(e.target.value)}
            />
            <Button fullWidth type='submit' variant='contained' color='primary' onClick={handleVerify} disabled={isLoading}>
              Next
            </Button>
            <Button fullWidth onClick={handleResendVerificationCode} disabled={isLoading} className={s.newCodeButton}>
              Send New Code
            </Button>
          </form>
        )}
        {!verify && (
          <form className={s.form} onSubmit={handleSubmitAccountInfo}>
            <Text>Create an Account</Text>
            {errors.message && (
              <Text variant='caption' color='error' paragraph>
                {errors.message}
              </Text>
            )}
            <TextField
              id='email'
              key='email'
              fullWidth
              error={Boolean(errors.email)}
              helperText={errors.email}
              variant='outlined'
              type='email'
              label='Email'
              name='email'
              onChange={handleChange}
            />
            <TextField
              fullWidth
              id='password'
              key='password'
              name='password'
              error={Boolean(errors.password)}
              helperText={errors.password}
              variant='outlined'
              type={showPassword ? 'text' : 'password'}
              label='Password'
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'>
                      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              id='passwordConfirm'
              key='passwordConfirm'
              name='passwordConfirm'
              error={Boolean(errors.passwordConfirm)}
              helperText={errors.passwordConfirm}
              variant='outlined'
              type={showPassword ? 'text' : 'password'}
              label='Confirm Password'
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'>
                      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button type='submit' variant='contained' color='primary' onClick={handleSubmitAccountInfo} disabled={isLoading} fullWidth>
              Next
            </Button>
            <Box className={clsx([s.formOptions, s.centered])}>
              <Text color='primary' variant='body2' to='/login'>
                Have An Account? Sign In
              </Text>
            </Box>
            <Text variant='caption' className={s.tosText}>
              By continuing, you agree to our terms of service.
            </Text>
          </form>
        )}
      </Container>
    </>
  );
};

export default AccountRegister;

AccountRegister.propTypes = {
  location: shape({}),
};
