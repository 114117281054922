import { useQuery } from '@apollo/client';
import { func } from 'prop-types';
import React from 'react';

import Grid from '@material-ui/core/Grid';
import { DraftList } from 'components/Draft/DraftList/List/DraftList';

import { HandleError } from 'components/Common/Error';
import Skeleton, { SkeletonVariant } from 'components/Common/Skeleton';
import Text from 'components/Common/Text';

import s from './DraftListContainer.module.scss';
import { GET_USER_CONTESTS } from './queries';

export const DraftListContainer = ({ setItem }) => {
  const { loading, error, data } = useQuery(GET_USER_CONTESTS, { fetchPolicy: 'network-only' });

  if (loading) return <Skeleton numberOfRows={5} height={72} variant={SkeletonVariant.RECT} />;
  if (error) {
    return <HandleError error={error.graphQLErrors[0]} fallback='Error getting contests' />;
  }

  const count = data?.nfl?.count;
  const contests = data?.nfl?.values.filter((contest) => contest.draft?.status !== 'complete');

  return (
    <Grid container alignItems='center' direction='column' className={s.draftStateList}>
      {count === 0 ? <Text paragraph>You have no drafts.</Text> : null}
      <DraftList contests={contests} setItem={setItem} />
    </Grid>
  );
};

DraftListContainer.propTypes = {
  setItem: func,
};

export default DraftListContainer;
