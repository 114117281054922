import React from 'react';
import { number, shape, string } from 'prop-types';

import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';

import Skeleton from '../../../../../components/Common/Skeleton';
import Text from '../../../../../components/Common/Text';

import s from './LeagueListItemMatchupEntry.module.scss';

export const Loader = () => {
  return (
    <Grid container spacing={1} alignItems="center" className={s.root}>
      <Grid item xs={2} className={s.center}>
        <Avatar className={s.avatar} src="" />
      </Grid>
      <Grid item xs={4}>
        <Text>
          <Skeleton width={100} />
        </Text>
      </Grid>
      <Grid item xs={2}>
        <Text>
          <Skeleton width={50} />
        </Text>
      </Grid>
      <Grid item xs={4}>
        <Text align="right">
          <Skeleton width={50} />
        </Text>
      </Grid>
    </Grid>
  );
};

export const LeagueListItemMatchupEntry = ({ avatar, name, record, earnedPoints, place }) => {
  const getOrdinal = (string) => {
    switch (string) {
      case 1:
        return `${string}st`;
      case 2:
        return `${string}nd`;
      case 3:
        return `${string}rd`;
      default:
        return `${string}th`;
    }
  };

  return (
    <Grid container spacing={1} alignItems="center" className={s.root}>
      <Grid item xs={2} className={s.center}>
        <Avatar className={s.avatar} src={avatar} />
      </Grid>
      <Grid item xs={4}>
        <Text data-testid="name-record" bold>
          {name ? name : 'Team'}  ({record.wins}-{record.losses})
        </Text>
      </Grid>
      <Grid container item justifyContent='center' xs={2}>
        <Text color='secondary' data-testid="place">{place ? getOrdinal(place) : 'T-1st'}</Text>
      </Grid>
      <Grid container item xs={4} justifyContent='center'>
      <Text color='primary' data-testid="earned-points" bold>{earnedPoints ? earnedPoints : '-'}</Text>
      </Grid>
    </Grid>
  );
};

LeagueListItemMatchupEntry.propTypes = {
  avatar: string,
  name: string,
  record: shape({}),
  earnedPoints: string,
  place: string,
};

export default LeagueListItemMatchupEntry;
