import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as SplashLogoSvg } from '../../assets/logos/splash_logo.svg';
import TextLogoSvg from '../../assets/logos/text_logo.png';


export const LoadingLogo = () => {
  const classes = useStyles();
  return (
    <div className={ classes.root }>
      <svg width="100" height="100" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" className="lds-double-ring">
        <circle cx="50" cy="50" fill="none" strokeLinecap="round" r="40" strokeWidth="4" stroke="#43127d" strokeDasharray="62.83185307179586 62.83185307179586" transform="rotate(302.945 50 50)">
          <animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 50;360 50 50" keyTimes="0;1" dur="2s" begin="0s" repeatCount="indefinite"></animateTransform>
        </circle>
        <circle cx="50" cy="50" fill="none" strokeLinecap="round" r="35" strokeWidth="4" stroke="#e5bf80" strokeDasharray="54.97787143782138 54.97787143782138" strokeDashoffset="54.97787143782138" transform="rotate(-302.945 50 50)">
          <animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 50;-360 50 50" keyTimes="0;1" dur="2s" begin="0s" repeatCount="indefinite"></animateTransform>
        </circle>
      </svg>
    </div>
  )
};

export const TextLogo = (props) => {
  const width = props.width || 300;
  return (
    <>
      <img src={TextLogoSvg} width={width} alt='' />
    </>
  )
};

export const SplashLogoAndSlogan = () => {
  const classes = useStyles();
  return (
  <div className={ classes.root }>
    <div className={ classes.logo }>
      <SplashLogoSvg />
    </div>
    <div className={ classes.slogan }>
      6 Week Fantasy Sports Leagues
    </div>
  </div>
  );
};

export const SplashLogo = () => {
  const classes = useStyles();
  return (
    <div className={ classes.root }>
      <div className={ classes.logo }>
        <SplashLogoSvg />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  logo: {
    maxWidth: 320
  },
  slogan: {
    textTransform: 'uppercase',
    fontWeight: 700,
  },

}));