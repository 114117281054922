import cfg from 'root/config';
const simulator = cfg?.paysafe?.simulator ?? 'EXTERNAL';
const environment = cfg?.paysafe?.environment ?? 'LIVE';
const email = cfg.env === 'development' ? 'sandbox+customer@fantasythrone.com' : user.attributes.email;

export const Checkout_USD = ({ singleUseToken, user }) => {

  const config = {
    currency: 'USD',
    amount: 0,
    payout: false,
    locale: 'en_US',
    simulator,
    environment,
    companyName: 'FantasyThrone',
    holderName: `${user.firstName} ${user.lastName}`,
    customer: {
      firstName: user.firstName,
      lastName: user.lastName,
      email,
    },
    displayPaymentMethods: ["card", "paypal", "skrill"],
    merchantRefNum: singleUseToken?.merchantRefNum,
    canEditAmount: true,
    amountOptions: [1000, 5000, 10000],
    threeDs: {
      merchantUrl: 'https://www.fantasythrone.com/',
      deviceChannel: 'BROWSER',
      messageCategory: 'PAYMENT',
      authenticationPurpose: 'PAYMENT_TRANSACTION',
      transactionIntent: 'GOODS_OR_SERVICE_PURCHASE',
    },
    paymentMethodDetails: {
      card: {
        accountId: 1002670400,
      },
      paypal: {
        accountId: 1002670700,
        consumerId: email,
      },
      skrill: {
        accountId: 1002734720,
        consumerId: email,
        emailSubject: 'Fantasy Throne Skrill Deposit',
        emailBody: 'Thank You for your deposit at Fantasy Throne!',
        emailMessage: 'Your deposit has been processed successfully!',
      },
    },
    singleUseCustomerToken: singleUseToken?.singleUseToken,
  };

  return config;
};

export const Payout_USD = ({ singleUseToken, user, accountBalance }) => {

  const config = {
    currency: 'USD',
    amount: 0,
    payout: true,
    locale: 'en_US',
    simulator,
    environment,
    companyName: 'FantasyThrone',
    holderName: `${user.firstName} ${user.lastName}`,
    customer: {
      firstName: user.firstName,
      lastName: user.lastName,
      email,
    },
    displayPaymentMethods: ["skrill"],
    merchantRefNum: singleUseToken?.merchantRefNum,
    canEditAmount: true,
    amountOptions: [1000, 5000, 10000],
    threeDs: {
      merchantUrl: 'https://www.fantasythrone.com/',
      deviceChannel: 'BROWSER',
      messageCategory: 'PAYMENT',
      authenticationPurpose: 'PAYMENT_TRANSACTION',
      transactionIntent: 'GOODS_OR_SERVICE_PURCHASE',
    },
    paymentMethodDetails: {
      skrill: {
        accountId: 1002734720,
        consumerId: 'sandbox+customer@fantasythrone.com',
        emailSubject: 'Fantasy Throne Skrill Withdraw',
        emailBody: 'Congratulations on your withdraw at Fantasy Throne!',
        emailMessage: 'Your payout has been processed successfully!',
      },
    },
    payoutConfig: {
      maximumAmount: accountBalance * 100,
      payoutMethod: "skrill",
    },
    singleUseCustomerToken: singleUseToken?.singleUseToken,
  };

  return config;
};
