import { shape } from 'prop-types';
import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Switch from '@material-ui/core/Switch';

import Text from 'components/Common/Text';
import { ContestDialog } from 'components/Contest';

import { ClockFormat } from 'components/Common/ClockFormat';
import { dayjs } from 'utils/dayjs';

import { useMutation } from '@apollo/client';
import { Button } from '@material-ui/core';
import { READY_TO_DRAFT } from 'components/Draft/DraftStatus/DraftPickStatus/queries';
import { useAppContext } from 'context/AppProvider';
import { useSnackbarContext } from 'context/SnackbarProvider';
import { UPDATE_DRAFT_USER_SETTINGS } from 'routes/Draft/DraftDetails/queries';
import s from './DraftPickStatus.module.scss';

const now = () => {
  return new Date();
};

const calculateTimeLeft = (expiration) => {
  return (expiration.getTime() - now().getTime()) / 1000;
};

export const DraftPickStatus = ({ draft, pick }) => {
  const [{ user }] = useAppContext();
  const userId = user.attributes.sub;
  const [_, dispatch] = useSnackbarContext();
  const { userSettings, schedule, contest } = draft;
  const expiration = new Date(pick.expiration);
  const draftStart = new Date(draft.schedule?.start);
  const [open, setOpen] = useState(false);
  const [timeLeft, setTimeLeft] = useState(pick?.duration);
  const [timeUntilDraft, setTimeUntilDraft] = useState(null);
  const [isAwaitingDraftStart, setIsAwaitingDraftStart] = useState(draft?.status === 'awaiting');
  const [isAutoDrafting, setIsAutoDrafting] = useState(userSettings?.autoDraftEnabled);
  const [isReadyToDraft, setIsReadyToDraft] = useState(false);
  const [showTimeRunningOut, setShowTimeRunningOut] = useState(true);

  const [updateUserSettings] = useMutation(UPDATE_DRAFT_USER_SETTINGS, {
    onCompleted: ({ userSettings }) => setIsAutoDrafting(userSettings?.autoDraftEnabled),
  });

  const [setReady] = useMutation(READY_TO_DRAFT, {
    onCompleted: ({ readyToDraft }) => {
      setIsReadyToDraft(true);
      if (readyToDraft?.contest?.users?.length === readyToDraft?.schedule?.readyUsers?.length) {
        setIsAwaitingDraftStart(false);
      }
    },
  });
  const handleSetReady = async () => {
    await setReady({
      variables: { draftId: draft.draftId },
    });
  };

  const handleSetAutoDraft = async (checked) => {
    await updateUserSettings({
      variables: { draftId: draft.draftId, settings: { autoDraftEnabled: checked } },
    });
  };

  useEffect(() => {
    setIsAutoDrafting(userSettings?.autoDraftEnabled);
  }, [userSettings?.autoDraftEnabled]);

  useEffect(() => {
    if (schedule?.readyUsers?.length === contest?.users?.length) {
      setIsAwaitingDraftStart(false);
    }
  }, [schedule?.readyUsers?.length, contest?.users?.length]);

  useEffect(() => {
    setIsReadyToDraft(schedule?.readyUsers?.includes(userId));
  }, [schedule?.readyUsers]);

  useEffect(() => {
    setIsAutoDrafting(userSettings?.autoDraftEnabled);
  }, [userSettings?.autoDraftEnabled]);

  useEffect(() => {
    setShowTimeRunningOut(true);
  }, [pick.overall]);

  useEffect(() => {
    if (!isAutoDrafting && !isAwaitingDraftStart && timeLeft > 9 && timeLeft < 11 && pick.franchise?.user?.userId === userId && showTimeRunningOut) {
      dispatch({
        type: 'OPEN',
        snackbarProps: { message: '10 seconds left to pick', variant: 'warning' },
      });
      setShowTimeRunningOut(false);
    }
  }, [isAwaitingDraftStart, isAutoDrafting, timeLeft, pick.franchise?.user?.userId, userId, showTimeRunningOut]);

  useEffect(() => {
    if (!isAwaitingDraftStart || dayjs().isAfter(draft?.schedule?.start)) {
      return;
    }

    setTimeout(() => {
      const newTimeUntilDraft = Math.round(calculateTimeLeft(draftStart));
      setTimeUntilDraft(newTimeUntilDraft);
    }, 1000);
  });

  useEffect(() => {
    if (isAwaitingDraftStart) {
      return;
    }
    // For the first pick, calculate based on the start time of the draft since expiration is null. Subtract timeUntilDraft to account for all users being ready early
    const timeToCalculate = pick?.expiration
      ? expiration
      : new Date(dayjs(draftStart).subtract(timeUntilDraft, 'second').add(pick?.duration, 'second'));
    setTimeout(() => {
      const newTimeLeft = Math.max(0, Math.min(pick?.duration, calculateTimeLeft(timeToCalculate)));
      setTimeLeft(newTimeLeft);
    }, 1000);
  }, [pick?.expiration, pick?.duration, draftStart, isAwaitingDraftStart, draft?.schedule?.start]);

  useEffect(() => {
    if (dayjs().isBefore(draft?.schedule?.start) || timeUntilDraft > 0) {
      return;
    }
    setIsAwaitingDraftStart(false);
  }, [draft?.status, draft?.schedule?.start, timeUntilDraft]);

  return (
    <Grid container item className={s.root} xs={5} md={2} alignItems='center' justifyContent='space-between' direction='column'>
      <Grid container alignItems='center' item direction='column'>
        {isAwaitingDraftStart && (
          <Button onClick={handleSetReady} color='primary' size='small' variant='outlined' disabled={isReadyToDraft}>
            {isReadyToDraft ? "I'm Ready" : 'Ready to Draft'}
          </Button>
        )}
        {isAwaitingDraftStart ? (
          <Text variant='caption'>Draft starts in</Text>
        ) : (
          <Text variant='caption'>
            R{pick?.round ?? '-'} / P{pick?.number ?? '-'}
          </Text>
        )}
      </Grid>
      <Grid container item alignItems='center' justifyContent='center' direction='row'>
        <Text align='center' variant='caption'>
          <ClockFormat seconds={isAwaitingDraftStart ? timeUntilDraft : timeLeft} />
        </Text>
      </Grid>
      <Grid item container alignItems='center' direction='column'>
        <Switch
          onChange={(e, checked) => handleSetAutoDraft(checked)}
          checked={isAutoDrafting}
          color='primary'
          inputProps={{ 'aria-label': 'primary checkbox' }}
          size='small'
        />
        <Text display='block' align='center' variant='caption'>
          Auto
        </Text>
        <Grid container item alignItems='center' justifyContent='center'>
          <Grid container alignItems='center' item justifyContent='center'>
            <Icon fontSize='small' onClick={() => setOpen(true)}>
              info_outline&nbsp;
            </Icon>
          </Grid>
          <Grid container alignItems='center' item justifyContent='center'>
            <Text align='center' variant='caption'>
              {draft.contest.name}
            </Text>
          </Grid>
        </Grid>
      </Grid>
      <ContestDialog open={open} handleClose={() => setOpen(false)} item={draft.contest} fullScreen={false} />
    </Grid>
  );
};

export default DraftPickStatus;

DraftPickStatus.propTypes = {
  draft: shape({}),
};
