import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import css from './LeagueScoresModal.module.scss';
import { Grid, Avatar, Tooltip, Divider, Button } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Text from 'components/Common/Text';
import { Pagination } from '@material-ui/lab';
import { PlayerDialog } from 'components/Player';


const PlayerDetails = ({ player }) => (
  <Grid container item xs={7} md={6} alignItems="center">
    <Grid container item xs={3} md={3} justifyContent="center">
      <Avatar src={player.photoUrl} alt={player.shortName} />
      <Grid xs={1} md={1} />
    </Grid>
    <Grid container xs={9} md={9}>
      <Grid xs={1} md={1} />
      <Grid container xs={11} md={11} direction="column">
        <Grid item align="left">{player.shortName}</Grid>
        <Grid item align="left">{player.team}/Bye: {player.byeWeek}</Grid>
      </Grid>
    </Grid>
  </Grid>
);

const PlayerStatus = ({ player }) => (
  <Grid container item xs={1} md={1} justifyContent="center">
    {player.currentInjuryStatus && (
      <Tooltip title={`Injury status: ${player.currentInjuryStatus} : ${player.currentInjury}`} placement="top">
        <WarningIcon fontSize="inherit" color="error" />
      </Tooltip>
    )}
  </Grid>
);

const PlayerStats = ({ player, label, isMobile }) => (
  <Grid container item xs={isMobile ? 6 : 2} justifyContent="center">
    {player[label]?.[0]?.fantasyPoints !== undefined
      ? player[label][0].fantasyPoints.toFixed(2)
      : '--'}
  </Grid>
);

// Team header component
const TeamHeader = ({ team, isHigherScore }) => {
  const desktop = useMediaQuery('(min-width: 1200px)'); // Desktop specific

  if (!team) {
    return (
      <Grid container xs={8} justifyContent='center' item className={css.teamHeader}>
        <Text variant={desktop ? 'body1' : 'subtitle3'}>
          Team data is unavailable
        </Text>
      </Grid>
    );
  }

  return (
    <Grid container xs={12} md={8} justifyContent='center' item className={css.teamHeader}>
      <Grid item xs={3} md={3} container justifyContent="center" alignItems="center">
        <Avatar src={team.franchiseLogo} alt={team.franchiseName} />
      </Grid>
      <Grid item xs={6} md={6} container direction="column" alignItems="center">
        <Grid container item justifyContent="center">
          <Text variant={desktop ? 'body1' : 'subtitle1'}>
            {team.franchiseName.slice(0, 7)}
          </Text>
        </Grid>
        <Grid container xs={3} md={3} item justifyContent="center">
          <Text variant={desktop ? 'body2' : 'subtitle2'}>
            ({team.wins}-{team.losses})
          </Text>
        </Grid>
      </Grid>
      <Grid container item xs={3} md={3} justifyContent="center" alignItems="center">
        <Text
          variant={desktop ? 'body1' : 'subtitle2'}
          bold
          className={isHigherScore ? css.primaryScore : ''}
        >
          {team.fantasyPoints}
        </Text>
      </Grid>
    </Grid>
  );
};

const TeamRoster = ({ players }) => {
  const isMobile = useMediaQuery('(max-width: 599px)');
  const tabletOrDesktop = useMediaQuery('(min-width: 960px)')


  const primaryPositions = ['QB', 'RB', 'RB', 'WR', 'WR', 'WR', 'TE', 'FLEX', 'FLEX'];
  const benchPosition = 'BN';

  const sortPlayers = (players) => {
    const roster = Array(primaryPositions.length).fill(null);
    const bench = [];
    const flexCandidates = [];

    const positionGroups = {
      QB: [],
      RB: [],
      WR: [],
      TE: [],
    };

    players.forEach((player) => {
      const { fantasyPosition } = player;
      if (positionGroups[fantasyPosition]) {
        positionGroups[fantasyPosition].push(player);
      } else {
        bench.push(player);
      }
    });

    const assignPlayers = (position, count) => {
      while (count > 0 && positionGroups[position].length > 0) {
        roster[primaryPositions.indexOf(position, roster.indexOf(null))] =
          positionGroups[position].shift();
        count--;
      }
    };

    assignPlayers('QB', 1);
    assignPlayers('RB', 2);
    assignPlayers('WR', 3);
    assignPlayers('TE', 1);

    ['RB', 'WR', 'TE'].forEach((pos) => {
      flexCandidates.push(...positionGroups[pos]);
    });

    while (roster.includes(null) && flexCandidates.length > 0) {
      roster[roster.indexOf(null)] = flexCandidates.shift();
    }

    Object.keys(positionGroups).forEach((pos) => {
      bench.push(...positionGroups[pos]);
    });

    const remainingBench = [...bench].map((player) => ({
      ...player,
      displayPosition: benchPosition,
    }));

    return [...roster, ...remainingBench];
  };

  const sortedPlayers = sortPlayers(players);

  return (
    <Grid container xs={12} md={12}>
      {sortedPlayers.map((player, index) => (
        <React.Fragment key={player.id || index}>
          {index === 9 && (
            <Grid item xs={12} md={12}>
              <Divider style={{ width: '100%', backgroundColor: 'grey', margin: '10px 0' }} />
            </Grid>
          )}
          <Grid container xs={12} md={12} alignItems="center" className={css.teamPlayer}>
            {tabletOrDesktop && (
              <Grid item xs={1} md={1}>
                {index < 9 ? primaryPositions[index] : player.displayPosition || 'BN'}
              </Grid>
            )}
            <Grid container item xs={tabletOrDesktop ? 11 : 12} justifyContent='space-evenly'
            alignItems='center'>
            <PlayerDetails player={player} />
            <PlayerStatus player={player} />

            {!isMobile && 
            <PlayerStats 
            player={player}
            label="gameProjections" 
            isMobile={isMobile}
            />
          }

            <PlayerStats  player={player} 
            label="gameStats" />
            </Grid>

          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
};

function Modal({ isOpen, onClose, allGames, initialPage }) {
  const isMobile = useMediaQuery('(max-width: 599px)');
  const isTablet = useMediaQuery('(min-width: 600px) and (max-width: 1199px)');
  const isDesktop = useMediaQuery('(min-width: 1200px)');
  const [currentPage, setCurrentPage] = useState(initialPage || 1);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState(null);

  const handlePlayerClick = (player) => {
    setSelectedPlayer(player);
    setDialogOpen(true);
  };


  useEffect(() => {
    if (initialPage) {
      setCurrentPage(initialPage);
    }
  }, [initialPage]);

  const handlePaginationChange = (event, value) => {
    setCurrentPage(value);
  };

  const currentMatchup = allGames[currentPage - 1];

  const homeTeam = currentMatchup?.[0] || {};
  const awayTeam = currentMatchup?.[1] || {};

  const homePlayers = homeTeam.roster || [];
  const awayPlayers = awayTeam.roster || [];

  const count = allGames.length;

  const isHomeTeamHigher = homeTeam.fantasyPoints > awayTeam.fantasyPoints;

  const calculateProjectedScore = (players) => {
    const primaryPositions = ['QB', 'RB', 'RB', 'WR', 'WR', 'WR', 'TE', 'FLEX', 'FLEX'];
    const startingLineup = players.slice(0, primaryPositions.length);

    return startingLineup.reduce((total, player) => {
      const projectedPoints = player.gameProjections?.[0]?.fantasyPoints || 0;
      return total + projectedPoints;
    }, 0).toFixed(2);
  };

  // Calculate projected scores
  const homeProjectedScore = calculateProjectedScore(homePlayers);
  const awayProjectedScore = calculateProjectedScore(awayPlayers);

  if (!isOpen) return null;

  const handleOverlayClick = () => {
    onClose();
  };

  const handleContentClick = (e) => {
    e.stopPropagation();
  };



  return (
    <Grid
    container
    xs={12}
    md={12}
    className={css.modalOverlay}
    onClick={handleOverlayClick}
    role="button"
    tabIndex={0}
  >
    <Grid className={css.modalContent} onClick={handleContentClick}>
      <Grid container xs={12} md={12} justifyItems='center' spacing={1}>
        <Button onClick={onClose} aria-label="Close modal">
          X
        </Button>
      </Grid>
      {isDesktop ? (
        // Desktop Layout
        <Grid container xs={12} md={12} justifyContent="space-evenly">
          <Grid item xs={5} md={5} align='center'>
            <TeamHeader team={homeTeam} isHigherScore={isHomeTeamHigher} />
          </Grid>
          <Grid className={css.dividerVertical} item md={1} align="center">
            <Divider orientation="vertical" flexItem style={{ height: '100%', backgroundColor: 'grey' }} />
          </Grid>
          <Grid item xs={5} md={5} align='center'>
            <TeamHeader team={awayTeam} isHigherScore={!isHomeTeamHigher} />
          </Grid>
          <Grid item xs={12} md={12} className={css.dividerHorizontal}>
            <Divider orientation="horizontal" style={{ width: '100%', backgroundColor: 'grey', margin: '10px 0' }} />
          </Grid>
          <Grid container xs={12}>
            <Grid item xs={1}/>
            <Grid item alignContent='center' align='center' xs={3}>Proj: {homeProjectedScore}</Grid>
            <Grid container item justifyContent='center' xs={4}>
            <Pagination
                count={count}
                page={currentPage}
                onChange={handlePaginationChange}
                size="small"
              />
            </Grid>
            <Grid item alignContent='center' align='center' xs={4}>Proj:{awayProjectedScore}</Grid>
          </Grid>
          <Grid item xs={12} md={12} className={css.dividerHorizontal}>
            <Divider orientation="horizontal" style={{ width: '100%', backgroundColor: 'grey', margin: '10px 0' }} />
          </Grid>
          <Grid item xs={5} md={5}>
            <Grid container xs={12} md={12}>
              <Grid item xs={8} md={8} />
              <Grid item xs={2} md={2} align="center">
                PROJ
              </Grid>
              <Grid item xs={2} md={2} align="center">
                SCORE
              </Grid>
              <Grid item xs={12} md={12}>
                <TeamRoster players={homePlayers} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={css.dividerVertical} md={1} align="center">
            <Divider orientation="vertical" flexItem style={{ height: '100%', backgroundColor: 'grey' }} />
          </Grid>
          <Grid item xs={5} md={5}>
            <Grid container xs={12} md={12}>
              <Grid item xs={8} md={8} />
              <Grid item xs={2} md={2} align="center">
                PROJ
              </Grid>
              <Grid item xs={2} md={2} align="center">
                SCORE
              </Grid>
              <Grid item xs={12} md={12}>
                <TeamRoster players={awayPlayers} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : isTablet ? (
        // Tablet Layout
        <Grid container xs={12} md={12} justifyContent="space-evenly">
          <Grid item xs={5} md={5} align='center'>
            <TeamHeader team={homeTeam} isHigherScore={isHomeTeamHigher} />
          </Grid>
          <Grid className={css.dividerVertical} item md={1} align="center">
            <Divider orientation="vertical" flexItem style={{ height: '100%', backgroundColor: 'grey' }} />
          </Grid>
          <Grid item xs={5} md={5} align='center'>
            <TeamHeader team={awayTeam} isHigherScore={!isHomeTeamHigher} />
          </Grid>
          <Grid item xs={12} md={12} className={css.dividerHorizontal}>
            <Divider orientation="horizontal" style={{ width: '100%', backgroundColor: 'grey', margin: '10px 0' }} />
          </Grid>
          <Grid container xs={12}>
            <Grid item alignContent='center' align='center' xs={4}>Proj: {homeProjectedScore}</Grid>
            <Grid container item justifyContent='center' xs={4}><Pagination 
            count={count} 
            defaultPage={1}
            siblingCount={0}
            size='small'
            // page={page} onChange={handlePagination}
            ></Pagination></Grid>
            <Grid item alignContent='center' align='center' xs={4}>Proj: {awayProjectedScore}</Grid>
          </Grid>
          <Grid item xs={12} md={12} className={css.dividerHorizontal}>
            <Divider orientation="horizontal" style={{ width: '100%', backgroundColor: 'grey', margin: '10px 0' }} />
          </Grid>
          <Grid item xs={5} md={5}>
            <Grid container xs={12} md={12}>
              <Grid item xs={8} md={8} />
              <Grid item xs={2} md={2} align="center">
                PROJ
              </Grid>
              <Grid item xs={2} md={2} align="center">
                SCORE
              </Grid>
              <Grid item xs={12} md={12}>
                <TeamRoster players={homePlayers} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={css.dividerVertical} md={1} align="center">
            <Divider orientation="vertical" flexItem style={{ height: '100%', backgroundColor: 'grey' }} />
          </Grid>
          <Grid item xs={5} md={5}>
            <Grid container xs={12} md={12}>
              <Grid item xs={8} md={8} />
              <Grid item xs={2} md={2} align="center">
                PROJ
              </Grid>
              <Grid item xs={2} md={2} align="center">
                SCORE
              </Grid>
              <Grid item xs={12} md={12}>
                <TeamRoster players={awayPlayers} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        // Mobile Layout
        <>
          <Grid container xs={12} md={12} justifyContent="space-evenly">
          <Grid item xs={5} md={5} align='center'>
            <TeamHeader team={homeTeam} isHigherScore={isHomeTeamHigher} />
          </Grid>
          <Grid className={css.dividerVertical} item md={1} align="center">
            <Divider orientation="vertical" flexItem style={{ height: '100%', backgroundColor: 'grey' }} />
          </Grid>
          <Grid item xs={5} md={5} align='center'>
            <TeamHeader team={awayTeam} isHigherScore={!isHomeTeamHigher} />
          </Grid>
          <Grid item xs={12} md={12} className={css.dividerHorizontal}>
            <Divider orientation="horizontal" style={{ width: '100%', backgroundColor: 'grey', margin: '10px 0' }} />
          </Grid>
          <Grid container xs={12}>
            <Grid item alignContent='center' align='center' xs={4}>Proj: {homeProjectedScore}</Grid>
            <Grid container item justifyContent='center' xs={4}><Pagination 
            count={count} 
            defaultPage={1}
            siblingCount={0}
            size='small'
            // page={page} onChange={handlePagination}
            ></Pagination></Grid>
            <Grid item alignContent='center' align='center' xs={4}>Proj:{awayProjectedScore}</Grid>
          </Grid>
          <Grid item xs={12} md={12} className={css.dividerHorizontal}>
            <Divider orientation="horizontal" style={{ width: '100%', backgroundColor: 'grey', margin: '10px 0' }} />
          </Grid>
          <Grid item xs={5} md={5}>
            <Grid container xs={12} md={12}>
              <Grid item xs={8} md={8} />
              <Grid item xs={1} md={1} />
              <Grid item xs={isMobile ? 3 : 2} align="center">
                SCORE
              </Grid>
              <Grid item xs={12} md={12}>
                <TeamRoster players={homePlayers} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={css.dividerVertical} md={1} align="center">
            <Divider orientation="vertical" flexItem style={{ height: '100%', backgroundColor: 'grey' }} />
          </Grid>
          <Grid item xs={5} md={5}>
            <Grid container xs={12} md={12}>
              <Grid item xs={8} md={8} />
              <Grid item xs={1} md={1} />
              <Grid item xs={isMobile ? 3 : 2} align="center">
                SCORE
              </Grid>
              <Grid item xs={12} md={12}>
                <TeamRoster players={awayPlayers} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        </>
      )}
    </Grid>
    </Grid>
  );
}

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  matchup: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.string,
      roster: PropTypes.arrayOf(
        PropTypes.shape({
          fantasyPosition: PropTypes.string,
          id: PropTypes.string,
          shortName: PropTypes.string,
          team: PropTypes.string,
          byeWeek: PropTypes.number,
          photoUrl: PropTypes.string,
          currentInjuryStatus: PropTypes.string,
          currentInjury: PropTypes.string,
          gameProjections: PropTypes.arrayOf(
            PropTypes.shape({
              fantasyPoints: PropTypes.number,
            })
          ),
          gameStats: PropTypes.arrayOf(
            PropTypes.shape({
              fantasyPoints: PropTypes.number,
            })
          ),
        })
      ),
      franchiseLogo: PropTypes.string,
      franchiseName: PropTypes.string,
      wins: PropTypes.number,
      losses: PropTypes.number,
      fantasyPoints: PropTypes.number,
    })
  ).isRequired,
};

export default Modal;
