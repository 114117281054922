import { useMutation } from '@apollo/client';
import { UPDATE_NOTIFICATION } from 'components/Common/NotificationPopper/queries';
import { arrayOf, func, shape } from 'prop-types';
import React from 'react';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Text from 'components/Common/Text';

import { NOTIFICATION_MESSAGE } from 'constants/notifications';

import { formatCurrency } from 'utils/strings';
import s from './NotificationPopper.module.scss';

const NotificationPopper = ({ notifications, handleUpdateNotificationsState }) => {
  const [updateNotification] = useMutation(UPDATE_NOTIFICATION);
  const hasNotifications = notifications.length > 0;

  return (
    <Box sx={{ maxWidth: 360, padding: hasNotifications ? 0 : 8 }}>
      {hasNotifications ? (
        <List disablePadding className={s.list}>
          {notifications.map((notification, idx) => (
            <>
              <ListItem
                key={notification.notificationId}
                className={s.listItem}
                disablePadding
                onClick={() => {
                  updateNotification({ variables: { id: notification.notificationId, isRead: true } });
                  handleUpdateNotificationsState(notification.notificationId);
                }}>
                <Text bold={!notification.isRead} variant='body2' color='textPrimary'>
                  {NOTIFICATION_MESSAGE[notification.type] || ''}
                  {notification.additionalData?.prize ? ` + ${formatCurrency(notification.additionalData.prize)}` : ''}. Click to{' '}
                  <Text
                    bold={!notification.isRead}
                    variant='body2'
                    color='primary'
                    to={`/leagues/${notification.additionalData?.leagueId}`}
                    component='span'>
                    view your league
                  </Text>
                  &nbsp;or&nbsp;
                  <Text bold={!notification.isRead} variant='body2' color='primary' to='/lobby' component='span'>
                    draft a new team.
                  </Text>
                </Text>
              </ListItem>
              {idx !== notifications.length - 1 && <Divider />}
            </>
          ))}
        </List>
      ) : (
        <Text>No Notifications</Text>
      )}
    </Box>
  );
};

NotificationPopper.propTypes = {
  notifications: arrayOf(shape({})),
  handleUpdateNotificationsState: func,
};

export default NotificationPopper;
