import React, { useState } from 'react';

import { func, string } from 'prop-types';

import { Container, Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import { ContestComingSoon, ContestDialog, ContestListContainer } from 'components/Contest';

import Layout from 'components/Common/Layout';
import s from './LoggedIn.module.scss';

const TabContainer = ({ sport, filters, setItem, handleClickOpen }) => {
  // TODO: Remove stubbing for sports not
  //       matching NFL
  if (sport !== 'nfl') {
    return <ContestComingSoon sport={sport} />;
  }

  return <ContestListContainer sport={sport} filters={filters} setItem={setItem} handleClickOpen={handleClickOpen} />;
};

TabContainer.propTypes = {
  sport: string,
  filters: func,
  dir: string,
  setItem: func,
  handleClickOpen: func,
};

export const LoggedIn = () => {
  const theme = useTheme();

  const fullScreen = true;
  const [item, setItem] = useState(null);
  const [open, setOpen] = useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <Layout>
      <Grid container xs={12}>
        <TabContainer dir={theme.direction} sport='nfl' setItem={setItem} handleClickOpen={handleClickOpen} />
        {item && <ContestDialog open={open} fullScreen={fullScreen} handleClose={handleClose} item={item} />}
      </Grid>
    </Layout>
  );
};

export default LoggedIn;
