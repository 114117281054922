import React from 'react';

// Components & CSS
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const RuleItem = ({ label, value }) => {
  const cn = RuleItemCss();
  return (
    <>
      <Grid item xs className={ cn.label }>
        { label }
      </Grid>
      <Grid item xs className={ cn.value }>
        { value }
      </Grid>
    </>
  );
};

export default RuleItem

const RuleItemCss = makeStyles((theme) => ({
  root: {
    marginBottom: 0,
  },
  joinButton: {
    padding: theme.spacing(4),
  },
  backButton: {
    position: 'fixed',
    left: 20,
    top: 5,
    textAlign: 'center',
  },
  h6: {
    textTransform: 'uppercase',
    padding: theme.spacing(1),
    marginBottom: 0,
  },
  entryFee: {
    fontWeight: 'bold',
  },
  label: {
    fontSize: '85%',
    padding: theme.spacing(1),
  },
  value: {
    fontSize: '85%',
    textAlign: 'right',
    padding: theme.spacing(1),
  },
  normalText: {
    fontSize: '14px',
  },
  fineprint: {
    fontSize: '75%',
    color: 'rgba(0,0,0,.4)',
  },
}));
