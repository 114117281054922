import { useEffect, useState } from 'react';

export const useSnackbar = () => {
  const [open, setOpen] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState({});

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setOpen(false);
        setSnackbarProps({});
      }, 3000);
    }
  }, [open]);

  const openSnackbar = ({ message = '', variant = '' }) => {
    setSnackbarProps({ message, variant });
    setOpen(true);
  };

  return { open, snackbarProps, openSnackbar };
};
