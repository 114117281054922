import PropTypes from 'prop-types';
import React from 'react';

import { useAppContext } from '../../../../context/AppProvider';
import LeagueListItemMatchupEntry from './LeagueListItemMatchupEntry/LeagueListItemMatchupEntry';

export const LeagueListItemMatchup = ({ league }) => {
  const [{ user }] = useAppContext();
  const userId = user.attributes.sub;

  if (!league) {
    return null;
  }

  const userFranchise = league.franchises?.find((franchise) => franchise?.userId === userId);
  const userMatchup = league?.schedule?.matchup?.find((schedule) => schedule?.userId === userId);
  const { overall } = league.contest.results;
  const userResults = overall?.find((userResult) => userResult?.franchise?.userId === userId);

  return (
    <>
      <LeagueListItemMatchupEntry
        avatar={userFranchise?.franchiseLogo}
        name={userFranchise?.franchiseName}
        record={userFranchise?.record}
        earnedPoints={userMatchup?.fantasyPoints ? userMatchup?.fantasyPoints.toFixed(2) : '-'}
        place={userResults?.place}
      />
    </>
  );
};

LeagueListItemMatchup.propTypes = {
  league: PropTypes.shape({}),
};

export default LeagueListItemMatchup;
