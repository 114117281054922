import React from 'react';
import { bool, number, shape } from 'prop-types';

import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';

import Text from '../../../../components/Common/Text';

import s from './LeagueStandingsItem.module.scss';

export const LeagueStandingsItem = ({ index, isMobile, franchise }) => {
  return (
    <ListItem key={index}>
      <Grid container alignItems="center" className={s.container}>
        <Grid item xs={1}>
          {index}
        </Grid>
        <Grid item xs={2}>
          <Avatar title="avatar" className={s.avatar} src={franchise.franchiseLogo} alt={franchise.franchiseName} />
        </Grid>
        <Grid item xs={5}>
          <Text bold display="block">
            {franchise.franchiseName}
          </Text>
          <Text>
            {franchise.record.wins}-{franchise.record.losses}-{franchise.record.ties}
          </Text>
        </Grid>
        <Grid item xs={2} className={s.center}>
          <Text>{franchise?.record?.pointsFor ? franchise.record.pointsFor.toFixed(2) : '--'}</Text>
        </Grid>
        <Grid item xs={2} className={s.center}>
          <Text>{franchise?.record?.pointsAgainst ? franchise.record.pointsAgainst.toFixed(2) : '--'}</Text>
        </Grid>
      </Grid>
    </ListItem>
  );
};

LeagueStandingsItem.propTypes = {
  index: number.isRequired,
  isMobile: bool,
  franchise: shape({}).isRequired,
};

export default LeagueStandingsItem;
