import React from 'react';

import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

import Layout from 'components/Common/Layout';
import { DraftListContainer } from '../../components/Draft/DraftList';

export const Drafts = (props) => {
  const classes = useStyles();

  const [item, setItem] = React.useState({});

  React.useEffect(() => {
    if (item.draftId) {
      props.history.push(`/drafts/${item.draftId}`);
    }
  }, [item, props.history]);

  return (
    <Layout>
      <Container className={classes.root}>
        <DraftListContainer setItem={setItem} />
      </Container>
    </Layout>
  );
};

export default Drafts;

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
  },
}));
