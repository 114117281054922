import { convertToBoolean } from "../src/utils";

export const cfg = {
  env: import.meta.env.VITE_ENV || import.meta.env.NODE_ENV || 'production',
  enableSimulation: convertToBoolean(import.meta.env.VITE_ENABLE_SIMULATION || false),
  ftApiUri: import.meta.env.VITE_FT_API_URI,
  ftWsUri: import.meta.env.VITE_FT_WS_URI,
  ftMockApiUri: import.meta.env.VITE_FT_MOCK_API_URI,
  awsmobile: {
    aws_project_region: import.meta.env.VITE_AWS_PROJECT_REGION,
    aws_cognito_identity_pool_id: import.meta.env.VITE_AWS_COGNITO_IDENTITY_POOL_ID,
    aws_cognito_region: import.meta.env.VITE_AWS_COGNITO_REGION,
    aws_user_pools_id: import.meta.env.VITE_AWS_USER_POOLS_ID,
    aws_user_pools_web_client_id: import.meta.env.VITE_AWS_USER_POOLS_WEB_CLIENT_ID,
  },
  paysafeKey: import.meta.env.VITE_PAYSAFE_PUBLIC_ENCODED_KEY,
  geoComplyApiKey: import.meta.env.VITE_GEOCOMPLY_API_KEY,
  geoComplySecretKey: import.meta.env.VITE_GEOCOMPLY_SECRET_KEY,
  paysafe: {
    simulator: import.meta.env.VITE_PAYSAFE_SIMULATOR,
    environment: import.meta.env.VITE_PAYSAFE_ENVIRONMENT,
  }
};
export default cfg;
