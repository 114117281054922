import React from 'react';

// Configure the Material-UI Framework Theme
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

export const theme = createTheme({
  themeName: 'FantasyThrone',
  palette: {
    // type: 'dark',
    primary: {
      main: '#43127d',
      light: '#BA88F7',
      dark: '#0E051A',
    },
    secondary: {
      main: '#bb8e64',
      light: '#FFE9D4',
      dark: '#94551b',
      contrastText: '#fff',
    },
    green: '#00901C',
    text: {
      primary: '#363636',
    },
  },
  typography: {
    h1: {
      fontSize: 40,
      fontFamily: "'Roboto', 'Segoe UI', 'HelveticaNeue-Light', sans-serif",
    },
    h2: {
      fontSize: 34,
      fontFamily: "'Roboto', 'Segoe UI', 'HelveticaNeue-Light', sans-serif",
    },
    h3: {
      fontSize: 30,
      fontFamily: "'Roboto', 'Segoe UI', 'HelveticaNeue-Light', sans-serif",
    },
    h4: {
      fontSize: 26,
      fontFamily: "'Roboto', 'Segoe UI', 'HelveticaNeue-Light', sans-serif",
    },
    h5: {
      fontSize: 22,
      fontFamily: "'Roboto', 'Segoe UI', 'HelveticaNeue-Light', sans-serif",
    },
    h6: {
      fontSize: 20,
      fontFamily: "'Roboto', 'Segoe UI', 'HelveticaNeue-Light', sans-serif",
    },
    body1: {
      fontSize: 16,
      fontFamily: "'AvenirNextLTPro-Regular', sans-serif",
    },
    body2: {
      fontSize: 12,
      fontFamily: "'AvenirNextLTPro-Regular', sans-serif",
    },
    caption: {
      fontSize: 10,
      fontFamily: "'AvenirNextLTPro-Regular', sans-serif",
    },
  },
});

export const AppThemeProvider = ({ children }) => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </MuiThemeProvider>
);
