import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import css from './LeagueScores.module.scss';
import { useCurrentWeek } from 'utils/useCurrentWeek';
import Modal from '../../../components/League/LeagueScores/IndepthMatchupScores/LeagueScoresModal';
import Matchup from '../../../components/League/LeagueScores/Matchups/Matchup';

export const LeagueScores = ({ league }) => {
  const [selectedMatchup, setSelectedMatchup] = useState(null);
  const [initialPage, setInitialPage] = useState(1);
  const { currentWeek: fetchedWeek } = useCurrentWeek();
  const currentWeek = fetchedWeek || league.week.start;

  const matchupData = league?.schedule?.matchups;
  const currentWeekMatchups = matchupData?.filter((current) => current?.week === currentWeek);

  const franchises = league.franchises;

  const games = currentWeekMatchups?.map((matchupWrapper) => {
    return matchupWrapper.matchup.map((matchup) => {
      const data = franchises.find((franchise) => franchise.userId === matchup.userId);

      return {
        userId: data?.userId,
        division: data?.division,
        franchiseLogo: data?.franchiseLogo,
        franchiseName: data?.franchiseName,
        fantasyPoints: matchup?.fantasyPoints,
        wins: data?.record?.wins,
        losses: data?.record?.losses,
        roster: matchup?.roster?.players,
        week: matchupWrapper?.week,
        sharedMatchupId: matchup?.sharedMatchupId,
      };
    });
  });

  const divisionOneGames = useMemo(
    () => games.filter((group) => group.some((game) => game.division === 1)),
    [games]
  );

  const divisionTwoGames = useMemo(
    () => games.filter((group) => group.some((game) => game.division === 2)),
    [games]
  );

  if (!games) return <p>Loading...</p>;

  const handleMatchupClick = useCallback((group, index) => {
    setSelectedMatchup(group);
    setInitialPage(index + 1);
  }, []);

  const closeModal = useCallback(() => {
    setSelectedMatchup(null);
  });

  return (
    <div className={css.container}>
      <div className={css.divisionContainer}>
        <h2 className={css.division}>Division 1</h2>
        <div className={css.matchupContainer}>
          {divisionOneGames.map((group) => (
            <div key={group[0].sharedMatchupId} onClick={() => handleMatchupClick(group)}>
              <div className={css.matchup}>
                {group.map((game) => (
                  <Matchup key={game.userId} {...game} />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      {divisionTwoGames.length > 0 && (
        <>
          <div className={css.divisionDivider} />
          <div className={css.divisionContainer}>
            <h2 className={css.division}>Division 2</h2>
            <div className={css.matchupContainer}>
              {divisionTwoGames.map((group) => (
                <div key={group[0].sharedMatchupId} onClick={() => handleMatchupClick(group)}>
                  <div className={css.matchup}>
                    {group.map((game) => (
                      <Matchup key={game.userId} {...game} />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      {selectedMatchup && (
        <Modal
        isOpen={!!selectedMatchup}
        onClose={closeModal}
        matchup={selectedMatchup}
        allGames={games}
        initialPage={initialPage} // Pass the initial page to the Modal
      />
      )}
    </div>
  );
};

LeagueScores.propTypes = {
  league: PropTypes.shape({
    id: PropTypes.string.isRequired,
    franchises: PropTypes.array.isRequired,
    week: PropTypes.shape({
      start: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
};

export default LeagueScores;
