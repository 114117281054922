import { bool, func, node, oneOf, string } from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';

import s from './Text.module.scss';

const Text = ({
  align,
  bold,
  children,
  className,
  color,
  component,
  display,
  gutterBottom,
  href,
  to,
  noWrap,
  onClick,
  paragraph,
  underline,
  uppercase,
  variant,
}) => {
  const textClasses = [
    className,
    bold ? s.bold : null,
    uppercase ? s.uppercase : null,
    component === 'button' ? s.button : null,
    to ? s.link : null,
    underline ? s.underline : null,
  ].join(' ');

  return (
    <Typography
      align={align}
      className={textClasses}
      color={color}
      component={to ? Link : component}
      display={display}
      gutterBottom={gutterBottom}
      href={href}
      noWrap={noWrap}
      onClick={onClick}
      paragraph={paragraph}
      to={to}
      variant={variant}>
      {children}
    </Typography>
  );
};

export default Text;

Text.propTypes = {
  align: oneOf(['left', 'right', 'center']),
  bold: bool,
  children: node,
  className: string,
  color: oneOf(['initial', 'inherit', 'primary', 'secondary', 'textPrimary', 'textSecondary', 'error']),
  component: string,
  display: oneOf(['initial', 'block', 'inline']),
  gutterBottom: bool,
  noWrap: bool,
  onClick: func,
  paragraph: bool,
  to: string,
  underline: bool,
  uppercase: bool,
  variant: oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'body1',
    'body2',
    'caption',
    'subtitle1',
    'subtitle2',
    'button',
    'overline',
    'srOnly',
    'inherit',
  ]),
};
