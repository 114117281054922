import { useMutation, useQuery } from '@apollo/client';
import { arrayOf, bool, func, number, oneOfType, shape, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import InfiniteScroll from 'react-infinite-scroller';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import { LoadingLogo } from 'components/Branding';
import { PlayerList } from 'components/Player';
import { positions } from 'constants/positions';
import { useLeagueContext } from 'context/LeagueProvider';

import { HandleError } from 'components/Common/Error';
import Skeleton, { SkeletonVariant } from 'components/Common/Skeleton';

import { DraftPlayersAvailableListLabels } from 'components/Draft/DraftPlayersAvailable/Labels/DraftPlayersAvailableListLabels';
import { PlayerPositionFilter } from 'components/Player/PlayerPositionFilter';

import s from './DraftPlayersAvailableListContainer.module.scss';
import { ADD_PLAYER_TO_QUEUE, DROP_PLAYER_FROM_QUEUE, GET_QUEUE_PLAYERS, REORDER_QUEUE } from './queries';

export const DraftPlayersAvailableListContainer = ({
  draftId,
  draft,
  draftedPlayers,
  filteredPositions,
  isDrafting,
  isLoading,
  leagueId,
  loadMore,
  players,
  playerId,
  setFilteredPlayerId,
  setFilteredPositions,
  setIsDrafting,
  setPlayerId,
  showLoadMore,
  setShowLoadMore,
}) => {
  const { data, error } = useLeagueContext();

  const [showQueue, setShowQueue] = useState(false);
  const [queueIds, setQueueIds] = useState([]);
  const [queuePlayers, setQueuePlayers] = useState([]);
  const [currentWeek, setCurrentWeek] = useState(1);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const queueOrder = reorder(queuePlayers, result.source.index, result.destination.index);
    setQueuePlayers(queueOrder);
    const updatedIds = queueOrder.map((player) => player.playerId);
    setQueueIds(updatedIds);
  };

  const { data: queue, loading: queueLoading } = useQuery(GET_QUEUE_PLAYERS, {
    variables: { draftId: draftId },
    fetchPolicy: 'network-only',
  });

  const [dropFromDraftQueue, { error: dropError }] = useMutation(DROP_PLAYER_FROM_QUEUE, {
    refetchQueries: [`getDraftQueue`],
    variables: { draftId: draftId },
    onCompleted: (data) => {
      const ids = data.dropFromDraftQueue.players.map((player) => player.playerId);
      setQueueIds(ids);
    },
  });

  const [addToDraftQueue, { error: addError }] = useMutation(ADD_PLAYER_TO_QUEUE, {
    refetchQueries: [`getDraftQueue`],
    variables: { draftId: draftId },
    onCompleted: (data) => {
      const ids = data.addToDraftQueue.players.map((player) => player.playerId);
      setQueueIds(ids);
    },
  });

  const [reorderDraftQueue] = useMutation(REORDER_QUEUE, {
    variables: { draftId: draftId, players: queueIds },
  });

  useEffect(() => {
    if (data) {
      const league = data.leagues?.values?.filter((league) => league.id === leagueId)[0];
      setCurrentWeek(league?.week?.start ?? 1);
    }
  }, [data, leagueId]);

  useEffect(() => {
    if (queue) {
      const ids = queue.queue.players.map((player) => player.playerId);
      setQueueIds(ids);
    }
  }, [queue]);

  useEffect(() => {
    if (draftedPlayers && draftedPlayers.length > 0 && queue?.queue?.players) {
      // drop any drafted players from all queues
      queue.queue.players.forEach((player) => {
        if (draftedPlayers.includes(player.playerId)) {
          dropFromDraftQueue({ variables: { draftId, playerId: player.playerId } });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftedPlayers, draftId, dropFromDraftQueue]);

  useEffect(() => {
    const queuePlayerList = queue?.queue?.players ?? []; // if queue has data, set the queue players here
    setQueuePlayers(queuePlayerList);
  }, [queue]);

  useEffect(() => {
    if (queueIds.length > 0) {
      reorderDraftQueue();
    }
  }, [queueIds, reorderDraftQueue]);

  if (addError || dropError) {
    return (
      <HandleError
        error={addError ? addError.graphQLErrors[0] : dropError.graphQLErrors0}
        fallback={addError ? 'Error adding player to queue' : 'Error dropping player from queue'}
      />
    );
  }

  if (queueLoading || !players.players) {
    return (
      <Box mt={2}>
        <PlayerPositionFilter
          positions={positions.NFL}
          setFilteredPositions={setFilteredPositions}
          filteredPositions={filteredPositions}
          showQueue={showQueue}
        />
        <Box mt={2} mb={6} mr={2} ml={2}>
          <DraftPlayersAvailableListLabels currentWeek={currentWeek} />
          <Box className={s.playerList} style={{ marginTop: '1rem' }}>
            <LoadingLogo />
            <Skeleton variant={SkeletonVariant.RECT} height={57} numberOfRows={10} />
          </Box>
        </Box>
      </Box>
    );
  }

  const handleListView = (showQueue) => {
    setShowQueue(showQueue);
    setShowLoadMore(!showQueue);
  };

  const availablePlayers = players?.players?.values?.filter((player) => !draftedPlayers.includes(player.playerId));

  return (
    <Box className={s.root}>
      <PlayerPositionFilter
        filteredPositions={filteredPositions}
        handleListView={handleListView}
        setFilteredPositions={setFilteredPositions}
        setFilteredPlayerId={setFilteredPlayerId}
        showQueue={showQueue}
        players={players?.players?.values ?? []}
        positions={positions.NFL}
      />
      <Box mt={2} mb={6} mr={2} ml={2}>
        <DraftPlayersAvailableListLabels currentWeek={currentWeek} />
        <DragDropContext onDragEnd={onDragEnd}>
          <Box className={s.playerList}>
            <InfiniteScroll
              hasMore={showLoadMore}
              loader={
                <div key='loader' className={s.loader}>
                  <CircularProgress color='primary' />
                </div>
              }
              loadMore={() => {
                if (showQueue || isLoading) return;

                loadMore();
              }}
              useWindow={false}>
              <Droppable droppableId='playerList'>
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    <PlayerList
                      addToDraftQueue={addToDraftQueue}
                      contestId={draft.contest.contestId}
                      currentWeek={currentWeek}
                      draftedPlayers={draftedPlayers}
                      draftId={draftId}
                      dropFromDraftQueue={dropFromDraftQueue}
                      isDrafting={isDrafting}
                      isDraggable={showQueue}
                      leagueId={leagueId}
                      playerId={playerId}
                      players={!availablePlayers && !queuePlayers ? [] : showQueue ? queuePlayers : availablePlayers}
                      queueIds={queueIds}
                      setIsDrafting={setIsDrafting}
                      setPlayerId={setPlayerId}
                      tag='DraftRoom'
                    />
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </InfiniteScroll>
          </Box>
        </DragDropContext>
      </Box>
    </Box>
  );
};

export default DraftPlayersAvailableListContainer;

DraftPlayersAvailableListContainer.propTypes = {
  draftId: string,
  draft: shape({}),
  draftedPlayers: arrayOf(number),
  filteredPositions: arrayOf(string),
  isDrafting: bool,
  isLoading: bool,
  leagueId: string,
  loadMore: func,
  players: oneOfType([shape({}), arrayOf(shape({}))]),
  playerId: number,
  setFilteredPlayerId: func,
  setFilteredPositions: func,
  setIsDrafting: func,
  setPlayerId: func,
  showLoadMore: bool,
  setShowLoadMore: func,
};
