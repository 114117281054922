import React from 'react';
import { bool, func, shape, string } from 'prop-types';

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import { ScoringListContainer } from '../../../components/Rule';
import Text from '../../../components/Common/Text';

import s from './LeagueRules.module.scss';

const RuleItem = ({ label, value }) => {
  return (
    <>
      <Grid item xs={6} className={s.label}>
        <Text variant="body1">{label}</Text>
      </Grid>
      <Grid item xs={6} className={s.value}>
        <Text display="block" variant="body1" align="right">
          {value}
        </Text>
      </Grid>
    </>
  );
};

RuleItem.propTypes = {
  label: string,
  value: string,
};

export const LeagueRules = ({ handleClose, contest, isLeaguePage }) => {
  return (
    <Container className={s.root} disableGutters>
      <Text variant="h6" align="center" id="responsive-dialog-title" bold mb={2}>
        League Details & Results
      </Text>
      <Text variant="body1" align="center" className={s.h6} uppercase bold>
        Format
      </Text>
      <Grid item container spacing={1} xs={12} md={3} className={s.center}>
        <RuleItem label={'League Type'} value={contest.rules.league.type} />
        <RuleItem label={'League Starts'} value={'-'} />
        <RuleItem label={'League Duration'} value={`Weeks ${contest.week.start} - ${contest.week.end}`} />
        {!isLeaguePage && (
          <>
            <Text variant="body2" align="center" className={s.h6} uppercase>
              Draft Details
            </Text>
            <Grid container spacing={1}>
              <RuleItem label={'Draft Type'} value={contest.rules.draft.format} />
              <RuleItem label={'Draft Time'} value={contest.rules.draft.starts} />
              <RuleItem label={'Draft Clock'} value={contest.rules.draft.clock} />
              <RuleItem label={'Scoring'} value={contest.rules.format} />
            </Grid>
          </>
        )}
        <Collapse in={false}>
          <Text variant="body2" align="center" className={s.h6} uppercase>
            Scoring Rules
          </Text>
          <Box>
            <ScoringListContainer rules={contest.rules.scoring} />
          </Box>
        </Collapse>
        <Grid item xs={12}>
          <Text variant="body1" className={s.h6} uppercase align="center" bold>
            Prizes & Payouts
          </Text>
        </Grid>
      </Grid>
      <Grid item container spacing={1} xs={12} md={3} className={s.center} justifyContent="center">
        <RuleItem label={'Entry Fee'} value={`$${contest.entry && contest.entry.fee}`} />
        <RuleItem label={'Prize Pool'} value={`$${contest.prize.pool}`} />
        <RuleItem label={'1st Place'} value={`$${contest.prize.first}`} />
        <RuleItem label={'2nd Place'} value={`$${contest.prize.second}`} />
        {contest.prize.weekly && <RuleItem label={'Weekly High Score'} value={`$${contest.prize.weekly}`} />}
        {isLeaguePage ? (
          <>
            <Grid item xs={12}>
              <Text variant="body1" className={s.h6} uppercase align="center" bold>
                Results
              </Text>
            </Grid>
            <RuleItem label={'Winner'} value={'TBD'} />
          </>
        ) : (
          <Grid item>
            <Text variant="body1" className={s.h6} uppercase align="center" bold>
              Entries
            </Text>
            <Text align="center">
              {contest.entry && contest.entry.count}/{contest.entry && contest.entry.max}
            </Text>
            <Text align="center" variant="caption" color="textSecondary">
              Entry fee will be refunded if league does not fill.
            </Text>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default LeagueRules;

LeagueRules.propTypes = {
  handleClose: func,
  contest: shape({}),
  isLeaguePage: bool,
};
