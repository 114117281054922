import React from 'react';
import { shape } from 'prop-types';

import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';

import Text from '../../../components/Common/Text';
import { useAppContext } from '../../../context/AppProvider';

import s from './LeagueSchedule.module.scss';
// import { LeagueListItemMatchup } from '../LeagueMatchup';

export const LeagueSchedule = ({ league }) => {
  const [{ user }] = useAppContext();
  const userId = user.attributes.sub;
  const finalWeek = league.week.end;

  const matchupsData = league?.schedule?.matchups
    .filter((leagueItem) => leagueItem.matchup.some((el) => el.userId === userId))
    .map(({ week, matchup }) => {
      const opponentId = matchup.find((matchup) => matchup.userId !== userId)?.userId;
      const opponent = league.franchises.find((franchise) => franchise.userId === opponentId);
      const matchupData = matchup.find((matchup) => matchup.userId === userId);
      let outcome;
      if (matchupData.fantasyPoints.toFixed(2) > matchupData.opponent.fantasyPoints.toFixed(2)) {
        outcome = 'W';
      } else if (matchupData.fantasyPoints.toFixed(2) < matchupData.opponent.fantasyPoints.toFixed(2)) {
        outcome = 'L';
      } else {
        outcome = 'T';
      }
      return { week, opponent, matchupData, outcome }
    });

  return (
    <Grid container direction="column" justifyContent="center" className={s.root}>
      {matchupsData
        .map(({ week, opponent, matchupData, outcome }) => {
          return (
            <Grid className={s.weekItem} key={week} container item alignItems="center">
              <div>
                <Text bold>WK {week}</Text>
                {matchupData.isChampionship && <Text variant="caption">Championship</Text>}
              </div>
              <div className={s.rowGroup}>
                <Text>vs</Text>
                <Avatar src={opponent?.franchiseLogo} />
                <Text>{opponent?.franchiseName}</Text>
              </div>
              <div className={s.rowGroup}>
                {!matchupData.isComplete ? (
                  <div>
                    <Text></Text>
                    <Text>--</Text>
                  </div>
                ) : (
                  <div>
                    <Text>{outcome}</Text>
                    <Text>
                      {matchupData.fantasyPoints.toFixed(2)}-{matchupData.opponent.fantasyPoints.toFixed(2)}
                    </Text>
                  </div>
                )}
              </div>
            </Grid>
          );
        })}
      {!league.isRegularSeasonComplete && (
        <Grid className={s.weekItem} container item alignItems="center">
          <div>
            <Text bold>WK {finalWeek}</Text>
            <Text variant="caption">Championship</Text>
          </div>
          <div className={s.rowGroup}>
            <Text bold>TBD</Text>
          </div>
          <div className={s.rowGroup}>
            {/*<Text>L</Text>*/}
            {/*<Text>122-119</Text>*/}
          </div>
        </Grid>
      )}
    </Grid>
  );
};

LeagueSchedule.propTypes = {
  league: shape({}),
};
