import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

export const ClockFormat = (props) => {
  const { seconds } = props;

  const minutes = Math.floor(seconds / 60);
  const netSeconds = seconds % 60;

  let displaySeconds = netSeconds.toString();
  if (netSeconds < 10) {
    displaySeconds = `0${netSeconds}`;
  }

  return (
    <>
      <NumberFormat value={minutes} decimalScale={0} displayType={'text'} />
      :
      <NumberFormat value={displaySeconds} decimalScale={0} displayType={'text'} />
    </>
  );
};

ClockFormat.propTypes = {
  seconds: PropTypes.number,
};

export default ClockFormat;
