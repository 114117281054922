import React from 'react';
import PropTypes from 'prop-types';

export const ErrorMessage = ({ error }) => {

  return (
    <div>
      { error.toString() }
    </div>
  );
};

ErrorMessage.propTypes = {
  error: PropTypes.object
};

export default ErrorMessage;