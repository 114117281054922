import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { shape } from 'prop-types';

// App State/Context
import { useAppContext } from '../../../context/AppProvider';

import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import EmailIcon from '@material-ui/icons/Email';

import { TextLogo } from '../../../components/Branding/Logos';
import Text from '../../../components/Common/Text';

import s from './LoggedOut.module.scss';

export const LoggedOut = ({ location }) => {
  const [{ isAuthenticated }] = useAppContext();

  // If we are authenticated redirect to
  // the requested destination or default to the lobby
  const { from } = location.state || { from: { pathname: '/lobby' } };
  if (isAuthenticated === true) {
    return <Redirect to={from} />;
  }

  return (
    <Container className={s.root}>
      <div className={s.actions}>
        <TextLogo />
        <Text variant="h6" component="h2" className={s.subheading} bold>
          6 WEEK FANTASY SPORTS LEAGUES
        </Text>
        <div>
          <Button component={Link} to="/register" className={s.href} variant="contained" color="secondary" startIcon={<EmailIcon />}>
            Sign up with email
          </Button>
        </div>
        <div className={s.authenticate}>
          <Text variant="body1" className={s.text}>
            Already have an account?&nbsp;
          </Text>
          <Text to="/login" bold>
            Sign in
          </Text>
        </div>
        <div className={s.fineprint}>
          <Text variant="caption">By continuing, you agree to our</Text>
          <div className={s.tos}>
            <Text variant="caption" to="#" className={s.href}>
              Terms of Service&nbsp;
            </Text>
            <Text variant="caption">and&nbsp;</Text>
            <Text variant="caption" to="#" className={s.href}>
              Privacy Policy
            </Text>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default LoggedOut;

LoggedOut.propTypes = {
  location: shape({}),
};
