import PropTypes from 'prop-types';
import React, { useState } from 'react';

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Snackbar,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';

import { PlayerDialogStatBox } from './PlayerDialogStatBox';

import { useMutation, useQuery } from '@apollo/client';
import { useSnackbarContext } from 'context/SnackbarProvider';
import { DRAFT_PLAYER, GET_DRAFT } from './queries';

export const PlayerDialog = (props) => {
  const {
    open,
    player,
    playerStatGroups,
    handleClosePlayerDialog,
    draftId,
    contestId,
    isDrafting,
    addToDraftQueue,
    dropFromDraftQueue,
    isInDraftRoom,
    queueIds,
  } = props;
  const [_, dispatch] = useSnackbarContext();

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [confirmDraftPlayer, setConfirmDraftPlayer] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const { loading } = useQuery(GET_DRAFT, {
    variables: { draftId: draftId },
    onError: (err) =>
      dispatch({
        type: 'OPEN',
        snackbarProps: { message: err.message, variant: 'error' },
      }),
  });
  const [draftPlayer] = useMutation(DRAFT_PLAYER, {
    variables: { draftId: draftId },
    onError: (err) =>
      dispatch({
        type: 'OPEN',
        snackbarProps: { message: err.message, variant: 'error' },
      }),
  });

  if (player == null) {
    return null;
  }

  const { playerId } = player;

  const projected = player.seasonProjections && player.seasonProjections;
  const yards = projected && projected.passingYards + projected.receivingYards + projected.rushingYards;
  const tds = projected && projected.passingTouchdowns + projected.receivingTouchdowns + projected.rushingTouchdowns;

  const seasonStats = player.seasonStats && player.seasonStats;
  const seasonYards = seasonStats && seasonStats.passingYards + seasonStats.receivingYards + seasonStats.rushingYards;
  const seasonTds = seasonStats && seasonStats.passingTouchdowns + seasonStats.receivingTouchdowns + seasonStats.rushingTouchdowns;

  const statGroups = playerStatGroups || [
    {
      title: `${new Date().getFullYear()} PROJECTED STATS`,
      stats: {
        'PROJ AVG PTS': projected ? Math.floor(projected.fantasyPointsPpr / 17) : '--',
        YDS: Math.floor(yards),
        TD: Math.floor(tds),
        PTS: projected?.fantasyPointsPpr?.toFixed(2) ?? '--',
      },
    },
    {
      title: `${new Date().getFullYear()} SEASON STATS`,
      stats: {
        YDS: Math.floor(seasonYards) ?? '--',
        TD: Math.floor(seasonTds) ?? '--',
        PTS: seasonStats?.fantasyPointsPpr?.toFixed(2) ?? '--',
      },
    },
  ];

  if (loading) return <p>Loading...</p>;

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        maxWidth='md'
        open={open}
        onClose={() => {
          setConfirmDraftPlayer(false);
          handleClosePlayerDialog();
        }}
        aria-labelledby='player-dialog'>
        <Snackbar message={'Player has already been drafted'} onClose={() => setShowSnackbar(false)} open={showSnackbar} />
        <Toolbar className={classes.root} style={{ position: 'relative' }}>
          <Typography variant='h6' style={{ fontWeight: 'bold', margin: '0 auto' }}>
            Player Info
          </Typography>
          <IconButton
            color='inherit'
            style={{ position: 'absolute', right: 0 }}
            onClick={() => {
              setConfirmDraftPlayer(false);
              handleClosePlayerDialog();
            }}
            aria-label='close'>
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <DialogContent className={classes.root}>
          <Box className={classes.outer}>
            <Box flexGrow={0} p={3} className={classes.photoBox}>
              <img src={player.photoUrl} className={classes.media} alt={player.fullName} />
            </Box>
            <Box flexGrow={1} p={1}>
              <Typography variant='h6' style={{ fontWeight: 'bold' }}>
                {player.fullName}
              </Typography>

              <Typography variant='body2' className={classes.bioText}>
                Position: {player.fantasyPosition}
              </Typography>

              <Typography variant='body2' className={classes.bioText}>
                Status:{' '}
                {player.currentInjuryStatus == null ? (
                  'Healthy'
                ) : (
                  <>
                    {player.currentInjuryStatus} <WarningIcon fontSize='inherit' color='error' />
                  </>
                )}
              </Typography>

              <Typography variant='body2' className={classes.bioText}>
                Team: {player.team}
              </Typography>
            </Box>
          </Box>

          {statGroups.map((g, index) => {
            return (
              <Box className={classes.outer} key={index}>
                <PlayerDialogStatBox title={g.title} stats={g.stats} />
              </Box>
            );
          })}

          {/* <Box className={classes.outer}>
            <Box p={1} className={classes.inner}>
              <Link>
                <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                  {new Date().getFullYear()} GAME LOG
                </Typography>
              </Link>
            </Box>
          </Box> */}

          <Box className={[classes.outer, classes.buttonBox].join(' ')}>
            <Box flexGrow={1}>{props.children}</Box>
          </Box>
        </DialogContent>
        {isInDraftRoom ? (
          <DialogActions className={classes.actionContainer}>
            <Grid container justifyContent='center'>
              <Grid item className={[classes.actionButtons, classes.queue].join(' ')}>
                {confirmDraftPlayer ? (
                  <>
                    {' '}
                    <IconButton color='inherit' onClick={() => setConfirmDraftPlayer(false)} aria-label='cancel'>
                      <CancelIcon />
                    </IconButton>
                    <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                      CANCEL
                    </Typography>
                  </>
                ) : queueIds.includes(playerId) ? (
                  <>
                    <IconButton
                      color='inherit'
                      onClick={() => {
                        dropFromDraftQueue({ variables: { draftId, playerId } });
                        handleClosePlayerDialog();
                      }}
                      aria-label='drop from queue'>
                      <CloseIcon />
                    </IconButton>
                    <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                      DROP FROM QUEUE
                    </Typography>
                  </>
                ) : (
                  <>
                    <IconButton
                      color='inherit'
                      onClick={() => {
                        addToDraftQueue({ variables: { draftId, playerId } });
                        handleClosePlayerDialog();
                      }}
                      aria-label='add to queue'>
                      <AddCircleOutlineIcon />
                    </IconButton>
                    <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                      ADD TO QUEUE
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid item className={[classes.actionButtons, classes.draft].join(' ')}>
                {confirmDraftPlayer ? (
                  <>
                    {' '}
                    <IconButton
                      color='inherit'
                      onClick={() => {
                        handleClosePlayerDialog();
                        draftPlayer({ variables: { draftId, playerId, contestId } });
                        setConfirmDraftPlayer(false);
                      }}
                      aria-label='confirm draft player'>
                      <CheckCircleIcon />
                    </IconButton>
                    <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                      CONFIRM
                    </Typography>
                  </>
                ) : (
                  <>
                    {' '}
                    <IconButton color='inherit' onClick={() => setConfirmDraftPlayer(true)} aria-label='draft player' disabled={!isDrafting}>
                      <CheckCircleOutlineIcon />
                    </IconButton>
                    <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                      DRAFT PLAYER
                    </Typography>
                  </>
                )}
              </Grid>
            </Grid>
          </DialogActions>
        ) : null}
      </Dialog>
    </>
  );
};

PlayerDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  isInDraftRoom: PropTypes.bool,
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    minWidth: '350px',
  },
  outer: {
    display: 'flex',
    width: '100%',
    borderBottom: '1px solid #cccccc',
    '&:first-of-type': {
      borderTop: '1px solid #cccccc',
    },
  },
  inner: {
    flexGrow: 1,
    textAlign: 'center',
  },
  photoBox: {
    textAlign: 'center',
    borderRight: '1px solid #cccccc',
  },
  bioText: {
    paddingTop: theme.spacing(1),
  },
  buttonBox: {
    marginTop: 'auto',
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: 0,
  },
  actionButtons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #0d0d0d',
    width: '50%',
  },
  draft: {
    color: theme.palette.primary.main,
  },
  queue: {
    color: theme.palette.secondary.main,
  },
}));

export default PlayerDialog;
