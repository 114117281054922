import { gql } from '@apollo/client';

export const UPDATE_NOTIFICATION = gql`
  mutation updateNotification($id: ID!, $isRead: Boolean!) {
    updateNotification(id: $id, isRead: $isRead) {
      updatedAt
    }
  }
`;

export const GET_NOTIFICATIONS = gql`
  query getNotifications {
    getNotifications {
      notificationId
      type
      isRead
      additionalData {
        leagueId
        prize
      }
      createdAt
    }
  }
`;

export const NOTIFICATION_CREATED = gql`
  subscription notificationCreated {
    notificationCreated {
      unreadCount
    }
  }
`;
