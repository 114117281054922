import { bool, func, number, oneOfType, shape, string, arrayOf } from 'prop-types';
import React, { useState } from 'react';
import { useAppContext } from '../../../context/AppProvider';

// Material-UI Components
import { Collapse, Dialog, DialogContent, DialogTitle, Grid, Icon, IconButton, useMediaQuery, useTheme } from '@material-ui/core';

// Components & CSS
import Text from '../../../components/Common/Text';
import { JoinContestAction } from '../JoinContestAction';
import { LeaveContestAction } from '../LeaveContestAction';
import s from './ContestDialog.module.scss';

const RuleItem = ({ label, value }) => (
  <>
    <Grid item xs={6} className={s.label}>
      <Text variant='body2'>{label}</Text>
    </Grid>
    <Grid item xs={6} className={s.value}>
      <Text variant='body2'>{value}</Text>
    </Grid>
  </>
);

RuleItem.propTypes = {
  label: string.isRequired,
  value: oneOfType([number, string]).isRequired,
};

export const ContestDialog = ({ open, handleClose, item }) => {
  const [collapsed, setCollapsed] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [{ user }] = useAppContext();
  const userId = user.attributes.sub;

  if (!item) {
    return null;
  }

  const handleClick = () => {
    setCollapsed((prev) => !prev);
  };

  const actionButton = (contest) => {
    if (contest.isJoinable) {
      if (contest.users.includes(userId)) {
        return <LeaveContestAction id={item.contestId} handleClose={handleClose} contestName={item.name} />;
      } else {
        return <JoinContestAction id={item.contestId} handleClose={handleClose} contestName={item.name} />;
      }
    }
    return null;
  };

  const { entry, prize, rules, week, name } = item;

  return (
    <Dialog maxWidth='sm' fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby='responsive-dialog-title'>
      <>
        <DialogTitle align='center' id='responsive-dialog-title'>
          {rules.sport} {name.slice(3, 7)} | {entry.max} Team {rules.league.type} | WK {week.start} - {week.end}
        </DialogTitle>
        <DialogContent>
          <IconButton color='secondary' className={s.backButton} onClick={handleClose} aria-label='Add an alarm'>
            <Icon>arrow_back_ios</Icon>
          </IconButton>
          <Text bold align='center' variant='body2' component='h3' uppercase>
            Entry Amount
          </Text>
          <Text bold align='center' variant='h5' paragraph>
            ${entry.fee}
          </Text>
          <Text bold align='center' variant='body2' component='h3' uppercase>
            League Details
          </Text>
          <Grid container spacing={1}>
            <RuleItem label='League Starts:' value={`Week ${week.start}`} />
            <RuleItem label='League Duration:' value={`Weeks ${week.start} - ${week.end}`} />
          </Grid>
          <Text bold align='center' variant='body2' component='h3' uppercase>
            Draft Details
          </Text>
          <Grid container spacing={1}>
            <RuleItem label='Draft Type:' value={rules.draft?.format ?? 'Snake'} />
            <RuleItem label='Draft Time:' value={rules.draft?.starts ?? '-'} />
            <RuleItem label='Draft Clock:' value={rules.draft?.clock ?? '-'} />
            <Grid item xs={6} className={s.label}>
              <Text variant='body2'> Scoring:</Text>
            </Grid>
            <Grid item xs={6} className={s.value}>
              <Text variant='body2' component='button' onClick={handleClick}>
                (see more)
              </Text>
            </Grid>
          </Grid>
          <Collapse in={collapsed}>
            <Text bold align='center' variant='body2' component='h3' uppercase>
              Scoring Rules
            </Text>
            <Grid container spacing={1}>
              {rules.scoring?.map(({ rule, points }) => (
                <RuleItem key={rule} label={rule} value={points} />
              ))}
            </Grid>
          </Collapse>
          <Text bold align='center' variant='body2' component='h3' uppercase>
            Prizes & Payouts
          </Text>
          <Grid container spacing={1}>
            <RuleItem label='Prize Pool:' value={`$${prize.pool}`} />
            <RuleItem label='1st Place:' value={`$${prize.first}`} />
            {prize.second && <RuleItem label='2nd Place:' value={`$${prize.second}`} />}
            {prize.third && <RuleItem label='3rd Place:' value={`$${prize.third}`} />}
            {prize.weekly && <RuleItem label='Weekly High Score:' value={`$${prize.weekly}`} />}
          </Grid>
          <Text bold align='center' variant='body2' component='h3' uppercase>
            Entries
          </Text>
          <Text align='center' variant='body2' component='h3' uppercase>
            {entry.count}/{entry.max}
          </Text>
          <div className={s.joinButton}>{actionButton(item)}</div>
          <Text align='center' variant='caption' component='h3' uppercase>
            Entry fee will be refunded if league does not fill.
          </Text>
        </DialogContent>
      </>
    </Dialog>
  );
};

ContestDialog.propTypes = {
  open: bool.isRequired,
  handleClose: func.isRequired,
  item: shape({
    entry: shape({
      count: number.isRequired,
      max: number.isRequired,
      fee: number.isRequired,
    }).isRequired,
    prize: shape({
      pool: number.isRequired,
      first: number.isRequired,
      second: number,
      third: number,
      weekly: number,
    }).isRequired,
    rules: shape({
      sport: string.isRequired,
      league: shape({
        type: string.isRequired,
      }).isRequired,
      draft: shape({
        format: string,
        starts: string,
        clock: string,
      }),
      scoring: arrayOf(
        shape({
          rule: string.isRequired,
          points: number.isRequired,
        })
      ),
    }).isRequired,
    week: shape({
      start: number.isRequired,
      end: number.isRequired,
    }).isRequired,
    users: arrayOf(string).isRequired,
  }).isRequired,
};

export default ContestDialog;
