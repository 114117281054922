import React, { useState } from 'react';
import { shape } from 'prop-types';
import { useQuery } from '@apollo/client';
import InfiniteScroll from 'react-infinite-scroller';
import { uniqBy } from 'lodash';

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';

import positions from 'constants/positions';
import { useCurrentWeek } from 'utils/useCurrentWeek';

import { DraftPlayersAvailableListLabels } from 'components/Draft';
import LeagueWaiverWirePlayerListItem from 'components/League/LeagueWaiverWire/LeagueWaiverWirePlayerListItem';
import { PlayerPositionFilter } from 'components/Player/PlayerPositionFilter';
import Text from 'components/Common/Text';

// import { GET_PLAYERS } from 'api/player';
import dummyArray from 'routes/League/LeagueWaiverWire/dummyArray';
import s from './LeagueWaiverWire.module.scss';
import Button from '@material-ui/core/Button';

const LeagueWaiverWire = ({ league }) => {
  const { currentWeek } = useCurrentWeek();
  const LIMIT = 10;
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const LIVE = 'live';
  const FREE_AGENTS = 'free agents';
  const COMPLETE = 'complete';

  const [filteredPositions, setFilteredPositions] = useState(['QB', 'RB', 'WR', 'TE', 'FLEX']);
  const [filteredPlayerId, setFilteredPlayerId] = useState(null);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [activeSection, setActiveSection] = useState(FREE_AGENTS);

  // const { data, fetchMore } = useQuery(GET_PLAYERS, {
  //   variables: {
  //     limit: LIMIT,
  //     skip: 0,
  //     playersFilter: filteredPlayerId
  //  /    ? { sport: { eq: 'nfl' }, averageDraftPositionPpr: { gt: 0 }, fantasyPosition: { in: filteredPositions }, playerId: { eq: filteredPlayerId } }
  //       : { sport: { eq: 'nfl' }, averageDraftPositionPpr: { gt: 0 }, fantasyPosition: { in: filteredPositions } },
  //   },
  // });

  const filteredPlayers = dummyArray.players.filter(player => filteredPositions.includes(player.fantasyPosition));
  // console.log("FILTERED PLAYERS",filteredPlayers);

  const handlePositionFilterChange = (selectedPositions) => {
    if (selectedPositions.includes('FLEX')) {
      setFilteredPositions(['RB', 'WR', 'TE']);
    } else {
      setFilteredPositions(selectedPositions);
    }
  };

  // const loadMorePlayers = () => {
  //   fetchMore({
  //     variables: {
  //       skip: data?.players.values.length,
  //       limit: LIMIT,
  //     },
  //     updateQuery: (prev, { fetchMoreResult }) => {
  //       const fetchMoreCopy = fetchMoreResult;
  //       if (fetchMoreCopy.players.count === 0) {
  //         setShowLoadMore(false);
  //       }
  //       const prevValues = prev?.players?.values || [];
  //       const newValues = fetchMoreCopy.players.values;
  //       fetchMoreCopy.players.values = [...prevValues, ...newValues];
  //       return { ...fetchMoreCopy };
  //     },
  //   });
  // };

  const showLive = isDesktop || activeSection === LIVE;
  const showCompleted = isDesktop || activeSection === COMPLETE;
  const showFreeAgents = isDesktop || activeSection === FREE_AGENTS;

  return (
    <Grid container>
      {!isDesktop && (
        <Grid container item justifyContent='center' alignItems='baseline' spacing={1}>
          <Button color='primary' onClick={() => setActiveSection(COMPLETE)} size='small'>
            Completed Waivers
          </Button>
          <Text>|</Text>
          <Button color='primary' onClick={() => setActiveSection(FREE_AGENTS)} size='small'>
            Free Agents
          </Button>
          <Text>|</Text>
          <Button color='primary' onClick={() => setActiveSection(LIVE)} size='small'>
            Live Claims
          </Button>
        </Grid>
      )}
      {showCompleted && (
        <Grid container item xs={12} md={2} className={s.sideColumn} direction='column'>
          <Text variant='h6' component='h2'>
            Completed Waivers
          </Text>
          <Grid container item>
            <Text variant='body2' component='h3' underline>
              Week 2
            </Text>
          </Grid>
          <Grid container item>
            <Text variant='body2' component='h3' underline>
              Week 3
            </Text>
          </Grid>
        </Grid>
      )}
      {showFreeAgents && (
        <Grid container item xs={12} md={8} direction='column' alignItems='center' className={s.root}>
          <Text variant='h4' component='h1' bold paragraph>
            Free Agents
          </Text>
          <PlayerPositionFilter
            className={s.filterContainer}
            filteredPositions={filteredPositions}
            positions={positions.NFL}
            setFilteredPlayerId={setFilteredPlayerId}
            setFilteredPositions={handlePositionFilterChange}
          />
          <DraftPlayersAvailableListLabels currentWeek={currentWeek} />
          <div className={s.playerList}>
            {/* <InfiniteScroll
              hasMore={showLoadMore}
              loadMore={loadMorePlayers}
              loader={
                <Grid container justifyContent='center' key='loader'>
                  <CircularProgress color='primary' />
                </Grid>
              }
              useWindow={false}> */}
              {filteredPlayers.map((player) => (
              <LeagueWaiverWirePlayerListItem
                key={player.playerId}
                currentWeek={currentWeek}
                // draftId={league.draftId}
                handleOpenDialog={() => {}}
                player={player}
              />
            ))}
            {/* </InfiniteScroll> */}
          </div>
        </Grid>
      )}
      {showLive && (
        <Grid container item xs={12} md={2} className={s.sideColumn} direction='column'>
          <Text variant='h6' component='h2'>
            Live Waiver Claims
          </Text>
          <Text underline variant='caption' bold>
            Claims process on Wednesday at 6am EST
          </Text>
        </Grid>
      )}
    </Grid>
  );
};

LeagueWaiverWire.propTypes = {
  league: shape({}),
};

export default LeagueWaiverWire;
