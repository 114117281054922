import { bool, func, number, shape } from 'prop-types';
import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import ListItem from '@material-ui/core/ListItem';
import WarningIcon from '@material-ui/icons/Warning';

import Text from '../../../../components/Common/Text';

import { Tooltip } from '@material-ui/core';
import s from './DraftPlayersAvailableListItem.module.scss';

export const DraftPlayersAvailableListItem = ({ currentWeek, handleOpenDialog, isDraggable, player }) => {
  const playerGameProjections = player.gameProjections ? player.gameProjections[currentWeek - 1] : [];
  const touchdowns = playerGameProjections
    ? playerGameProjections.passingTouchdowns + playerGameProjections.rushingTouchdowns + playerGameProjections.receivingTouchdowns
    : 0;

  const yards = playerGameProjections
    ? playerGameProjections.passingYards + playerGameProjections.rushingYards + playerGameProjections.receivingYards
    : 0;

  const fantasyPoints = playerGameProjections?.fantasyPointsPpr ?? 0;

  return (
    <ListItem divider className={isDraggable ? [s.listItem, s.grab].join(' ') : [s.listItem, s.point].join(' ')} onClick={handleOpenDialog}>
      <Grid container spacing={2}>
        <Grid item xs={2} md={1}>
          {' '}
          <Avatar alt={player.fullName} src={player.photoUrl} />
        </Grid>
        <Grid container item xs={6} md={3} alignItems='center'>
          <Grid item xs={9}>
            <Text variant='body2' style={{ fontWeight: 'bold' }}>
              {player.fullName}
            </Text>
            <Text variant='caption'>
              {player.team}/{player.fantasyPosition} - Bye: {player.byeWeek}
            </Text>
          </Grid>
          {player.currentInjuryStatus !== null && (
            <Grid item xs={3}>
              <Tooltip title={`Injury status: ${player.currentInjuryStatus}`} placement='top'>
                <WarningIcon color='error' />
              </Tooltip>
            </Grid>
          )}
        </Grid>
        <Grid className={s.adp} item xs={4} md={2}>
          <Text variant='caption'>
            <strong>{player.averageDraftPositionPpr}</strong> ADP
          </Text>
        </Grid>
        <Hidden smDown>
          <Grid container alignItems='center' item md={3}>
            <Grid item md={4}>
              <Text align='center' display='block' variant='caption'>
                {player.positionRank ?? '--'}
              </Text>
            </Grid>
            <Grid item md={4}>
              <Text align='center' display='block' variant='caption'>
                {player.seasonStats?.fantasyPointsPpr?.toFixed(0) ?? '--'}
              </Text>
            </Grid>
            <Grid item md={4}>
              <Text align='center' display='block' variant='caption'>
                {player.seasonStats?.fantasyPointsPpr ? (player.seasonStats.fantasyPointsPpr / player.gameProjections.length).toFixed(1) : '--'}
              </Text>
            </Grid>
          </Grid>
          <Grid container alignItems='center' item md={3}>
            <Grid item md={4}>
              <Text align='center' display='block' variant='caption'>
                {yards?.toFixed(0) ?? '--'}
              </Text>
            </Grid>
            <Grid item md={4}>
              <Text align='center' display='block' variant='caption'>
                {touchdowns?.toFixed(0) ?? '--'}
              </Text>
            </Grid>
            <Grid item md={4}>
              <Text align='center' display='block' variant='caption'>
                {fantasyPoints?.toFixed(0) ?? '--'}
              </Text>
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
    </ListItem>
  );
};

export default DraftPlayersAvailableListItem;

DraftPlayersAvailableListItem.propTypes = {
  currentWeek: number,
  handleOpenDialog: func,
  isDraggable: bool,
  player: shape({}),
};
