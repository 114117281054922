import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Material-UI Components
import { List } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Components & CSS
import { PlayerDialog, PlayerListItem } from '../../../components/Player';

export const PlayerList = ({
  draftedPlayers,
  players,
  leagueId,
  draftId,
  isDrafting,
  tag,
  addToDraftQueue,
  dropFromDraftQueue,
  contestId,
  queueIds,
  ...props
}) => {
  const [player, setPlayer] = useState(null);
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleOpenPlayerDialog = (id) => {
    setPlayer(id);
    setOpen(true);
  };

  const handleClosePlayerDialog = () => {
    setPlayer(null);
    setOpen(false);
  };

  useEffect(() => {
    if (player && draftedPlayers.includes(player.playerId)) {
      handleClosePlayerDialog();
    }
  }, [draftedPlayers, player]);

  return (
    <>
      <List className={classes.root}>
        {players.length === 0 || !players ? (
          <p>Player list is empty</p>
        ) : (
          players.map((item, index) => (
            <PlayerListItem index={index} tag={tag} key={`${item.playerId}-${index}`} item={item} {...props} handleOpenPlayerDialog={handleOpenPlayerDialog} />
          ))
        )}
      </List>
      <PlayerDialog
        addToDraftQueue={addToDraftQueue}
        contestId={contestId}
        draftId={draftId}
        dropFromDraftQueue={dropFromDraftQueue}
        fullScreen={true}
        handleClosePlayerDialog={handleClosePlayerDialog}
        isDrafting={isDrafting}
        isInDraftRoom
        leagueId={leagueId}
        open={open}
        player={player}
        queueIds={queueIds}
      />
    </>
  );
};

PlayerList.propTypes = {
  tag: PropTypes.string.isRequired,
  players: PropTypes.array,
  leaugeId: PropTypes.string,
};

PlayerList.defaultProps = {
  players: [],
  leagueId: null,
};

export default PlayerList;

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
  },
}));
