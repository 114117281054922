import React from 'react';
import PropTypes from 'prop-types';

import MuiSkeleton from '@material-ui/lab/Skeleton';

import s from './Skeleton.module.scss';

export const SkeletonVariant = {
  TEXT: 'text',
  RECT: 'rect',
  CIRCLE: 'circle',
};

export const SkeletonAnimation = {
  PULSE: 'pulse',
  WAVE: 'wave',
  NONE: false,
};

const Skeleton = ({ animation = 'wave', height, numberOfRows, variant, width }) => {
  if (numberOfRows) {
    return Array.from(new Array(numberOfRows)).map((item, idx) => (
      <MuiSkeleton key={idx} classes={{ rect: s.rect }} animation={animation} height={height} width={width} variant={variant} />
    ));
  } else {
    return <MuiSkeleton animation={animation} classes={{ rect: s.rect }} height={height} width={width} variant={variant} />;
  }
};

export default Skeleton;

Skeleton.propTypes = {
  animation: PropTypes.string,
  height: PropTypes.number,
  numberOfRows: PropTypes.number,
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  width: PropTypes.number,
};
