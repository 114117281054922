import React from 'react';
import { shape } from 'prop-types';

import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';

import LeagueStandingsHeader from './LeagueStandingsHeader/LeagueStandingsHeader';
import LeagueStandingsItem from './LeagueStandingsItem/LeagueStandingsItem';

export const LeagueStandings = ({ league }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const calculateWinPct = (record) => {
    const { wins, losses, ties } = record;
    const totalGames = wins + losses + ties;
    return wins / totalGames;
  };

  const { franchises } = league;

  const noDivisions = franchises.filter((franchise) => !franchise.division)?.length === franchises.length;

  let division1 = franchises
    .filter((team) => team.division === 1)
    .map((team) => ({
      ...team,
      winPct: calculateWinPct(team.record),
    }))
    .sort((a, b) => b.winPct - a.winPct);
  const division2 = franchises
    .filter((team) => team.division === 2)
    .map((team) => ({
      ...team,
      winPct: calculateWinPct(team.record),
    }))
    .sort((a, b) => b.winPct - a.winPct);

  if (noDivisions) {
    division1 = franchises;
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={6}>
        <LeagueStandingsHeader divisionName={division2.length > 0 ? 'DIVISION 1' : ''} showStatHeaders />

        <List>
          {division1.map((franchise, index) => (
            <LeagueStandingsItem isMobile={isMobile} key={franchise.userId} index={index + 1} franchise={franchise} />
          ))}
        </List>
      </Grid>
      {division2.length > 0 && (
        <Grid item xs={12} md={6}>
          <LeagueStandingsHeader divisionName="DIVISION 2" showStatHeaders={!isMobile} />

          <List>
            {division2.map((franchise, index) => (
              <LeagueStandingsItem isMobile={isMobile} key={franchise.userId} index={index + 1} franchise={franchise} />
            ))}
          </List>
        </Grid>
      )}
    </Grid>
  );
};

export default LeagueStandings;

LeagueStandings.propTypes = {
  league: shape({}),
};
