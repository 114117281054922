import React, { useState } from 'react';
import { number, shape, string } from 'prop-types';

import { Button } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import ListItem from '@material-ui/core/ListItem';

import Text from '../../../../components/Common/Text';
import { WaiverSubmit } from '../WaiverSubmit/WaiverSubmit';

import s from './LeagueWaiverWirePlayerListItem.module.scss';

export const LeagueWaiverWirePlayerListItem = ({ player }) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleClosePlayerWaiver = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <ListItem divider className={[s.listItem, s.pointer].join(' ')}>
        <Grid container spacing={2}>
          <Grid item xs={2} md={1}>
            {''}
            <Avatar alt={player?.fullName} src={player?.photoUrl} />
          </Grid>
          <Grid item xs={6} md={3}>
            <Text variant="body2" style={{ fontWeight: 'bold' }}>
              {player?.fullName}
            </Text>
            <Text variant="caption">
              {player?.team}/{player?.fantasyPosition} - Bye: {player?.byeWeek}
            </Text>
          </Grid>
          <Grid className={s.adp} item xs={4} md={2}>
            <Button variant="contained" color="primary" onClick={() => setDialogOpen(true)}>{player.locked ? 'Locked' : 'ADD'}</Button>
          </Grid>
          <Hidden smDown>
            <Grid container alignItems="center" item md={3}>
              <Grid item md={4}>
                <Text align="center" display="block" variant="caption">
                  {player?.positionRank ?? '--'}
                </Text>
              </Grid>
              <Grid item md={4}>
                <Text align="center" display="block" variant="caption">
                  {player?.fantasyPoints?.toFixed(0) ?? '--'}
                </Text>
              </Grid>
              <Grid item md={4}>
                <Text align="center" display="block" variant="caption">
                  {player?.averagePoints.toFixed(1) ?? 'AVG'}
                </Text>
              </Grid>
            </Grid>
            <Grid container alignItems="center" item md={3}>
              <Grid item md={4}>
                <Text align="center" display="block" variant="caption">
                  {player?.yards?.toFixed(0) ?? '--'}
                </Text>
              </Grid>
              <Grid item md={4}>
                <Text align="center" display="block" variant="caption">
                  {player?.touchdowns?.toFixed(0) ?? '--'}
                </Text>
              </Grid>
              <Grid item md={4}>
                <Text align="center" display="block" variant="caption">
                  {player?.fantasyPoints?.toFixed(0) ?? '--'}
                </Text>
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </ListItem>
      <WaiverSubmit
        fullScreen={false}
        open={dialogOpen}
        handleClosePlayerWaiver={handleClosePlayerWaiver}
        player={player}
      />
    </>
  );
};

export default LeagueWaiverWirePlayerListItem;

LeagueWaiverWirePlayerListItem.propTypes = {
  currentWeek: number,
  draftId: string,
  player: shape({}),
};
